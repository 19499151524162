import React from "react";
import { Routes, Route } from "react-router-dom";
/* import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"; */

// Layout
//import BasicLayout from "./ui/BasicLayout";
import EmptyLayout from "./ui/layout/EmptyLayout";

// Pages
import HomePage from "pages/Home";
import HomeMapPage from "pages/HomeMap";
import MyPassportPage from "pages/MyPassport";
import ActivitiesPage from "pages/Activities";
import RoutesPage from "pages/Routes";
import RouteDetail from "pages/RouteDetail";
import RouteDetailMap from "pages/RouteDetailMap";
import PlacePage from "pages/Place";
import ProfilePage from "pages/Profile";
import ScanPage from "pages/Scan";
import SummaryPage from "pages/Summary";
import RafflePage from "pages/Raffle";
import LocationCheck from "pages/LocationCheck";
import WelcomeLocation from "./pages/WelcomeLocation";
import QrValidation from "./pages/QrValidation";
import ActivityDetailPage from "./pages/ActivityDetail";
/* import LoginPage from "pages/Login"; */

import "./App.scss";
import AnalyticsController from "./providers/Analytics";
import Voting from "./pages/Voting";
import VotingCheck from "./pages/VotingCheck";
import VotingView from "./pages/VotingView";
import PollSummaryPage from "./pages/PollSummary";
import DetectIncognito from "./pages/DetectIncognito";

import "./App.scss";
import VideoList from "pages/VideoList";

function App() {
  AnalyticsController.initializeTracking();
  return (
    <div className="App">
      <Routes>
        <Route element={<EmptyLayout />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/rutas" element={<RoutesPage />} />
          <Route path="/rutas/:id" element={<RouteDetail />} />
          <Route path="/rutas/:id/map" element={<RouteDetailMap />} />
          <Route path="/videos" element={<VideoList />} />

          <Route path="/DP-2023" element={<HomeMapPage />} />
          {/*           <Route path="/login" element={<LoginPage />} />
          <Route path="/perfil" element={<LoginPage />} /> */}
          <Route path="/mi-pasaporte" element={<MyPassportPage />} />
          <Route path="/actividades" element={<ActivitiesPage />} />
          <Route path="/actividad/:id" element={<ActivityDetailPage />} />

          <Route path="/lugar/:urlKey" element={<PlacePage />} />
          <Route path="/scan" element={<ScanPage />} />
          <Route path="/qr-check" element={<QrValidation />} />
          <Route path="/perfil" element={<ProfilePage />} />

          <Route path="/summary" element={<SummaryPage />} />
          <Route path="/resultados/:event" element={<PollSummaryPage />} />
          <Route path="/raffle" element={<RafflePage />} />
          <Route path="/location-check" element={<LocationCheck />} />
          <Route path="/votacion/:event" element={<WelcomeLocation />} />
          <Route path="/voting" element={<Voting />} />
          <Route path="/voting-check/:urlKey" element={<VotingCheck />} />
          <Route path="/voting-view/:urlKey" element={<VotingView />} />
          <Route path="/incognito" element={<DetectIncognito />} />
          {/* TODO: Implement protected pages
          https://github.com/remix-run/react-router/blob/dev/examples/auth/src/App.tsx
           */}
          {/* <Route
            path="/protected"
            element={
              <RequireAuth>
                <ProtectedPage />
              </RequireAuth>
            }
          /> */}
        </Route>
      </Routes>
    </div>
  );
}
export default App;
