import { Base64 } from "js-base64";
const SECRET = "s3cr3t"; // TODO: get from env
export function checkQrToken(qrToken: string, placeId: string) {
  try {
    const [placeUrlKey, secretKey] = Base64.decode(qrToken).split(":");
    return placeUrlKey === placeId && secretKey === SECRET;
  } catch (error) {
    console.log("error", error);
    return false;
  }
}
