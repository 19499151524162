import styled from "@emotion/styled";

export const ButtonContainer = styled.div`
  margin: 0;
  top: auto;
  right: 8vw;
  bottom: 10vh;
  left: auto;
  position: fixed;
`;
