import React, { useState } from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import UserAvatar from "components/UserAvatar";
import Icon from "@mui/material/Icon";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

interface RouteLogoProps {
  logo: string;
  onClick?: () => void;
}

export default function RouteLogo({
  logo,
  onClick = () => {}
}: RouteLogoProps) {
  return (
    <Container onClick={onClick}>
      <img src={logo} alt="logo" width="100%" height="auto" />
    </Container>
  );
}

const Container = styled.div`
  width: 107px;
  height: 74px;
`;
