import React from "react";
import styled from "@emotion/styled";
import { StyledFooter } from "./styles";
import { Box, Typography } from "@mui/material";
import AnalyticsController from "providers/Analytics";


export default function Footer() {
  return (
    <StyledFooter>
      <Box sx={{ paddingTop: "27px", paddingBottom: "5vh" }}>
        <a
          href="https://www.rancagua.cl/cultura-y-turismo/"
          target="_blank"
          rel="noreferrer"
          onClick={() => AnalyticsController.trackButtonClicked("click_footer_dpt_patrimonio_turismo")}
        >
          <Typography variant="h6" sx={{ color: "#868686", fontSize: "12px" }}>
            Departamento de
          </Typography>
          <Typography variant="h6" sx={{ color: "#868686", fontSize: "12px" }}>
            Patrimonio y Turismo
          </Typography>
        </a>
        <Box sx={{ paddingTop: "13px" }}>
          <a href="https://innovarancagua.cl" target="_blank" rel="noreferrer" onClick={() => AnalyticsController.trackButtonClicked("click_footer_cdir")}>
            <Typography
              variant="h6"
              sx={{ color: "#868686", fontSize: "12px" }}
            >
              Corporación de
            </Typography>
            <Typography
              variant="h6"
              sx={{ color: "#868686", fontSize: "12px" }}
            >
              Desarrollo e Innovación
            </Typography>
          </a>
        </Box>
      </Box>
      <LogoCdir
        src="/assets/logos/cdir_white.svg"
        alt="Corporación de Desarrollo e Innovación de la Ilustre Municipalidad de Rancagua"
      />
    </StyledFooter>
  );
}

const LogoCdir = styled.img`
  width: 150px;
  height: 150px;
  margin-top: 0;
`;
