import React, { useState } from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import UserAvatar from "components/UserAvatar";
import Icon from "@mui/material/Icon";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

interface FloatingBackButtonProps {
  onClick?: () => void;
}

export default function FloatingBackButton({
  onClick = () => {}
}: FloatingBackButtonProps) {
  return (
    <Button onClick={onClick}>
      <img src="/assets/icons/arrow_left.svg" alt="back" />
    </Button>
  );
}

const Button = styled.div`
  border-radius: 88px;
  border: 2px solid #fff;
  background: #fff;
  display: flex;
  width: 42px;
  height: 42px;
  padding: 9px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  flex-shrink: 0;
  z-index: 1;
`;
