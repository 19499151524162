import { useFirestoreCollectionData, useFirestore } from "reactfire";
import { collection, orderBy, query } from "firebase/firestore";

const POLL_SUMMARY_COLLECTION = "poll_summary";

export function useSummaryData(qry?: any) {
  const firestore = useFirestore();
  const pollSummaryCollection = collection(firestore, POLL_SUMMARY_COLLECTION);
  const pollSummaryQry = query(pollSummaryCollection, orderBy("count", "desc"));

  const { status, data } = useFirestoreCollectionData(pollSummaryQry, {
    idField: "id"
  });

  return [data, status] as const;
}
