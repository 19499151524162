import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { Participant } from "../../providers/Participant";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Stack from "@mui/material/Stack";

interface VoteProp {
  data: Participant;
  small?: boolean;
}

export default function VoteCard({ data, small }: VoteProp) {
  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Card
      sx={{
        padding: small ? "13px" : "27px",
        marginX: "auto",
        marginBottom: small ? 0 : "9px",
        borderRadius: "11px",
        borderTop: small ? 0 : "3px solid #E6E6E6",
        borderRight: small ? 0 : "3px solid #E6E6E6",
        borderBottom: small ? 0 : "5px solid #E6E6E6",
        borderLeft: small ? 0 : "3px solid #E6E6E6",
        textAlign: small ? "center" : "none",
        justifyContent: "center",
        boxShadow: "none",
      }}
    >
      {!small ? (
        <Slider {...carouselSettings}>
          {data.images.map((imageUrl, index) => (
            <div key={index}>
              <img
                style={{ width: "100%", backgroundColor: "black" }}
                src={imageUrl}
                alt={`carousel-image-${index}`}
              />
            </div>
          ))}
        </Slider>
      ) : (
        <img
          style={{ width: "25%", backgroundColor: "black" }}
          src={data.coverImage}
          alt="test"
        />
      )}
      <CardContent
        sx={{
          overflow: "hidden",
          marginTop: small ? "-10px" : 0,
          marginBottom: "-2.5rem",
        }}
      >
        <Stack>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: small ? "16px" : "22px",
              lineHeight: small ? "17.5px" : "27px",
              marginTop: "0.5rem",
            }}
          >
            {/*  #{data.number}  */}
            {data.name}
          </Typography>
          <Typography
            sx={{
              marginY: "11px",
              fontWeight: 400,
              lineHeight: "14.5px",
              marginBottom: "1rem",
            }}
          >
            {data.category}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
}
