import React from "react";
import styled from "@emotion/styled";
// COMPONENTS
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import DateFormat from "../DateFormat";
import SanitizeHTML from "components/SanitizeHTML";

// CONSTANTS
import { DEFAULT_COVER_IMAGE } from "containers/ActivityListCard";

// ICONS
import InsertInvitationOutlinedIcon from "@mui/icons-material/InsertInvitationOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";

// STYLES
import { DetailStack } from "./styles";

interface ActivityDetailProps {
  activity: any;
  embed?: boolean;
  interestPlace?: any;
}

export default function ActivityDetail({
  activity,
  interestPlace
}: ActivityDetailProps) {
  return (
    <>
      <DetailStack>
        <BackgroundImg
          style={{
            backgroundImage: `url(${
              activity?.icon.url ? activity?.icon.url : DEFAULT_COVER_IMAGE
            })`
          }}
        />
        {/* <img
          src={activity?.icon.url ? activity?.icon.url : DEFAULT_COVER_IMAGE}
          alt={activity?.icon.title}
          width={200}
          height={200}
        /> */}
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" alignItems="center">
            <InsertInvitationOutlinedIcon fontSize="large" />
            <Stack direction="column" marginLeft="2vh">
              <DateFormat date={activity} />
              <Typography
                fontSize="12px"
                fontWeight="bold"
                marginTop="-1vh"
                marginBottom="1vh"
              >
                {activity.time} a {activity.endTime} hrs.
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="row" marginTop="2vh" alignItems="center">
            <LocationOnOutlinedIcon fontSize="large" />
            <Stack direction="column" marginLeft="2vh">
              <Typography
                fontWeight="bold"
                sx={{ marginBottom: "1vh", fontSize: "16px" }}
              >
                {activity?.interestPlaces
                  ? interestPlace?.name
                  : activity?.address}
              </Typography>
              <Typography fontSize="12px" marginTop="-1vh">
                {interestPlace?.location?.address?.city || "Rancagua"}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <SanitizeHTML data={activity} embed={true} />
      </DetailStack>
    </>
  );
}

const BackgroundImg = styled.div`
  width: 100%;
  height: 200px;
  position: relative;
  border-radius: 16px;
  background-color: white /* Color de fondo debajo de la imagen */
  background-image: url("/path/to/your/image.jpg"); /* Ruta de la imagen de fondo */
  background-position: top center; /* Centra la imagen de fondo */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
  background-size: contain; /* Ajusta el tamaño de la imagen para cubrir todo el div */
`;
