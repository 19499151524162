import styled from "@emotion/styled";

export const BannerContainer = styled.div`
  height: 10vh;
  text-align: left;
  padding: 8px 16px;
  background-color: transparent;
  position: relative;
  z-index: 10000;
`;

export const BannerImg = styled.img`
  width: auto;
  height: 50px;
  margin-right: 1rem;
  z-index: 10000;
`;
