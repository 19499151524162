import styled from "@emotion/styled";

export const Container = styled.div`
  min-width: 100px;
  min-height: 100px;
  margin-bottom: -2rem;
`;

export const Icon = styled.img`
  width: 100px;
  height: auto;
`;
