import { Place } from "providers/Places";
import { StampContainer, StampOverlay } from "./styles";

interface StampProps {
  place: Place;
  visited?: boolean;
  onClick?: () => void;
}
export default function Stamp({
  place,
  visited = false,
  onClick = () => {}
}: StampProps) {
  return (
    <StampContainer onClick={onClick}>
      <img
        src={!visited ? place.stamps?.lockUrl : place.stamps?.url}
        alt="imagen"
        style={{ height: "100%", width: "100%" }}
      />
    </StampContainer>
  );
}
