import React, { useState } from "react";
import { Gallery } from "react-grid-gallery";

import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";

import "./styles.scss";

export default function GridGallery({ images }: { images: any }) {
  const [index, setIndex] = useState(-1);

  const currentImage = images[index];
  const nextIndex = (index + 1) % images.length;
  const nextImage = images[nextIndex] || currentImage;
  const prevIndex = (index + images.length - 1) % images.length;
  const prevImage = images[prevIndex] || currentImage;

  const handleClick = (index: number, item: CustomImage) => setIndex(index);
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);
  return (
    <div className="gallery-wrapper">
      <PhotoProvider>
        {images.map((image, key) => (
          <PhotoView src={image.src} key={key}>
            <img src={image.src} alt="" className="img-preview" />
          </PhotoView>
        ))}
      </PhotoProvider>
      {/* <Gallery
        images={images}
        onClick={handleClick}
        enableImageSelection={false}
      /> */}

      {!!currentImage &&
        /* @ts-ignore */
        {
          /*  <Lightbox
          mainSrc={currentImage.original}
          imageTitle={currentImage.caption}
          mainSrcThumbnail={currentImage.src}
          nextSrc={nextImage.original}
          nextSrcThumbnail={nextImage.src}
          prevSrc={prevImage.original}
          prevSrcThumbnail={prevImage.src}
          onCloseRequest={handleClose}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
        /> */
        }}
    </div>
  );
}
