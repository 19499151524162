import ReactGA from "react-ga4";

class AnalyticsController {
    initializeTracking = () => {
        ReactGA.initialize("G-9VNWL0TWNR");
    }

    trackPageView = (pathname: string) => {
        ReactGA.send({ hitType: "pageview", page: pathname, title: "user_visits_page" });
    }

    trackButtonClicked = (label?: string) => {
        ReactGA.event(`user_clicks_button_${label}`, {
            category: "button",
            action: "tap",
            label: label ?? "",
        });
    }

    trackSharedContent = (label?: string) => {
        ReactGA.event("user_shares_content", {
            category: "shared_content",
            action: "shared",
            label: label ?? "",
        });
    }

    trackTotemScan = (label?: string) => {
        ReactGA.event("user_scans_totem", {
            category: "totem_scan",
            action: "scan",
            label: label ?? "",
        });
    }
    trackActivityOpen = (activityName: string, label?: string) => {
        ReactGA.event(`user_opens_activity_${activityName}`, {
            category: "user_interaction",
            action: "activity_open",
            label: label ?? "",
        });
    }

    trackAppEvent = (eventName: string, label?: string) => {
        ReactGA.event(`${eventName}`, {
            category: "user_interaction",
            action: "activity_open",
            label: label ?? "",
        });
    }
}

export default new AnalyticsController();