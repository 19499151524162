import React from "react";
import styled from "@emotion/styled";

interface GiftIconProps {
  // Define your props here
}

interface GiftIconState {
  // Define your state here
}

class GiftIcon extends React.Component<GiftIconProps, GiftIconState> {
  constructor(props: GiftIconProps) {
    super(props);
    // Initialize your state here
    this.state = {};
  }

  render() {
    return (
      <Container>
        <img src="/assets/icons/gift_white.svg" height="auto" alt="Gift" />
      </Container>
    );
  }
}

export default GiftIcon;

const Container = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #14c7f5;
  stroke-width: 2px;
  border: 2px solid #fff;
  stroke: #fff;
  border-radius: 50%;
  box-shadow: -4px -3px 0px 1px rgba(0, 0, 0, 0.25) inset;
`;
