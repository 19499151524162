import React, { useState, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import { Sheet, SheetRef } from "react-modal-sheet";
import styled from "@emotion/styled";

import Typography from "@mui/material/Typography";
import { useNavigate, useParams, Link } from "react-router-dom";
import ActivityDetailCard from "components/ActivityDetailCard";
// Providers
import { Activity, useActivitiesData } from "providers/Activities";
import { DEFAULT_COVER_IMAGE } from "containers/ActivityListCard";
// Components
import ActivityListCard from "components/ActivityListCard";
import ActivityDetail from "components/ActivityDetail";
import FloatingBackButton from "components/FloatingBackButton";

// UI
import PageContainer from "ui/PageContainer";
import BoxList from "ui/BoxList";
import AnalyticsController from "providers/Analytics";
import ReactGA from "react-ga4";
import ActivityListCardContainer from "containers/ActivityListCard";

export default function ActivityDetailPage() {
  const [activities, status] = useActivitiesData();
  const [selectedActivity, setSelectedActivity] = useState<Activity | null>(
    null
  );
  const navigate = useNavigate();
  console.log("activities", activities);
  const { id } = useParams<{ id: string }>();
  /*  let [searchParams, setSearchParams] = useSearchParams(); */
  console.log("id", id);
  useEffect(() => {
    const activityKey = id;
    if (activityKey && activities) {
      const activityFound = activities.find(
        (act: Activity) => act.urlKey === activityKey
      );
      setSelectedActivity(activityFound || null);
    }
  }, [id, activities]);
  const ScrollToTop = () => {
    useEffect(() => {
      window.scrollTo(0, 0); // Desplaza la ventana del navegador al inicio absoluto
    }, []);

    return null;
  };

  return (
    <div style={{ width: "100%" }}>
      <BackgroundImg
        style={{
          backgroundImage: `url(${
            selectedActivity?.icon.url
              ? selectedActivity?.icon.url
              : DEFAULT_COVER_IMAGE
          })`
        }}
      >
        <Box
          sx={{
            zIndex: "100",
            position: "absolute",
            top: "15%",
            marginLeft: "16px"
          }}
        >
          <FloatingBackButton
            onClick={() => {
              navigate(-1);
            }}
          />
        </Box>
      </BackgroundImg>
      <PageContainer title={""} description="" loading={status === "loading"}>
        {selectedActivity && (
          <>
            <ActivityDetailCard activity={selectedActivity} />
          </>
        )}
      </PageContainer>
    </div>
  );
}
const BackgroundImg = styled.div`
  width: 100%;
  height: 400px;
  position: relative;
/*   border-radius: 16px; */
  background-color: white /* Color de fondo debajo de la imagen */
  background-image: url("/path/to/your/image.jpg"); /* Ruta de la imagen de fondo */
  background-position: top center; /* Centra la imagen de fondo */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
  background-size: cover; /* Ajusta el tamaño de la imagen para cubrir todo el div */
`;
