import * as React from "react";

// MUI
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import ShareDialog from "components/ShareDialog";
import CardActionArea from "@mui/material/CardActionArea";
import DateFormat from "components/DateFormat";

import Helmet from "react-helmet";

// PROVIDERS
import { Activity } from "providers/Activities";

// STYLES
import { ActivityCard } from "./styles";

interface ActivityListCardProps {
  activity: Activity;
  onClick?: () => void;
  getImage: string;
}

export default function ActivityListCard({
  activity,
  onClick = () => {},
  getImage
}: ActivityListCardProps) {
  return (
    <>
      <Helmet>
        <meta name={"titulo"} property={"og:title"} content={activity?.title} />
        <meta
          name={"imagen"}
          property={"og:image"}
          content={activity?.icon.url}
        />
        <meta name={"tipo"} property={"og:type"} content={"webpage"} />
        <meta
          name={"nombre_sitio"}
          property={"og:site_name"}
          content={"Descubre Rancagua Antiguo"}
        />
        <meta
          name={"descripcion"}
          property={"description"}
          content={activity?.description}
        />
        <meta
          name={"tarjeta"}
          property={"twitter:card"}
          content={activity?.icon.url}
        />
      </Helmet>
      <ActivityCard raised>
        <CardHeader
          sx={{ padding: "0.5em 1em 0 1em", color: "#663399" }}
          action={
            <ShareDialog
              title={activity?.title + " en el día del patrimonio cultural!"}
              url={`/actividades?actividad=${activity?.urlKey}`}
              description={activity?.description}
              navbar={false}
            />
          }
          avatar={<DateFormat date={activity} />}
        />
        <CardActionArea onClick={onClick}>
          {
            <CardMedia
              component="img"
              height="250"
              image={getImage}
              alt={activity?.title}
              title={activity?.title}
              sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
            />
          }
          <CardContent>
            <Typography
              gutterBottom
              variant="h6"
              component="div"
              fontWeight="bold"
            >
              {activity?.title}
            </Typography>
            <Typography fontWeight="bold">
              De {activity.time} a {activity.endTime}hrs.
            </Typography>
          </CardContent>
        </CardActionArea>
      </ActivityCard>
    </>
  );
}
