import * as React from "react";
// COMPONENTS
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
// ICONS
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PrizesDialog() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInstagram = () => {
    window.open(
      "https://instagram.com/desarrollo_innovacion?igshid=MzRlODBiNWFlZA==",
      "_blank"
    );
  };

  return (
    <div>
      <Button
        variant={"contained"}
        size="large"
        startIcon={<CardGiftcardIcon />}
        color={"info"}
        onClick={() => handleClickOpen()}
        style={{ borderRadius: "16px" }}
      >
        Recompensas
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="responsive-dialog-title"
          style={{ fontWeight: "bold", color: "#313E5C", padding: "2rem" }}
        >
          {"¡Recompensas y sorteos para la comunidad!"}
        </DialogTitle>
        <DialogContent sx={{ padding: "2rem" }}>
          <DialogContentText>
            Entérate de los premios y sorteos que hay para ti en nuestras redes
            sociales.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: "1rem" }}>
          <Button
            variant="outlined"
            color="info"
            autoFocus
            onClick={handleClose}
            style={{ borderRadius: "15px" }}
          >
            Cerrar
          </Button>
          <Button
            variant="contained"
            color="info"
            autoFocus
            onClick={handleInstagram}
            style={{ borderRadius: "15px" }}
          >
            Ir a Instagram
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
