import { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useAuth, useFirestore } from "reactfire";
import { signInAnonymously } from "firebase/auth";
import { useLocalStorage } from "usehooks-ts";
import { validate as uuidValidate } from "uuid";
import { collection, doc, onSnapshot, getDocs } from "firebase/firestore";
import { detectIncognito } from "detectincognitojs";

// Providers
import { useUserContext } from "providers/User";

// Hooks
import { usePollSettingsData } from "hooks/usePollSettingsData";

const POLL_VOTES_COLLECTION = "poll_votes";
const CURRENT_EVENT = "CARROS2023";

export function usePollChecker() {
  const firestore = useFirestore();
  const user = useUserContext();
  const [settings, isLoading] = usePollSettingsData(CURRENT_EVENT); // [settings, isLoading
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [authenticated, setAuthenticated] = useState(
    user && user.uid ? true : false
  );
  const [hasVoted, setHasVoted] = useLocalStorage<any>("vote", "");
  const [qrChecked, setQrChecked] = useLocalStorage<any>("qr", "");
  const [locationChecked, setLocationChecked] = useLocalStorage<any>(
    "location",
    ""
  );
  const [secureMode, setSecureMode] = useState(false);
  const [numberOfVotes, setNumberOfVotes] = useState(0);
  const auth = useAuth();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  /* console.log("userLocation", userLocation); */

  useEffect(() => {
    (async function () {
      try {
        const { isPrivate } = await detectIncognito();

        if (isPrivate === true) {
          navigate("/incognito");
        }
      } catch (error) {
        console.log("error:[isIncognito]", error);
      }

      if (user && user.uid) {
        //conteo de votos
        const initialVotesSnapshot = await getDocs(
          collection(firestore, POLL_VOTES_COLLECTION)
        );
        setNumberOfVotes(initialVotesSnapshot.size);

        // actualizar estado
        const unsubscribe = onSnapshot(
          collection(firestore, POLL_VOTES_COLLECTION),
          (snapshot) => {
            const newNumberOfVotes = snapshot.size;
            setNumberOfVotes(newNumberOfVotes);
          }
        );
        onSnapshot(
          doc(firestore, POLL_VOTES_COLLECTION, `${user.uid}_${CURRENT_EVENT}`),
          (doc) => {
            const voteData = doc.data();
            if (voteData && voteData.uid && voteData.option) {
              setHasVoted(voteData.option);
              setQrChecked(""); // reset qr token
              setLocationChecked(""); // reset location token
            }
            setLoading(false);
          }
        );
      }
    })();
  }, [user]);

  useEffect(() => {
    try {
      // User
      (async function () {
        try {
          if (!user || !user.uid) {
            await logInAnonymously(auth);
            setError("");
          }
          setAuthenticated(true);
        } catch (error: any) {
          console.log(error.code);
          const message = `${
            error.code === "auth/too-many-requests"
              ? "Ha superado el límite máximo de intentos para votar."
              : "Ha ocurrido un error, intenta nuevamente"
          }`;
          setError(message);
          setAuthenticated(false);
          setLoading(false);
        }
      })();

      // QR
      const qrKey = searchParams.get("qr"); // || qrChecked;
      const valid = uuidValidate(qrKey || "");
      if (valid && user && user.uid) {
        setQrChecked(qrKey);
      }
    } catch (error) {
      console.log("onError[logInAnonymously]", error);
      setAuthenticated(false);
      setQrChecked("");
    }
  }, [searchParams, user]);

  return {
    authenticated,
    qrChecked: qrChecked !== "" ? uuidValidate(qrChecked) : false,
    qrValue: qrChecked,
    setQrChecked,
    locationChecked: locationChecked !== "",
    setLocationChecked,
    hasVoted,
    secureMode,
    loading: isLoading || loading,
    settings,
    numberOfVotes,
    error
  };
}

async function logInAnonymously(auth: any) {
  try {
    await signInAnonymously(auth);
  } catch (error) {
    console.log("on error --->", error);
    return Promise.reject(error);
  }
}
