import React from "react";
import { detectIncognito } from "detectincognitojs";
import Typography from "@mui/material/Typography";

export default function DetectIncognito() {
  detectIncognito().then((result) => {
    console.log(result.browserName, result.isPrivate);
  });
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh"
        }}
      >
        <img
          src="/assets/logo-negro.svg"
          alt="logo CDIR"
          width="150px"
          height="150px"
        />
        <Typography
          variant="h1"
          sx={{
            fontSize: "18px",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            width: "80%"
          }}
        >
          La plataforma no está disponible en navegación privada
        </Typography>
      </div>
    </>
  );
}
