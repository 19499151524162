import styled from "@emotion/styled";

const Subtitle = styled.h2`
  font-size: 1.2rem;
  padding: 1rem 0;
  margin: 0;
  text-align: left;
  color: #000000;
`;
export default Subtitle;
