import Grid from "@mui/material/Unstable_Grid2";
import styled from "@emotion/styled";
import Stack from "@mui/material/Stack";

import Loading from "components/Loading";

import Title from "ui/Title";
import Description from "ui/Description";

interface PageContainerProps {
  children: React.ReactNode;
  title?: string;
  description?: string;
  loading?: boolean;
  container?: boolean;
}
export default function PageContainer({
  children,
  title = "",
  description = "",
  loading = false,
  container = true
}: PageContainerProps) {
  return (
    <PageContainerWrapper>
      {loading && <Loading />}
      <Grid container={container} direction="column">
        <Grid xs={12} md={6} mdOffset={3}>
          <Stack direction="column">
            {title && (
              <Title
                style={{ textAlign: "center", fontSize: "1.4rem" }}
                /*   style={{
                  padding: container ? "0" : "0 16px"
                }} */
              >
                {title}
              </Title>
            )}
            {description && <Description>{description}</Description>}
          </Stack>
          {children}
        </Grid>
      </Grid>
    </PageContainerWrapper>
  );
}
export const PageContainerWrapper = styled.div((props: any) => {
  const isContainer = props?.children[1]?.props?.container;
  return {
    width: "100%",
    padding: isContainer ? "0 1rem" : "0"
    /*  minHeight: "50vh" */
  };
});
