import { useEffect, useState } from "react";
import { useFirestore } from "reactfire";
import {
  collection,
  getDocs,
  query,
  where,
  Query,
  DocumentData,
  QuerySnapshot
} from "firebase/firestore";

// MUI
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";

// UI
import PageContainer from "ui/PageContainer";

export const PLACES_VISITED_COLLECTION = "place_visited";

export default function SummaryPage() {
  const firestore = useFirestore();
  const citiesRef = collection(firestore, PLACES_VISITED_COLLECTION);
  const [grouped, setGrouped] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [visited, setVisited] = useState<any[]>([]);
  const [sorted, setSorted] = useState<any[]>([]);

  useEffect(() => {
    (async () => {
      const conditionQuery: Query<DocumentData> = query(
        citiesRef,
        where("year", "==", 2024),
        where("route", "==", "2024CONTITO")
      );
      //const query = query(citiesRef, where("year", "==", "2024"));
      const all = await getDocs(conditionQuery);
      const group: any = {};
      const visit: any[] = [];
      all.forEach((doc: any) => {
        try {
          const data = doc.data();
          const { place, email, userId } = data;

          if (group[userId] && group[userId].visited) {
            const v = group[userId].visited;
            v.push(place);
            group[userId].visited = v;
          } else {
            group[userId] = { visited: [{ place }], email };
          }
          visit.push({ place, email, userId });
        } catch (error) {
          console.log("error", error);
        }
      });
      setGrouped(group);
      setVisited(visit);

      const usersSummary: any[] = [];

      Object.keys(group).map((key) => {
        const { visited, email } = group[key];
        usersSummary.push({ email, visited: visited.length });
      });

      const sortedUsersSummary = usersSummary.sort(
        (a, b) => b.visited - a.visited
      );
      setSorted(sortedUsersSummary);
      setLoading(false);
    })();
  }, []);

  return (
    <PageContainer title="Resumen 2024" loading={loading}>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          window.location.reload();
        }}
        sx={{ marginLeft: "1rem" }}
      >
        Actualizar
      </Button>
      <h2>Visitas Registradas: {visited.length}</h2>

      <h2>Visitas por usuario</h2>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Visitas</TableCell>
              <TableCell align="left">Email</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sorted.map((summary: any, key: number) => (
              <TableRow
                key={key}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {key + 1}
                </TableCell>
                <TableCell component="th" scope="row">
                  {summary?.visited || 0}
                </TableCell>

                <TableCell align="left">{summary?.email || ""}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </PageContainer>
  );
}
