import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

export default function Loading() {
  return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
          <img src="/assets/logo-negro.svg" alt="logo CDIR" width="150px" height="150px"/>
          <CircularProgress color="inherit" />
      </div>
  );
}
