import React from "react";
//components
import PlaceIcon from "@mui/icons-material/Place";

//styles
import "./styles.scss";

interface MyPositionProps {
  coordinates: {
    lat: number;
    lng: number;
  };
}

const MyPosition = ({ coordinates }: MyPositionProps) => {
  if (!coordinates) return null;

  const handleClick = () => {
    //console.log("on click my position!");
  };

  return (
    <div style={{ height: "50px", width: "50px" }}>
      <PlaceIcon
        className="animation my-position"
        sx={{ height: "50px", width: "50px", color: "#E17A5E" }}
        onClick={handleClick}
      />
    </div>
  );
};

export default MyPosition;
