import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import {
  FirebaseAppProvider,
  AuthProvider,
  FirestoreProvider
} from "reactfire";
import { initializeApp } from "firebase/app";
import reportWebVitals from "./reportWebVitals";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import App from "./App";
import { UserProvider } from "./providers/User";

import "./index.scss";

const firebaseConfig = {
  apiKey: "AIzaSyAdRw-XGKDXudUtcAVaReuJyiCK5XF3H-4",
  authDomain: "city-tour-84058.firebaseapp.com",
  projectId: "city-tour-84058",
  storageBucket: "city-tour-84058.appspot.com",
  messagingSenderId: "417225139801",
  appId: "1:417225139801:web:f250ff9173314cbce83348",
  measurementId: "G-33SXRWPNMJ"
};

const firebaseApp = initializeApp(firebaseConfig);
const firestoreInstance = getFirestore(firebaseApp);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <FirebaseAppProvider firebaseApp={firebaseApp}>
      <FirestoreProvider sdk={firestoreInstance}>
        <BrowserRouter>
          <AuthProvider sdk={getAuth(firebaseApp)}>
            <UserProvider>
              <App />
            </UserProvider>
          </AuthProvider>
        </BrowserRouter>
      </FirestoreProvider>
    </FirebaseAppProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
