import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import {
  TileLayer,
  MapContainer,
  Marker,
  Popup,
  Polyline,
  useMap,
  GeoJSON,
  SVGOverlay,
  useMapEvents
} from "react-leaflet";
import L from "leaflet";
/* import bikeRoadData from "./data/ciclovias-rancagua.geojson"; */

import "leaflet/dist/leaflet.css";
import "./styles.scss";
//Hooks
import { useLocalStorage } from "usehooks-ts";

// Providers
import {
  useUserLocationContext,
  useUpdateUserLocationContext
} from "providers/UserLocation";

L.Marker.prototype.options.icon = L.icon({
  iconUrl: "/assets/location_on.svg",
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  popupAnchor: [0, -40]
});

const blueOptions = { color: "transparent" };
const redOptions = { color: "#545454", weight: 7 };

const createCustomIcon = (htmlContent: any) => {
  return L.divIcon({
    html: htmlContent,
    className: "custom-div-icon",
    iconSize: [20, 20],
    iconAnchor: [20, 20]
  });
};

export interface CityOpenMapMarker {
  position: [number, number];
  className: string;
  onClick: any;
  title: string;
  urlKey?: string;
}

export default function CityOpenMap({
  enabledRadio,
  followUser = false,
  markers = []
}: {
  enabledRadio: any;
  followUser?: boolean;
  markers?: CityOpenMapMarker[];
}) {
  const map = useMap();
  const [locationChecked, setLocationChecked] = useLocalStorage<any>(
    "location",
    ""
  );

  const [data, setData] = useState(null);

  useEffect(() => {
    const basePath = window.location.origin;
    const url = `${basePath}/data/ciclovias-rancagua-v2.geojson`;

    fetch(url)
      .then((response) => response.json())
      .then(setData);
  }, []);

  const [isInside, setIsInside] = useState(false);
  const userLocation = useUserLocationContext();
  const userLocationPermission = useUpdateUserLocationContext();

  //recorrido
  const polyline = [
    { lat: -34.15432, lng: -70.7445 },
    { lat: -34.15417, lng: -70.74569 },
    { lat: -34.15408, lng: -70.7457 },
    { lat: -34.15399, lng: -70.74565 },
    { lat: -34.15476, lng: -70.73938 }
  ];

  const visitedIcon = L.divIcon({
    html: "<div className='visited'/>",
    className: "custom-marker-place-visited",
    iconSize: [30, 30],
    iconAnchor: [30, 30]
  });
  const notVisitedIcon = L.divIcon({
    html: "<div className='not-visited'/>",
    className: "custom-marker-place-not-visited",
    iconSize: [30, 30],
    iconAnchor: [30, 30]
  });

  /*  useEffect(() => {
    if (userLocation && userLocation.coordinates && map) {
      map.setView(userLocation.coordinates, map.getZoom());
    } else if (!userLocation.enabled) {
      userLocationPermission(true); // Force user to enable location
    }
  }, [userLocation, map]); */

  return (
    <div>
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      {markers.map((marker, key) => (
        <Marker
          key={key}
          position={marker.position}
          eventHandlers={{ click: marker.onClick }}
          icon={marker.className === "visited" ? visitedIcon : notVisitedIcon}
        ></Marker>
      ))}

      {data && <GeoJSON data={data} style={() => cyclesPolygonStyle} />}

      {userLocation && userLocation.coordinates && (
        <Marker
          position={[
            userLocation.coordinates?.lat,
            userLocation.coordinates?.lng
          ]}
          icon={
            new L.Icon({
              iconUrl: "/assets/icons/my-position.svg",
              iconSize: [30, 30],
              iconAnchor: [20, 40],
              popupAnchor: [0, -40],
              className: "pulse-marker"
            })
          }
        >
          <Popup>Mi ubicación</Popup>
        </Marker>
      )}

      {/* </Polyline> */}
    </div>
  );
}

const cyclesPolygonStyle = {
  color: "#3388FF", // Color del borde
  weight: 4, // Grosor del borde
  opacity: 1, // Opacidad del borde
  fillColor: "#3388FF", // Color de relleno
  fillOpacity: 0.6 // Opacidad del relleno
};

const CustomMarker = styled.div`
  background-color: red;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border: 10px solid white;
`;
