import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Sheet, { SheetRef } from "react-modal-sheet";

// Providers
import { getRoutes, Route } from "providers/Routes";
import { usePlacesData, Place } from "providers/Places";
import { useUserContext } from "providers/User";
import { useUpdateUserLocationContext } from "providers/UserLocation";

// Components
import PlaceCard from "components/PlaceCard";
import SelfGuidedRouteDetail from "components/SelfGuidedRouteDetail";

// UI
import PageContainer from "ui/PageContainer";
import BoxList from "ui/BoxList";
import Title from "ui/Title";

export default function RoutesPage() {
  const ref = useRef<SheetRef>();
  const routes = getRoutes();
  const navigate = useNavigate();
  const userLocationPermission = useUpdateUserLocationContext();
  const [selectedRoute, setSelectedRoute] = useState<Route | null>(null);
  const [places, status] = usePlacesData();
  const user = useUserContext();
  return (
    <PageContainer title="Rutas" loading={status === "loading"}>
      {/* <Grid item xs={12} spacing={0}>
        <Stack spacing={2}>
          {places.map((place, key) => {
            const visited = user
              ? user?.savedMonuments?.some(
                  (vPlace: Place) => vPlace.id === place.id
                )
              : false;
            return (
              <PlaceCard
                key={key}
                place={{ ...place, visited }}
                onClick={() => {
                  userLocationPermission(true);
                  if (place?.urlKey) navigate(`/?place=${place.urlKey}`);
                }}
              />
            );
          })}
        </Stack>
      </Grid> */}
      <h1>ROUTES LIST</h1>
      <h2>ROUTE CARD</h2>
      {/* <Title style={{ margin: "1rem 0" }}>Rutas</Title>
      <Grid item xs={12} spacing={0}>
        <Stack spacing={2}>
          {routes.map((route: Route, key: number) => (
            <PlaceCard
              key={key}
              place={route}
              onClick={() => {
                setSelectedRoute(route);
              }}
            />
          ))}
        </Stack>
      </Grid> */}

      {/*  <Sheet
        isOpen={selectedRoute !== null}
        onClose={() => setSelectedRoute(null)}
        springConfig={{ stiffness: 150, damping: 20, mass: 1 }}
      >
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content style={{ paddingBottom: ref.current?.y }}>
            <PageContainer title={selectedRoute?.name} container={false}>
              <BoxList style={{ padding: 0 }}>
                <SelfGuidedRouteDetail route={selectedRoute} />
              </BoxList>
            </PageContainer>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet> */}
    </PageContainer>
  );
}
