import React, { useEffect, useContext, createContext } from "react";
import { useLocalStorage } from "usehooks-ts";
import { useGeolocated } from "react-geolocated";
import { Coordinates, LOCATION_KEY } from "providers/Location";

export const DEFAULT_CENTER = {
  lat: -34.170354,
  lng: -70.742243
};

const DEFAULT_USER_LOCATION = {
  enabled: false,
  loading: false,
  userDenied: false,
  coordinates: null,
  updatedAt: new Date()
};

export const userLocationContext = createContext<UserLocation>(
  DEFAULT_USER_LOCATION
);
export const userLocationPermissionContext = createContext<any>(null);

export function useUserLocationContext() {
  return useContext(userLocationContext);
}

export function useUpdateUserLocationContext() {
  return useContext(userLocationPermissionContext);
}

function sameCoordinates(a: any, b: any): boolean {
  return a && b && a.lat === b.lat && a.lng === b.lng;
}

export interface UserLocation {
  enabled: boolean;
  loading: boolean;
  userDenied: boolean;
  coordinates?: Coordinates | null;
  updatedAt: Date;
}

type uLocationProviderProps = {
  children: JSX.Element;
};
export default function UserLocationProvider({
  children
}: uLocationProviderProps) {
  const [location, setLocation] = useLocalStorage<UserLocation>(
    LOCATION_KEY,
    DEFAULT_USER_LOCATION
  );

  const { coords, getPosition } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false,
      timeout: Infinity
    },
    geolocationProvider: navigator.geolocation,
    watchPosition: true,
    suppressLocationOnMount: location.enabled ? false : true,
    onError: (err: any) => {
      console.error("ocurrio un problema!", err);
      const newLocation = {
        ...DEFAULT_USER_LOCATION,
        enabled: false,
        coordinates: null,
        userDenied: true,
        loading: false,
        updatedAt: new Date()
      };

      // Save location in localStorage
      setLocation(newLocation);
    },
    onSuccess: (data: any) => {
      const newLocation = {
        ...location,
        enabled: true,
        userDenied: false,
        loading: false,
        updatedAt: new Date()
      };

      // Save location in localStorage
      setLocation(newLocation);
    }
  });

  useEffect(() => {
    let newUserLocation: UserLocation;
    const newCoordinates: Coordinates | null = coords
      ? { lat: coords.latitude, lng: coords.longitude }
      : null;

    if (location.enabled && !location.coordinates && coords) {
      // update location context
      newUserLocation = {
        ...location,
        updatedAt: new Date(),
        coordinates: newCoordinates
      };
      // Save location in localStorage
      setLocation(newUserLocation);
      window.location.reload(); // Force location plugin re-init
    } else if (
      location.enabled &&
      coords &&
      !sameCoordinates(location.coordinates, newCoordinates)
    ) {
      // update user location
      newUserLocation = {
        ...location,
        updatedAt: new Date(),
        coordinates: newCoordinates
        /* coordinates: {
          lat: -34.154836,
          lng: -70.740216
        } */
      };

      /*    setUserLocation(newUserLocation); */
      setLocation(newUserLocation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coords]);

  const updateUserLocationPermission = (enabled: boolean = false): void => {
    // Prompt user
    getPosition();

    // try force
    if (location.userDenied) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then((permissionStatus) => {
          if (permissionStatus.state === "denied") {
            alert("Debes permitir el acceso a tu ubicación en tu navegador");
            window.location.href = "app-settings:location"; // not works :(
          }
        });
    } else {
      const newCoordinates: Coordinates | null = coords
        ? { lat: coords.latitude, lng: coords.longitude }
        : null;

      const newLocation = {
        ...location,
        enabled,
        coordinates: newCoordinates,
        userDenied: false,
        loading: true,
        updatedAt: new Date()
      };

      // Save location in localStorage
      setLocation(newLocation);

      // TODO: async update user location in firebase
    }
  };
  return (
    <div>
      <userLocationContext.Provider value={location}>
        <userLocationPermissionContext.Provider
          value={updateUserLocationPermission}
        >
          {children}
        </userLocationPermissionContext.Provider>
      </userLocationContext.Provider>
    </div>
  );
}
export function isPointInsideRectangle(
  point: { lat: number; lng: number },
  rectangle: { lat: number; lng: number }[]
) {
  const x = point.lat;
  const y = point.lng;
  const minX = Math.min(
    ...rectangle.map((point: { lat: number; lng: number }) => point.lat)
  );
  const maxX = Math.max(
    ...rectangle.map((point: { lat: number; lng: number }) => point.lat)
  );
  const minY = Math.min(
    ...rectangle.map((point: { lat: number; lng: number }) => point.lng)
  );
  const maxY = Math.max(
    ...rectangle.map((point: { lat: number; lng: number }) => point.lng)
  );

  return x >= minX && x <= maxX && y >= minY && y <= maxY;
}
