import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Participant } from "../../providers/Participant";

interface ListResultsProps {
  college: Participant;
  index: number;
}

export default function ListResults({ college, index }: ListResultsProps) {
  const getColor = (index: number): string => {
    if (index < 3) {
      const colors = ["#7AC4AC", "#F4E7A8", "#5B7EBE"];
      return colors[index];
    } else {
      return "transparent";
    }
  };

  return (
    <Stack
      direction="row"
      sx={{
        backgroundColor: getColor(index),
        padding: "1rem",
        justifyContent: "space-between",
        marginTop: "20px",
        borderRadius: "0.3125rem",
        gap: "0.625rem"
      }}
    >
      <Typography
        sx={{
          fontWeight: 700,
          display: "flex",
          alignItems: "center",
          marginBottom: "1.5rem",
          lineHeight: "17.5px",
          fontSize: "16px",
          fontStyle: "normal"
        }}
      >
        {index + 1}°
      </Typography>
      <Stack
        direction="column"
        sx={{
          alignItems: "flex-start",
          flexWrap: "wrap",
          wordBreak: "break-word",
          maxWidth: "155px"
        }}
      >
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: "1rem",
            fontStyle: "normal",
            lineHeight: "17.5px",
            marginBottom: "0.5rem"
          }}
        >
          {college.name}
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "14.5px"
          }}
        >
          {college.category}
        </Typography>
      </Stack>
      <Stack
        direction="column"
        sx={{
          marginTop: "0.5rem"
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "17.5px"
          }}
        >
          {college.numberVotes}
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "17.5px"
          }}
        >
          Votos
        </Typography>
      </Stack>
    </Stack>
  );
}
