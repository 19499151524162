import React from "react";
import styled from "@emotion/styled";

interface WalkCircleProps {
  // Define your props here
}

interface WalkCircleState {
  // Define your state here
}

class WalkCircle extends React.Component<WalkCircleProps, WalkCircleState> {
  constructor(props: WalkCircleProps) {
    super(props);
    // Initialize your state here
    this.state = {};
  }

  render() {
    return (
      <Container>
        <Walk src="/assets/icons/directions_walk.svg" alt="user" />
        {/* <img src="/assets/icons/walk_circle.svg" alt="Walk Circle" /> */}
      </Container>
    );
  }
}

export default WalkCircle;

const Container = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ed9e55;
  stroke-width: 2px;
  border: 2px solid #fff;
  stroke: #fff;
  border-radius: 50%;
  box-shadow: -4px -3px 0px 1px rgba(0, 0, 0, 0.25) inset;
`;
const User = styled.img`
  width: 100%;
  height: auto;
  display: block;
`;

const Walk = styled.img`
  height: auto;
`;

const People = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
  >
    <mask
      id="mask0_384_220"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="25"
    >
      <rect y="0.5" width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_384_220)">
      <path
        d="M7 23.5L9.8 9.4L8 10.1V13.5H6V8.8L11.05 6.65C11.2833 6.55 11.5292 6.49167 11.7875 6.475C12.0458 6.45833 12.2917 6.49167 12.525 6.575C12.7583 6.65833 12.9792 6.775 13.1875 6.925C13.3958 7.075 13.5667 7.26667 13.7 7.5L14.7 9.1C15.1333 9.8 15.7208 10.375 16.4625 10.825C17.2042 11.275 18.05 11.5 19 11.5V13.5C17.8333 13.5 16.7917 13.2583 15.875 12.775C14.9583 12.2917 14.175 11.675 13.525 10.925L12.9 14L15 16V23.5H13V17L10.9 15.4L9.1 23.5H7ZM13.5 6C12.95 6 12.4792 5.80417 12.0875 5.4125C11.6958 5.02083 11.5 4.55 11.5 4C11.5 3.45 11.6958 2.97917 12.0875 2.5875C12.4792 2.19583 12.95 2 13.5 2C14.05 2 14.5208 2.19583 14.9125 2.5875C15.3042 2.97917 15.5 3.45 15.5 4C15.5 4.55 15.3042 5.02083 14.9125 5.4125C14.5208 5.80417 14.05 6 13.5 6Z"
        fill="white"
      />
    </g>
  </svg>
);

const PeopleContainer = styled.div`
  position: absolute;
  left: 8px;
  top: 8px;
`;
