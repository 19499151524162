// Providers

// Components

import * as React from "react";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import MenuIcon from "@mui/icons-material/Menu";


// Components
/* import ShareButton from "components/ShareButton"; */
import WelcomeBanner from "components/WelcomeBanner";
import ProfileButton from "components/ProfileButton";
import ShareDialog from "../ShareDialog";
import ProfileButtonContainer from "containers/ProfileButton";
import {Place} from "../../providers/Places";
import { IconMenu, Icon } from "./styles";

/* import ShareDialog from "components/ShareDialog"; */

const USER_PROFILE_ICON_PATHS = ["/mi-pasaporte"];


interface TopBarProps {
    user?: any;
}

export default function TopBar({
user = null,
}: TopBarProps){
  const location = useLocation();

  return (
    <Box sx={{ flexGrow: 1, height: "10vh" }}>
      <Paper
        sx={{
          top: 0,
          left: 0,
          height: "10vh",
          padding: ".5rem",
        }}
        elevation={0}
      >
        <WelcomeBanner />
        {/*
        <Paper
          sx={{
            position: "fixed",
            top: "0.5rem",
            right: "0.5rem",
            backgroundColor: "transparent",
          }}
          elevation={0}
        >
          <div style={{ display: "flex", justifyContent: "flex-end", backgroundColor: "transparent" }}>
            <IconMenu>
                <Icon src="/assets/icon_heart.svg" alt="Descubre Rancagua Antiguo" />
            </IconMenu>
            <ProfileButtonContainer user={user} />
          </div>
        </Paper>
        */}
      </Paper>
    </Box>
  );
}
