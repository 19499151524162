import { useEffect, useState, useCallback } from "react";
import { useMap, useMapEvents } from "react-leaflet";

import { useUserLocationContext, DEFAULT_CENTER } from "providers/UserLocation";
const DEFAULT_ZOOM = 16;
const MapWithEvents = ({
  following,
  zoom = DEFAULT_ZOOM,
  onStopFollowing = () => {}
}: {
  following: boolean;
  zoom: number;
  onStopFollowing: any;
}) => {
  const map = useMap();
  const userLocation = useUserLocationContext();
  const [lastLocation, setLastLocation] = useState<any>(null);
  useEffect(() => {
    if (map && userLocation && following) {
      setLastLocation(userLocation?.coordinates);
      const center = userLocation?.coordinates || DEFAULT_CENTER;
      map.setView(center, map.getZoom());
    } else if (map && userLocation && !following) {
      setLastLocation(userLocation?.coordinates);
    }
  }, [map, userLocation, following]);

  useEffect(() => {
    if (map && zoom) {
      map.setZoom(zoom);
    }
  }, [map, zoom]);

  const onMove = useCallback(() => {
    if (compareLocations(lastLocation, userLocation?.coordinates)) {
      onStopFollowing();
    }
  }, [map, userLocation, following, lastLocation]);

  useMapEvents({
    move: onMove,
    moveend: () => {
    }
  });

  return null;
};

export default MapWithEvents;

const compareLocations = (location1: any, location2: any) => {
  return (
    location1 &&
    location2 &&
    location1?.lat === location2?.lat &&
    location1?.lng === location2?.lng
  );
};
