import React from "react";
import DOMPurify from "dompurify";

// COMPONENTS
import Typography from "@mui/material/Typography";

interface SanitizeHTMLProps {
  data: any;
  embed?: boolean;
}

export default function Index({ data, embed }: SanitizeHTMLProps) {
  const fullText = DOMPurify.sanitize(
    embed ? data?.description ?? "" : data?.summary ?? ""
  );
  const renderHTML = (html: any) => {
    return { __html: html };
  };

  return (
    <Typography
      fontSize="1rem"
      dangerouslySetInnerHTML={renderHTML(fullText)}
    />
  );
}
