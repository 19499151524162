import styled from "@emotion/styled";
import { Container, Box, Typography, Button, IconButton, TextField, Card, CardContent, CardMedia, Rating } from '@mui/material';


export const PageContainer = styled.div`
  width: 100vw;
  padding: 0 1rem;
  margin-bottom: 4rem;
  min-height: 20vh;
  justify-content: center;
`;

export const StyledContainer = styled(Container)`
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 16px;
  width: 100%;
  overflow: hidden;
`;

export const StyledCard = styled(Card)`
  width: 100vw;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin-top: -50px;
  position: absolute;
  border: none;
  box-shadow: none;

`;

export const Title = styled(Typography)`
  font-size: 1.5rem;
  font-weight: bold;
`;

export const SubTitle = styled(Typography)`
  font-size: 1rem;
  color: gray;
`;

export const Content = styled(CardContent)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const RatingContainer = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

export const IconsContainer = styled(Box)`
display: flex;
flex-direction: column;
align-items: flex-start;
margin-bottom: 8px;
`;
