import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { QrScanner } from "@yudiel/react-qr-scanner";

// UI
import PageContainer from "ui/PageContainer";

// MUI
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import MuiAlert from "@mui/material/Alert";

// Providers
import { useUserContext, useUpdateUserContext } from "providers/User";
import { Place, usePlacesData } from "providers/Places";
import AnalyticsController from "providers/Analytics";

// UI
import Description from "ui/Description";
import Title from "ui/Title";
import { EVENT } from "providers/EventRoute";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// https://www.npmjs.com/package/@yudiel/react-qr-scanner
const QR_BASE_PATH =
  "https://patrimonioyturismo.rancagua.digital/descubre-rancagua/";

export default function ScanPage() {
  const [places, status] = usePlacesData();
  const [qrCode, setQrCode] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [errorCode, setErrorCode] = useState(null);
  const user = useUserContext();
  const updateUser = useUpdateUserContext();
  const navigate = useNavigate();

  const onScan = (qr: string) => {
    if (qr && places && user) {
      setLoading(true);
      setError("");
      setErrorCode(null);
      try {
        setQrCode(qr);
        const urlKey = qr.replace(QR_BASE_PATH, "");
        const place = places.find((place: Place) => place.urlKey === urlKey);
        // TODO: Check if valid QR code
        if (place) {
          const savedMonuments = user?.savedMonuments ?? [];
          const isPlaceSaved = savedMonuments.some(
            (savedPlace: any) =>
              savedPlace.id === place.id && savedPlace.route === EVENT.event
          );
          if (!isPlaceSaved) {
            const updatedSavedMonuments = [
              ...savedMonuments,
              {
                ...place,
                route: EVENT.event,
                savedAt: new Date(),
                fromQr: true
              }
            ];
            updateUser({ ...user, savedMonuments: updatedSavedMonuments });
            AnalyticsController.trackAppEvent("user_scan_success", {
              place: place.name
            });
            setSuccess("Visita guardada en tu pasaporte");
          } else {
            AnalyticsController.trackAppEvent("user_scan_success", {
              place: place.name
            });
            setSuccess("Visita guardada en tu pasaporte");
          }
          setLoading(false);
        } else {
          setError("El QR no es válido");
          setLoading(false);
        }
      } catch (error) {
        AnalyticsController.trackAppEvent("user_scan_error", "scan_error");
        setError("Ocurrio un problema al escanear el QR");
        setLoading(false);
      }
    }
  };

  const handleClose = () => {
    navigate("/rutas/con-tito-lastarria-24/map?menu=map");
  };

  return (
    <PageContainer loading={loading || status === "loading"}>
      <Stack sx={{ marginTop: "2rem" }}>
        <Title>Escanea y registra tu visita</Title>
        <Description style={{ textAlign: "left" }}>
          Acércate a los puntos del recorrido y <b>Escanea los QR</b> dispuestos
          en los Tótem Informativos para registrar tu visita
        </Description>
        {error && <Alert severity="error">{error}</Alert>}

        <QrScanner
          onDecode={onScan}
          onError={(error: any) => {
            setLoading(false);
            setError("Ocurrio un problema al escanear el QR");
            setErrorCode(error);
          }}
        />

        <Button
          variant="outlined"
          color="inherit"
          sx={{
            borderRadius: "4vh",
            width: "100%",
            marginTop: "1.5vh"
          }}
          onClick={() => {
            navigate(-1);
          }}
        >
          Volver
        </Button>
        {errorCode && <pre>{JSON.stringify(errorCode)}</pre>}
        {/* {qrCode && <pre>{JSON.stringify(qrCode)}</pre>} */}
        {/* <Snackbar
        open={success!== ''}
        autoHideDuration={6000}
        onClose={()=>{
          navigate("/mi-pasaporte");
        }}
        message="Note archived"
        action={action}
      /> */}

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={success !== ""}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            {success}
          </Alert>
        </Snackbar>
      </Stack>
    </PageContainer>
  );
}
