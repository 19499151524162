import React from 'react';
import { CardStyled, CardLink } from "./styles";
import AnalyticsController from "providers/Analytics";



interface CardProps {
  id: string;
  title: string;
  subtitle: string;
  description?: string;
  image: string;
}

const CardRoute = ({ id, title, subtitle, description, image }: CardProps) => {
  const handleClick = () => {
    window.location.href = `/rutas/${id}`;
  };

  return (
    <CardStyled>
      <CardLink href={`/rutas/${id}`} onClick={(e) => {
          e.preventDefault();
          AnalyticsController.trackButtonClicked("route_card_click");
          window.location.href = `/rutas/${id}`;
        }}
      />
      <div className="image-container">
        <img src={image} alt={title} />
      </div>
      <h3>{title}</h3>
      <p>{subtitle}</p>
      {description && <p>{description}</p>}
    </CardStyled>
  );
};

export default CardRoute;
