import React, { useState } from "react";
import styled from "@emotion/styled";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import CircularButton from "components/CircularButton";

import Title from "ui/Title";
import Description from "ui/Description";
import ActionButton from "ui/ActionButton";
/* 
interface LayersInformationProps {
  icon: React.ReactNode;
  progressColor?: string;
  percentage?: number;
  onClick?: () => void;
} */

export default function LayersInformation() {
  return (
    <Container>
      <Grid
        container
        style={{
          padding: "0 16px",
          width: "100%"
        }}
      >
        <Grid xs={12} style={{ padding: "1rem 0" }}>
          <CircularButton
            icon={"bike"}
            title="Ciclovías"
            color="rgb(51, 136, 255)"
            onClick={() => {}}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

const Container = styled.div`
  padding: 1rem 0;
`;
