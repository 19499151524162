import styled from '@emotion/styled';

export const CardLink = styled.a`
  /* Estilos del enlace */
  text-decoration: none;
  color: inherit;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1; 
`;

export const CardStyled = styled.div`
  position: relative;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  background-color: #f4f4f4;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e0e0e0;
  }

  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;
    border-radius: 5px;
    overflow: hidden;
    padding: 10px 0;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  h3 {
    font-size: 16px;
    margin: 10px 0;
    padding: 0 80px;
  }

  p {
    font-size: 14px;
    color: #666;
  }
`;
