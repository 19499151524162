import styled from "@emotion/styled";

export const Number = styled.span`
  font-weight: bold;
`;

export const Container = styled.div`
  background-color: #131426;
  color: white;
  text-align: center;
  padding: 0.5rem;
  border-radius: 12px;
  margin-top: 1rem;
`;
