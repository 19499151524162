import styled from "@emotion/styled";
import Box from "@mui/material/Box";
export const StampContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: auto;
  padding: 0.3rem;
  min-width: 100px;
  min-height: 100px;
  max-height: 120px;
  max-width: 120px;
`;

export const StampOverlay = styled(Box)`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
