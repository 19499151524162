import styled from "@emotion/styled";

export const MapContainer = styled.div`
  min-height: 79vh;
  background: #fcfcfc;
`;

export const MarkerContainer = styled.div`
  background: transparent;
  color: white;
  min-height: 100px;
  min-width: 100px;
`;
