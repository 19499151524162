import React, { useState, useRef, useEffect, useCallback } from "react";
import styled from "@emotion/styled";
import { useNavigate, useSearchParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { Sheet, SheetRef } from "react-modal-sheet";
import AnalyticsController from "providers/Analytics";

import {
  MapContainer,
  LayerGroup,
  Circle,
  SVGOverlay,
  useMapEvents,
  useMap
} from "react-leaflet";

// Providers
import { getRoutes, Route } from "providers/Routes";
import { usePlacesData, Place } from "providers/Places";
import { useUserContext, useUpdateUserContext } from "providers/User";

// Utils
import { formatAddress } from "utils";

// Components
import PlaceCard from "components/PlaceCard";
import SelfGuidedRouteDetail from "components/SelfGuidedRouteDetail";
import QrInformation from "components/QrInformation";
import LayersInformation from "components/LayersInformation";
import MapInformation from "components/MapInformation";
import AwardsInformation from "components/AwardsInformation";

// Providers
import { checkQrToken } from "providers/Token";
import {
  useUserLocationContext,
  useUpdateUserLocationContext
} from "providers/UserLocation";

// Components
import MapButton from "components/MapButton";
import CityOpenMap, { CityOpenMapMarker } from "components/CityOpenMap";
import MapHeader from "components/MapHeader";
import ProgressIndication from "components/ProgressIndication";
import PlaceDetail from "components/PlaceDetail";
import MapWithEvents from "components/MapWithEvents";
// UI
import PageContainer from "ui/PageContainer";
import BoxList from "ui/BoxList";
import Title from "ui/Title";
import WalkCircleIcon from "ui/WalkCircleIcon";
import GiftIcon from "ui/GiftIcon";
import "leaflet/dist/leaflet.css";
import "./styles.scss";
const DEFAULT_CENTER = {
  lat: -34.170354,
  lng: -70.742243
};
const DEFAULT_ZOOM = 16;

const MENU = {
  map: {
    title: "Pasaporte",
    name: "map",
    size: 600,
    description: ""
  },
  scanner: {
    title: "",
    name: "scanner",
    size: 600,
    description: ""
  },
  gift: {
    title: "¡Recorre y Participa en el Sorteo de un Viaje a Sewell!",
    name: "gift",
    size: 600,
    description: ""
  },
  layers: {
    title: "Información del mapa",
    name: "layers",
    size: 300,
    description: ""
  }
};

const fillBlueOptions = { fillColor: "blue" };

interface MenuOpc {
  title?: string;
  name: string;
  description?: string;
  size: number;
}
export default function RouteDetailMap() {
  const ref = useRef<SheetRef>();
  const mapRef = useRef(null);
  const [qrKey, setQrKey] = useState<string>("");
  const user = useUserContext();
  let [searchParams, setSearchParams] = useSearchParams();
  const routes = getRoutes();
  const navigate = useNavigate();
  const userLocation = useUserLocationContext();
  const userLocationPermission = useUpdateUserLocationContext();
  const updateUser = useUpdateUserContext();
  const [selectedRoute, setSelectedRoute] = useState<Route | null>(null);
  const [selectedPlace, setSelectedPlace] = useState<any>(null);
  const [center, setCenter] = useState<any>(DEFAULT_CENTER);
  const [zoom, setZoom] = useState<number>(DEFAULT_ZOOM);
  const [following, setFollowing] = useState<boolean>(false);
  const [lastPosition, setLastPosition] = useState<any>({ lat: 0, lng: 0 });
  const [places, status] = usePlacesData();
  const [activeMenu, setActiveMenu] = useState<MenuOpc | any>(null);
  const [qrAuthorization, setQrAuthorization] = useState(false);
  const savedMonuments = user?.savedMonuments || [];
  const markers: CityOpenMapMarker[] = places.map((place: Place) => {
    return {
      position: [
        place?.location?.coordinates?.lat || 0,
        place?.location?.coordinates?.lng || 0
      ],
      className:
        savedMonuments.filter((monument, key) => {
          return monument.id === place.id;
        }).length > 0
          ? "visited"
          : "not-visited", // TODO: change to visited
      onClick: () => {
        AnalyticsController.trackButtonClicked("place_marker");
        setSelectedPlace(place);
        setSearchParams({ place: place?.urlKey || "" });
      },
      title: place.name,
      urlKey: place?.urlKey || ""
    };
  });

  useEffect(() => {
    if (qrKey && user && selectedPlace) {
      // TODO: check qrKey
      const valid: boolean = checkQrToken(qrKey, selectedPlace?.urlKey ?? "");
      setQrAuthorization(valid);
    }
  }, [selectedPlace, user, qrKey]);

  useEffect(() => {
    const placeKey = searchParams.get("place");
    const menuKey = searchParams.get("menu");
    const qr = searchParams.get("qr") || "";
    setQrKey(qr);
    if (placeKey && places) {
      const place = places.find((place: Place) => place.urlKey === placeKey);
      if (place) {
        setSelectedPlace(place);
      }
    } else if (menuKey !== "") {
      const menu = Object.values(MENU).find(
        (menu: MenuOpc) => menu.name === menuKey
      );
      if (menu) setActiveMenu(menu);
    }
  }, [searchParams, places]);

  useEffect(() => {
    if (activeMenu) setSearchParams({ menu: activeMenu?.name || "" });
  }, [activeMenu]);

  const onFollowUser = () => {
    userLocationPermission(true);
    setFollowing(true);
  };

  const onStopFollowing = () => {
    setFollowing(false);
  };

  const onClosePlace = () => {
    setSelectedPlace(null);
    setSearchParams({ place: "" });
  };
  const onCloseMenu = () => {
    setActiveMenu(null);
    setSearchParams({ menu: "" });
  };

  return (
    <PageContainer title="" loading={status === "loading"} container={false}>
      <MapWrapper>
        <MapContainer
          style={{ height: "100vh", width: "100%", zIndex: 0 }}
          center={center}
          zoom={zoom}
          zoomControl={false}
        >
          <MapWithEvents
            following={following}
            zoom={zoom}
            onStopFollowing={onStopFollowing}
          />
          <CityOpenMap enabledRadio={[]} markers={markers} />
        </MapContainer>

        {/* OVERLAY */}
        <OverlayButtonsWrapper>
          <MapHeader logo="/assets/routes/con-tito-header.svg" user={user} />

          <Grid
            container
            style={{
              padding: "0 16px",
              width: "100%"
            }}
          >
            <Grid xs={10}>
              <ProgressIndication
                icon={<WalkCircleIcon />}
                current={user?.savedMonuments?.length || 0}
                total={places.length}
                onClick={() => {
                  setActiveMenu(MENU.map);
                }}
              />
              <div
                style={{
                  marginLeft: "26px",
                  marginTop: "-6px"
                }}
              >
                <ProgressIndication
                  icon={<GiftIcon />}
                  progressColor="#3C47B5"
                  current={user?.savedMonuments?.length || 0}
                  total={10}
                  onClick={() => {
                    setActiveMenu(MENU.gift);
                  }}
                />
              </div>
            </Grid>

            <Grid xs={2}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end"
                }}
              >
                <RightButtonsWrapper>
                  <MapButton
                    icon={<img src="/assets/icons/layers.svg" alt="layers" />}
                    backgroundColor="#8BDB5A"
                    onClick={() => {
                      AnalyticsController.trackButtonClicked("layers_button");
                      setActiveMenu(MENU.layers);
                    }}
                  />
                  {/*   <MapButton
                    icon={<img src="/assets/icons/map_white.svg" alt="Mapa" />}
                    backgroundColor="#8BDB5A"
                    onClick={() => {
                      setActiveMenu({
                        title: "Pasaporte",
                        name: "map",
                        size: 600,
                        description: ""
                      });
                    }}
                  /> */}

                  <MapButton
                    icon={
                      <img
                        src="/assets/icons/qr_code_scanner.svg"
                        alt="scaner"
                      />
                    }
                    backgroundColor="#E56B6B"
                    onClick={() => {
                      AnalyticsController.trackButtonClicked("qr_button");
                      setActiveMenu(MENU.scanner);
                    }}
                  />
                </RightButtonsWrapper>
              </div>
            </Grid>
          </Grid>
        </OverlayButtonsWrapper>

        <LocationButtonWrapper>
          <MapButton
            icon={
              <img
                src={`/assets/icons/explore${following ? "_active" : ""}.svg`}
                alt="ubicacion"
              />
            }
            backgroundColor={"#FFF"}
            boxShadow="0px 1px 0.9px 0px rgba(0, 0, 0, 0.25)"
            onClick={() => {
              AnalyticsController.trackButtonClicked("follow_user_button");
              onFollowUser();
            }}
          />
        </LocationButtonWrapper>
      </MapWrapper>
      <Sheet
        isOpen={selectedPlace !== null}
        snapPoints={[600]}
        initialSnap={0}
        onClose={onClosePlace}
        /* springConfig={{ stiffness: 150, damping: 20, mass: 1 }} */
        style={{ zIndex: 999 }}
        /*   detent="content-height" */
      >
        {/* SELECTED PLACE */}
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            <Sheet.Scroller draggableAt="both">
              <div className="scrollable-content">
                <PageContainer
                  title={selectedPlace?.name}
                  description={formatAddress(selectedPlace)}
                >
                  {selectedPlace && (
                    <PlaceDetail
                      place={selectedPlace}
                      user={user}
                      onUpdateUser={updateUser}
                      qrAuthorization={qrAuthorization}
                    />
                  )}
                </PageContainer>
              </div>
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={onClosePlace} />
      </Sheet>

      <Sheet
        isOpen={activeMenu !== null}
        onClose={onCloseMenu}
        style={{ zIndex: 999 }}
        snapPoints={[activeMenu?.size || 400]}
        initialSnap={0}
        detent="content-height"
      >
        {/* SELECTED MENU */}
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            <Sheet.Scroller draggableAt="both">
              {/*  <div className="scrollable-content"> */}
              <PageContainer
                title={activeMenu?.title || ""}
                description={activeMenu?.description || ""}
              >
                {activeMenu?.name === "scanner" && <QrInformation />}
                {activeMenu?.name === "map" && (
                  <MapInformation user={user} places={places} />
                )}
                {activeMenu?.name === "layers" && <LayersInformation />}
                {activeMenu?.name === "gift" && (
                  <AwardsInformation user={user} />
                )}
              </PageContainer>
              {/* </div> */}
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={onCloseMenu} />
      </Sheet>
    </PageContainer>
  );
}

const MapWrapper = styled.div`
  position: relative;
`;

const OverlayButtonsWrapper = styled.div`
  position: absolute;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 30vh;
`;

const RightButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-items: end;
  justify-content: flex-end;
  gap: 16px;
`;

const LocationButtonWrapper = styled.div`
  position: absolute;
  top: 80vh;
  right: 16px;
  width: auto;
  z-index: 100;
`;
