import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import { MapContainer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

// Hooks
import { usePollChecker } from "hooks/usePollChecker";

// Components
import CityOpenMap from "components/CityOpenMap";
import Loading from "components/Loading";
import AlertMessage from "components/AlertMessage";

// Providers
import {
  useUserLocationContext,
  isPointInsideRectangle
} from "providers/UserLocation";

const DEFAULT_CENTER = {
  lat: -34.15437694071006,
  lng: -70.74286440137391
};
const DEFAULT_ZOOM = 16;
const RECTANGLE = [
  { lat: -34.15210886640515, lng: -70.74614474073246 },
  { lat: -34.15322065090912, lng: -70.73735313823663 },
  { lat: -34.15683501238327, lng: -70.73812886787417 },
  { lat: -34.15577012887842, lng: -70.7470359091303 },
  { lat: -34.15210886640515, lng: -70.74614474073246 }
];

export default function LocationCheck() {
  const navigate = useNavigate();
  const [title, setTitle] = useState("Comprobando Ubicación");
  const [buttonText, setButtonText] = useState("Escanear QR");
  const [isInside, setIsInside] = useState(false);
  const [description, setDescription] = useState(
    "Estamos revisando si te encuentras dentro del recorrido de los carros"
  );
  const userLocation = useUserLocationContext();
  const {
    error,
    loading,
    hasVoted,
    qrChecked,
    authenticated,
    setLocationChecked
  } = usePollChecker();

  useEffect(() => {
    if (hasVoted) {
      navigate(`/voting-view/${hasVoted}`);
    } else if (authenticated && qrChecked) {
      navigate("/voting");
    }
  }, [hasVoted, qrChecked, authenticated]);

  useEffect(() => {
    if (userLocation && userLocation.coordinates) {
      const insideRectangle = isPointInsideRectangle(
        userLocation.coordinates,
        RECTANGLE
      );
      setIsInside(insideRectangle);
      if (insideRectangle) {
        setTitle("Ubicación lista");
        setButtonText("Ir a votar");
        setDescription(
          "Ahora también eres protagonista de esta fiesta. \n" +
            "Ya puedes votar por tu carro favorito."
        );
        setLocationChecked(JSON.stringify(userLocation.coordinates));
      } else {
        setTitle("¡Estás muy lejos!");
        setButtonText("Escanear QR");
        setDescription("Acércate a la zona marcada en el mapa para votar."); //
      }
    }
  }, [userLocation]);

  if (loading) {
    setTimeout(() => {}, 1000);
    return <Loading />;
  }

  return (
    <>
      <MapContainer
        style={{ height: "60vh", width: "100vw", minHeight: "60vh" }}
        center={DEFAULT_CENTER}
        zoom={DEFAULT_ZOOM}
      >
        <CityOpenMap enabledRadio={RECTANGLE} />
      </MapContainer>
      {/* {userLocation && userLocation.coordinates && (
        <pre>{JSON.stringify(userLocation.coordinates)}</pre>
      )} */}
      <Grid>
        <Grid>
          <Box>
            <Grid
              container
              spacing={25}
              sx={{
                paddingTop: "36px",
                paddingBottom: "13px",
                paddingLeft: "16px",
                whiteSpace: "nowrap"
              }}
            >
              <AlertMessage
                open={error !== ""}
                severity="error"
                message={error}
              />
              <Grid item xs={6} md={6}>
                <Typography
                  variant="h1"
                  gutterBottom
                  sx={{
                    fontSize: "30px",
                    fontWeight: "700",
                    whiteSpace:
                      title === "¡Estás muy lejos!" ||
                      title === "Ubicación lista"
                        ? "nowrap"
                        : "normal"
                  }}
                >
                  {title}
                </Typography>
              </Grid>
              <Grid item xs={6} md={6}>
                {userLocation && userLocation.loading && <CircularProgress />}
                {userLocation && userLocation.enabled && isInside && (
                  <CheckCircleOutlineIcon
                    sx={{ color: "#4CAF50", fontSize: 40 }}
                  />
                )}
              </Grid>
            </Grid>
            <Typography
              variant="h2"
              gutterBottom
              sx={{
                fontSize: "14px",
                fontWeight: "400",
                paddingX: "16px",
                paddingBottom: "26px",
                lineHeight: "14.5px"
              }}
            >
              {description}
            </Typography>
            <Grid
              container
              spacing={buttonText === "Ir a votar" ? 9 : 5}
              sx={{
                /*  paddingX: "50px", */ paddingBottom: "50px",
                paddingLeft: buttonText === "Ir a votar" ? "10px" : "16px"
              }}
            >
              <Grid item>
                <Button
                  variant="text"
                  size="large"
                  sx={{ textTransform: "none" }}
                  onClick={() => navigate("/votacion/rancagua2023")}
                >
                  <Typography
                    variant="h2"
                    gutterBottom
                    sx={{ color: "black", fontSize: "16px", fontWeight: "700" }}
                  >
                    Cancelar
                  </Typography>
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    background: "black",
                    textTransform: "none",
                    borderRadius: "0px"
                  }}
                  onClick={() =>
                    buttonText === "Escanear QR"
                      ? navigate("/qr-check")
                      : navigate("/voting")
                  }
                >
                  <Typography
                    variant="h2"
                    sx={{ color: "#FFF", fontSize: "16px", fontWeight: "700" }}
                  >
                    {buttonText}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
