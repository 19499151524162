import axios from "axios";

const POLL_SAVE_VOTE_ENDPOINT =
  "https://services-moleculer.smartrancagua.com/votes";
/* const POLL_SAVE_VOTE_ENDPOINT =
  "https://services-preprod.smartrancagua.com/votes"; */
//const POLL_SAVE_VOTE_ENDPOINT = "http://localhost:8081/votes";

export const Poll = {
  id: "",
  name: "",
  description: "",
  dateTo: "10 nov 2023 22:15",
  dateFrom: "10 nov 2023 19:00",
  tags: [],
  options: [
    {
      id: "1",
      title: "1 nombre del carro",
      description: "nombre colegio",
      cover: "",
      images: []
    }
  ],
  summary: {
    "1": {
      votes: 0
    },
    lastUpdate: "now"
  }
};

export const Vote = {
  poll: "1",
  user: "",
  key: "{poll}-{user}",
  source: "qr", // enum qr, location, web, app
  option: "1",
  date: "10 nov 2023 19:00"
};

export const saveVoteMS = async (vote: any) => {
  try {
    const { data } = await axios.post(POLL_SAVE_VOTE_ENDPOINT, { ...vote });
    return Promise.resolve(data);
  } catch (error) {
    console.log("error", error);
    return Promise.reject(error);
  }
};
