import styled from "@emotion/styled";
import Button from "@mui/material/Button";

export const ActionButton = styled(Button)`
  border-radius: 30px;
  font-weight: bold;
  padding: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #000000;
  background: #f7d765;
`;
/* #f7d765 */
/* background: #eadd30; */
export default ActionButton;
