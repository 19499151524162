import React, { useState } from "react";
import styled from "@emotion/styled";

interface ProgressIndicationProps {
  icon: React.ReactNode;
  progressColor?: string;
  percentage?: number;
  current?: number;
  total?: number;
  onClick?: () => void;
}

export default function ProgressIndication({
  icon,
  progressColor = "#f7d765",
  percentage = 0,
  current = 0,
  total = 0,
  onClick = () => {}
}: ProgressIndicationProps) {
  const progressWidth = current <= total ? (current / total) * 100 : 100;
  return (
    <div onClick={onClick}>
      <Container>
        <Progress>
          <Count>
            {current}/{total}
          </Count>
        </Progress>
        <Indicator
          style={{
            backgroundColor: progressColor,
            width: progressWidth + "%",
            borderRadius: progressWidth === 100 ? "43px" : "43px 0 0 43px"
          }}
        />
        {icon}
      </Container>
    </div>
  );
}

const Count = styled.span`
  position: absolute;
  right: 0px;
  font-size: 0.8rem;
  color: #888888;
  font-weight: bold;
  padding: 2px 10px;
  z-index: 1;
`;

const Container = styled.div`
  position: relative;
  width: 160px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: left;
`;

const Progress = styled.div`
  position: absolute;
  top: 14px;
  left: 10px;
  width: 100%;
  height: 25px;
  background-color: #d9d9d9;
  border-color: #ffffff;
  border-radius: 43px;
  border: 2px solid #ffffff;
`;
const Indicator = styled.div`
  position: absolute;
  top: 16px;
  left: 10px;
  width: 50%;
  height: 21px;
  background-color: #f7d765;
  transition: width 0.5s ease-in-out;
  animation: pulse 1.5s infinite;

  @keyframes pulse {
    0% {
      filter: brightness(1);
    }
    50% {
      filter: brightness(1.2);
    }
    100% {
      filter: brightness(1);
    }
  }
`;
