import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import styled from "@emotion/styled";
import Stack from "@mui/material/Stack";

// Providers
import { Place, usePlacesData } from "providers/Places";
import { useUserContext } from "providers/User";
import AnalyticsController from "providers/Analytics";
import { EVENT } from "providers/EventRoute";
// Components
import Stamp from "components/Stamp";

// UI
import PageContainer from "ui/PageContainer";
import CheckCard from "components/CheckCard";
import PrizesDialog from "components/PrizesDialog";

export default function PlacesList() {
  const user = useUserContext();
  // Places custom-hook
  const [places, status] = usePlacesData();
  const [place, setPlace] = useState<Place | null>(null);
  const [stampOpen, setStampOpen] = useState(false);

  const sortedPlaces = places
    .map((place: Place) => {
      const visited = user
        ? user?.savedMonuments?.some(
            (vPlace: Place) =>
              vPlace.id === place.id && vPlace.route === EVENT.event
          )
        : false;
      return { ...place, visited };
    })
    .sort((a, b) => (a?.distance || 0) - (b?.distance || 0));
  /* .sort((a, b) => (a.visited ? -1 : 1) - (b.visited ? -1 : 1))
    .reverse(); */

  const savedPlaces: number =
    user?.savedMonuments?.filter((place) => place.route === EVENT.event)
      .length || 0;
  /* const pageTitle = savedPlaces
    ? `${savedPlaces} de ${places.length} lugares visitados `
    : "¡Descubre la ciudad y obtén recompensas!"; */

  const pageDescription =
    savedPlaces > 0 ? "¡Descubre la ciudad y obtén recompensas!" : "";
  return (
    <>
      <PageContainer
        title={""}
        loading={status === "loading"}
        description={"" /* pageDescription */}
        container={false}
      >
        <Stack direction="column">
          {/*  <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              margin: "2rem 0"
            }}
          >
            <PrizesDialog />
          </div> */}
          {/* {!user && <HowTo />} */}
          <Grid xs={12} container>
            {sortedPlaces.map((place: Place, key: number) => {
              return (
                <Grid key={key} xs={6}>
                  <StampCover
                    style={{
                      background: `${place.visited ? "#fffae9" : "white"}`,
                      border: `${
                        place.visited
                          ? "1px solid #ed9e55"
                          : "1px solid #C8C8C8"
                      }`
                    }}
                  >
                    <Stamp
                      visited={place.visited}
                      place={place}
                      onClick={() => {
                        AnalyticsController.trackAppEvent(
                          "user_tracks_place",
                          place.name
                        );
                        setPlace(place);
                        !place.visited && setStampOpen(true);
                      }}
                    />
                    <PlaceName>{place.name}</PlaceName>
                    <Distance>{place.distance}m</Distance>
                    <Distance></Distance>
                  </StampCover>
                </Grid>
              );
            })}
          </Grid>
        </Stack>
        <CheckCard
          place={place}
          route={EVENT.event}
          isOpen={stampOpen}
          showCloseIcon={false}
          onClose={() => {
            AnalyticsController.trackAppEvent(
              "user_opens_stamp",
              place?.name ?? ""
            );
            setStampOpen(false);
          }}
        />
        {/* {user && (
        <Grid xs={12}>
          <StampsProgress />
        </Grid>
      )} */}
      </PageContainer>
    </>
  );
}

const StampCover = styled.div`
  height: 200px;
  margin: 0 4px 8px 4px;
  border-radius: 16px;
  padding: 24px;
  text-align: center;
  background: #fffae9;
`;

/* const StampCover = styled.div(StampCoverBase)((props: any) => {
  return (
    props.visited && {
      boxShadow: props.visited ? "0 0 8px rgba(0, 0, 0, 0.1)" : "none"
    }
  );
}); */

const PlaceName = styled.h4`
  font-size: 0.8rem;
  font-weight: 700;
  margin: 0;
  padding: 0;
  color: #505050;
`;
const Distance = styled.p`
  font-size: 0.7rem;
  font-weight: 400;
  margin: 0;
  padding: 0;
  color: #868686;
`;
