import React, { useState, useEffect, useRef } from "react";
import { MapContainer, MarkerContainer } from "./styles";

// components
import MapMarker from "components/MapMarker";

/**
 * Based on
 * https://www.youtube.com/watch?v=8kxYqoY2WwE&ab_channel=GoogleMapsPlatform
 */

export const DEFAULT_CENTER_POSITION: Position = {
  lat: -34.1704879,
  lng: -70.7440712,
};

export const GOOGLE_MAPS_API_KEY = {
  id: "4898b1d21c1f463b",
  key: "AIzaSyCO9J0GEavFS6Ivjvl3f-QW9fd0yuNXuJI",
};

export const DEFAULT_ZOOM = 15;
export const WALK_ZOOM = 18;
export const MAP_OPTIONS = {
  mapId: GOOGLE_MAPS_API_KEY.id,
  center: DEFAULT_CENTER_POSITION,
  zoom: DEFAULT_ZOOM,
  version: "beta",
  disableDefaultUI: true,
};

export interface Position {
  lat: number;
  lng: number;
}

export interface Point {
  idx?: number;
  content?: any;
  position: Position;
  onClick?: any;
}

interface MapProps {
  points?: Point[];
  center?: Position;
  zoom?: number;
  onInit?: any;
}

export default function Map({
  points,
  center,
  zoom,
  onInit = (map: any) => {},
}: MapProps) {
  const ref = useRef<any>();
  const [map, setMap] = useState<any>();
  const [activeMarker, setActiveMarker] = useState<Point | null>(null);

  useEffect(() => {
    onInit(map);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  useEffect(() => {
    const m = new window.google.maps.Map(ref.current, MAP_OPTIONS);
    setMap(m);
    return () => {};
  }, []);

  useEffect(() => {
    if (map) {
      center && map.panTo(center); // TODO: update center!!!
      zoom && map.setZoom(zoom);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [center, zoom]);

  const onClickMarker = (fn: any, point: Point) => {
    return (e: any) => {
      if (
        (activeMarker && point.idx !== activeMarker.idx) ||
        (!activeMarker && point)
      ) {
        setActiveMarker(point);
      } else {
        // if same point (close)
        setActiveMarker(null);
      }
      return map ? fn(map) : null;
    };
  };

  return (
    <>
      <MapContainer id="map" ref={ref} />
      {/* Markers */}
      {points &&
        points.map((point: Point, idx: number) => {
          return (
            <MapMarker
              key={idx}
              map={map}
              position={point.position}
              onClick={onClickMarker(point.onClick, { ...point, idx })}
            >
              <MarkerContainer>
                <div
                  className={`${
                    activeMarker && activeMarker.idx === idx
                      ? "active-marker"
                      : "inactive-marker"
                  }`}
                >
                  {point.content}
                </div>
              </MarkerContainer>
            </MapMarker>
          );
        })}
    </>
  );
}
