import React, { useState, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import { Sheet, SheetRef } from "react-modal-sheet";
import Typography from "@mui/material/Typography";
import { useSearchParams, useNavigate, Link } from "react-router-dom";
import FloatingBackButton from "components/FloatingBackButton";

// Providers
import { Activity, useActivitiesData } from "providers/Activities";

// Components
import ActivityListCard from "components/ActivityListCard";
import ActivityDetail from "components/ActivityDetail";

// UI
import PageContainer from "ui/PageContainer";
import BoxList from "ui/BoxList";
import AnalyticsController from "../../providers/Analytics";
import ReactGA from "react-ga4";
import ActivityListCardContainer from "../../containers/ActivityListCard";

export default function ActivitiesPage() {
  const ref = useRef<SheetRef>();
  const [activities, status] = useActivitiesData();
  const [selectedActivity, setSelectedActivity] = useState<Activity | null>(
    null
  );
  let [searchParams, setSearchParams] = useSearchParams();
  const [dragActive, setDragActive] = useState<boolean>(true);
  const navigate = useNavigate();
  const onCloseSheet = () => {
    setSelectedActivity(null);
    setSearchParams("");
    setDragActive(false);
  };
  useEffect(() => {
    const activityKey = searchParams.get("actividad");
    if (activityKey !== selectedActivity?.urlKey) {
      if (activityKey && activities) {
        const activityFound = activities.find(
          (act: Activity) => act.urlKey === activityKey
        );
        if (activityFound && selectedActivity !== activityFound) {
          setSelectedActivity(activityFound);
        }
      }
    }
  }, [searchParams, activities, selectedActivity]);
  const ScrollToTop = () => {
    useEffect(() => {
      window.scrollTo(0, 0); // Desplaza la ventana del navegador al inicio absoluto
    }, []);

    return null;
  };

  return (
    <div style={{ marginTop: "19.9px" }}>
      <PageContainer
        title="Actividades"
        description=""
        loading={status === "loading"}
      >
        <ScrollToTop />
        <Box sx={{ zIndex: "100", position: "absolute", top: "11.5%" }}>
          <Link to="/" style={{ textDecoration: "none", color: "black" }}>
            <FloatingBackButton />
          </Link>
        </Box>
        {activities &&
          activities.map((activity: Activity, key: number) => {
            return (
              <Box key={key} sx={{ width: "100%" }}>
                {status === "error" && <Typography>Fallo</Typography>}
                {status === "success" && (
                  <ActivityListCardContainer
                    activity={activity}
                    onClick={() => {
                      AnalyticsController.trackActivityOpen(activity.title);
                      navigate(`/actividad/${activity.urlKey}`);
                      //setSelectedActivity(activity);
                    }}
                  />
                )}
              </Box>
            );
          })}

        <Sheet
          isOpen={selectedActivity !== null}
          snapPoints={[600]}
          initialSnap={0}
          onClose={onCloseSheet}
          /*  springConfig={{ stiffness: 150, damping: 20, mass: 1 }} */
        >
          <Sheet.Container>
            <Sheet.Header />
            <Sheet.Content style={{ paddingBottom: ref.current?.y }}>
              <div className="scrollable-content">
                <PageContainer title={selectedActivity?.title}>
                  <BoxList>
                    <ActivityDetail activity={selectedActivity} />
                  </BoxList>
                </PageContainer>
              </div>
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop onTap={onCloseSheet} />
        </Sheet>
      </PageContainer>
    </div>
  );
}
