import React from "react";
import "./styles.scss";

interface YouTubeEmbedProps {
  videoId: string;
  width?: string;
  height?: string;
  title?: string;
}

const YouTubeEmbed: React.FC<YouTubeEmbedProps> = ({
  videoId,
  width = "100%",
  height = "200",
  title = ""
}) => {
  const videoUrl = `https://www.youtube.com/embed/${videoId}?rel=0&disablekb=1&modestbranding=1`;

  return (
    <div className="video-responsive">
      <iframe
        width={width}
        height={height}
        src={videoUrl}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title={title}
      />
    </div>
  );
};

export default YouTubeEmbed;
