import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import AnalyticsController from "providers/Analytics";
import Grid from "@mui/material/Grid";
import Sheet, { SheetRef } from "react-modal-sheet";
import { Favorite, FavoriteBorder } from "@mui/icons-material";
import {
  Container,
  Box,
  Typography,
  Button,
  IconButton,
  TextField,
  Card,
  CardContent,
  CardMedia,
  Rating
} from "@mui/material";
import styled from "@emotion/styled";
// Providers
import { getRoutes, Route } from "providers/Routes";
import { usePlacesData, Place } from "providers/Places";
import { useUserContext } from "providers/User";
import { useUpdateUserLocationContext } from "providers/UserLocation";

// Components
import PlaceCard from "components/PlaceCard";
import EventCountdown from "components/EventCountdown";
import SelfGuidedRouteDetail from "components/SelfGuidedRouteDetail";

// UI
import PageContainer from "ui/PageContainer";
import {
  StyledContainer,
  StyledCard,
  Title,
  SubTitle,
  Content,
  RatingContainer,
  IconsContainer
} from "./styles";
import Description from "ui/Description";
import { map } from "lodash";
import FloatingBackButton from "components/FloatingBackButton";

export default function RouteDetail() {
  const { id } = useParams<{ id: string }>();
  const ref = useRef<SheetRef>();
  const routes = getRoutes();
  const navigate = useNavigate();
  const userLocationPermission = useUpdateUserLocationContext();
  const [selectedRouteData, setSelectedRouteData] = useState<Route | null>(
    null
  );
  const [status, setStatus] = useState<"loading" | "loaded">("loading");
  const [countdownCompleted, setCountdownCompleted] = useState(false);

  const selectedRoute = routes.find((route) => route.urlKey === id);

  useEffect(() => {
    if (selectedRoute) {
      setSelectedRouteData(selectedRoute);
      setStatus("loaded");
    }
  }, [selectedRoute]);

  const handleCountdownComplete = () => {
    setCountdownCompleted(true);
  };

  return (
    <PageContainer title="" loading={status === "loading"} container={false}>
      {selectedRoute && (
        <>
          <CardMedia
            sx={{
              width: "100%",
              height: "auto",
              minHeight: "100%",
              objectFit: "contain",
              marginTop: "1rem"
            }}
            component="img"
            image={selectedRoute.icon}
            alt="Ruta"
          />
          <Box
            sx={{
              zIndex: "100",
              /*  position: "absolute", */
              /*   top: "15%", */
              marginLeft: "0"
            }}
          >
            <Link to="/" style={{ textDecoration: "none", color: "black" }}>
              <FloatingBackButton />
            </Link>
          </Box>
          <StyledContainer>
            <StyledCard>
              <Content>
                {/* 
                <RatingContainer>
                  <Rating name="read-only" value={4} readOnly />
                  <Typography variant="body2" color="text.secondary">
                    000 valoraciones
                  </Typography>
                </RatingContainer>
                */}
                {/* <Title>Descubre Rancagua con Tito Lastarria</Title> */}
                <TextContainer>
                  <FadeInParagraph className="fade-in-text">
                    {selectedRoute.description}
                  </FadeInParagraph>
                </TextContainer>
                {/* 
                <IconsContainer>
                  <Box display="flex" alignItems="center" mr={2} sx={{marginTop:'19px', marginBottom:'18px'}}>
                    <FavoriteBorder />
                    <Typography variant="body2">Descripción icono</Typography>
                  </Box>
                  <Box display="flex" alignItems="center" sx={{marginBottom:'24px'}}>
                    <FavoriteBorder />
                    <Typography variant="body2">Descripción icono</Typography>
                  </Box>
                </IconsContainer>
                */}
                {!countdownCompleted && (
                  <EventCountdown
                    endDate="23-05-2024 :00"
                    onComplete={handleCountdownComplete}
                  />
                )}
                <BottomBar>
                  <Button
                    variant="contained"
                    sx={{
                      /* marginLeft: "auto", */
                      /*  fontSize: "16px", */
                      fontWeight: "bold",
                      backgroundColor: "#F7D765",
                      /*  marginTop: "8px",
                      marginBottom: "16px", */
                      width: "70%",
                      border: "none",
                      boxShadow: "none",
                      borderRadius: "30px",
                      color: "#000"
                      /*    position: "fixed" */
                    }}
                    href="/rutas/con-tito-lastarria-24/map"
                    disabled={!countdownCompleted}
                    onClick={(e) => {
                      AnalyticsController.trackButtonClicked("start_route");
                    }}
                  >
                    Iniciar ruta
                  </Button>
                  <Description style={{ textAlign: "right", color: "black" }}>
                    Al iniciar, aceptas{" "}
                    <a
                      href="https://firebasestorage.googleapis.com/v0/b/city-tour-84058.appspot.com/o/Poli%CC%81ticas_Descubre_Rancagua_2024.pdf?alt=media&token=de6eb0fd-d83a-46a7-9a21-fffafff34da7"
                      target="_blank"
                      rel="noreferrer"
                    >
                      los términos y condiciones{" "}
                    </a>{" "}
                    del sitio descubrerancagua.cl.
                  </Description>
                </BottomBar>
              </Content>
            </StyledCard>
          </StyledContainer>
        </>
      )}
    </PageContainer>
  );
}

const TextContainer = styled.div`
  /*   width: 100%; */
  margin: 0 16px;
  padding: 0;
  font-size: 1rem;

  padding-bottom: 100px;
  /*   line-height: 1.5; */
`;

const FadeInParagraph = styled.p`
  animation: fadeIn 5s ease-in-out forwards;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const TypingEffectParagraph = styled.p`
  overflow: hidden; /* Ensures the text doesn't overflow */
  white-space: nowrap; /* Prevents text from wrapping */
  display: inline-block;
  border-right: 0.15em solid orange; /* Creates the cursor effect */
  animation: blink-caret 0.75s step-end infinite; /* Cursor blink animation */
`;

const BottomBar = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 120px;
  background: #f4f4f4;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
