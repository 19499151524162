import React, { useCallback, useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { useNavigate, useParams } from "react-router-dom";
import { doc, setDoc } from "firebase/firestore";

import { useFirestore } from "reactfire";

// Hooks
import { usePollChecker } from "hooks/usePollChecker";

// Providers
import { getParticipantByParams, Participant } from "providers/Participant";
import { useUserContext } from "providers/User";

// Components
import VoteCard from "components/VoteCard";
import AlertMessage from "components/AlertMessage";
import Loading from "components/Loading";
import { saveVoteMS } from "providers/Poll";

const DEFAULT_EVENT = "CARROS2023";
const POLL_VOTES_COLLECTION = "poll_votes";

export default function VotingCheck() {
  /*   const { executeRecaptcha } = useGoogleReCaptcha();
    const [token, setToken] = useState("");

    let captcha: string; */
  const user = useUserContext();
  const navigate = useNavigate();
  const firestore = useFirestore();
  const { urlKey } = useParams();

  const [selectedParticipant, setSelectedParticipant] =
    useState<Participant | null>(null);

  const { settings, loading, hasVoted, locationChecked, qrValue, error } =
    usePollChecker();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (hasVoted) {
      navigate(`/voting-view/${hasVoted}`);
    }
  }, [hasVoted]);

  useEffect(() => {
    const fetchParticipantInfo = async () => {
      try {
        const participant = await getParticipantByParams({ urlKey });
        setSelectedParticipant(participant);
      } catch (error) {
        console.error("Error fetching participant info:", error);
      }
    };

    fetchParticipantInfo();
  }, [urlKey]);

  const onConfirmVote = async () => {
    try {
      // TODO: save vote
      if (user && user.uid && selectedParticipant) {
        setIsLoading(true);
        const VOTE_KEY = `${user.uid}_${DEFAULT_EVENT}`;

        // Save in MS
        const msVote = {
          uid: user.uid,
          qr: qrValue,
          key: VOTE_KEY,
          location: locationChecked,
          option: selectedParticipant.urlKey, // TODO: add validation & sanitize
          event: DEFAULT_EVENT,
          origin: "web" // TODO: get from params
        };

        const { success } = await saveVoteMS(msVote);

        if (success) {
          const cityRef = doc(firestore, POLL_VOTES_COLLECTION, VOTE_KEY);
          const fsVote = { ...msVote, createdAt: new Date() };
          setDoc(cityRef, { ...fsVote }, { merge: true });
        }
      }
      setIsLoading(false);
      // TODO: redirect to voting view
      // navigate(`/voting-view/${urlKey}`)
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };
  if (loading || isLoading) {
    return <Loading />;
  }
  return (
    <>
      <Stack
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1000,
          backgroundColor: "white"
        }}
      >
        <div
          style={{
            backgroundColor: "#EA5A16",
            padding: "0.2rem"
          }}
        ></div>
        <Container>
          <AlertMessage open={error !== ""} severity="error" message={error} />
          <Typography
            sx={{
              marginTop: "21px",
              fontWeight: 500,
              fontStyle: "normal",
              lineHeight: "14.5px",
              fontSize: "14px",
              marginBottom: "11px"
            }}
          >
            PASO 2 de 2
          </Typography>
        </Container>
      </Stack>
      <Container>
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: "30px",
            marginBottom: "21px"
          }}
        >
          Confirmar Votación
        </Typography>
        {selectedParticipant && (
          <VoteCard data={selectedParticipant} small={false} />
        )}
        <Stack
          direction="row"
          sx={{
            justifyContent: "space-between",
            marginTop: "31px",
            marginBottom: "0.5rem"
          }}
          spacing={2}
        >
          <Button
            variant="text"
            sx={{
              backgroundColor: "white",
              width: "100%",
              color: "black",
              fontWeight: "bold",
              textTransform: "none",
              fontSize: "16px",
              fontFamily: "Poppins"
            }}
            onClick={() => navigate("/voting")}
          >
            Volver atrás
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#131226",
              width: "100%",
              color: "white",
              fontWeight: "bold",
              textTransform: "none",
              fontSize: "16px",
              fontFamily: "Poppins",
              borderRadius: "0px",
              "&:hover": {
                backgroundColor: "#131226"
              },
              pointerEvents:
                loading || (settings && !settings.enableVote) ? "none" : "auto"
            }}
            onClick={onConfirmVote}
          >
            {settings && settings.enableVote
              ? "Votar"
              : "Ya ha finalizado la votación"}
          </Button>
        </Stack>
      </Container>
    </>
  );
}
