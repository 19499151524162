import styled from "@emotion/styled";

export const StyledFooter = styled.footer`
  background-color: black;
  color: #fff;
  padding: 16px;
  text-align: left;
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
