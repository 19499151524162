import styled from "@emotion/styled";

const BoxList = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 16px;
  padding-top: 0px;
  overflow: auto;
`;

export default BoxList;
