import React from "react";
import { useNavigate } from "react-router-dom";

//MUI
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";

interface SavedDialogProps {
  place?: any;
  isOpen: boolean;
  showCloseIcon?: boolean;
  onClose: () => void;
}

export default function SavedDialog({
  place,
  isOpen,
  onClose,
  showCloseIcon = true,
}: SavedDialogProps) {
  const navigate = useNavigate();

  const handleClose = () => {
    onClose();
  };

  const handlePassaport = () => {
    navigate(`/mi-pasaporte`);
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={onClose}
        fullWidth={true}
        maxWidth={"xl"}
        sx={{ zIndex: 9999 }}
      >
        <DialogTitle
          id="responsive-dialog-title"
          style={{ fontWeight: "bold", color: "#313E5C", padding: "2rem" }}
        >
          {"¡Ya visitaste este lugar!"}
        </DialogTitle>
        <DialogContent sx={{ padding: "2rem" }}>
          <DialogContentText>
            Puedes visualizar la estampilla en tu pasaporte.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: "1rem" }}>
          <Button
            variant="outlined"
            color="info"
            autoFocus
            onClick={handleClose}
            style={{ borderRadius: "15px" }}
          >
            Cerrar
          </Button>
          <Button
            variant="contained"
            color="info"
            autoFocus
            onClick={handlePassaport}
            style={{ borderRadius: "15px" }}
          >
            Mi pasaporte
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
