import styled from "@emotion/styled";

/*export const ShareDialogDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
*/

export const IconMenu = styled.div`
  height: 12vh;
  text-align: left;
  padding: 0.5rem;
  background-color: transparent;
  position: relative;
  z-index: 9999;
`;

export const Icon = styled.img`
  width: auto;
  max-height: 6vh;
`;
