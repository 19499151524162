import React, { useState } from "react";
import { BannerImg, BannerContainer } from "./styles";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CloseIcon from "@mui/icons-material/Close";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import AnalyticsController from "providers/Analytics";


export default function WelcomeBanner() {
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const location = useLocation();


  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const handleItemClick = () => {
    handleDrawerClose();
  };

  const bannerImgSrc = location.pathname === '/'
    ? "/assets/DescubreRancagua_negativo.svg"
    : "/assets/Descubre-Rancagua-negativo.svg"; 

  const menuIconColor = location.pathname === '/' ? 'white' : '#505050';
  const backgroundColor = location.pathname === '/' ? '#e3528b' : 'white';

  return (
  <div style={{
        backgroundColor: backgroundColor, 
        width: '100vw', 
        margin: 0, 
        padding: 0, 
        position: 'fixed', 
        top: 0, 
        left: 0, 
        zIndex: 1000 
      }}>      
      <BannerContainer>
        <Link to="/" style={{textDecoration:'none', color:'black'}} 
        onClick={() => {
          AnalyticsController.trackButtonClicked("banner_click");
        }}>
          <BannerImg src={bannerImgSrc} alt="Banner Image" />
        </Link>
        <div style={{ position: "absolute", top: "50%", right: "0rem", transform: "translateY(-50%)" }}>
          {/* 
          <IconButton size="large">
            <FavoriteBorderIcon sx={{ color: "white", fontSize: '2rem' }} />
          </IconButton>
          */}
          <IconButton size="large" onClick={() => {
            AnalyticsController.trackButtonClicked("menu_click");
            handleDrawerOpen();
          }}>
            <MenuIcon sx={{ color: menuIconColor, fontSize: '2rem' }} />
          </IconButton>
        </div>
        <Drawer
          anchor="right"
          open={openDrawer}
          onClose={handleDrawerClose}
          PaperProps={{
            sx: { width: '60%', zIndex: 1300, position: 'absolute', top: '0', height: '100%' }
          }}
        >
          <ClickAwayListener onClickAway={handleDrawerClose}>
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative'
              }}
              role="presentation"
            >
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                <IconButton onClick={handleDrawerClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <List sx={{ flexGrow: 1 }}>
                <ListItem>
                  <ListItemText>
                    <Link to="/" style={{textDecoration:'none', color:'black'}}>
                      <Typography onClick={handleItemClick} sx={{fontWeight:'bold', fontSize:'24px'}}>Inicio</Typography>
                    </Link>
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    <Link to="/actividades" style={{textDecoration:'none', color:'black'}} onClick={() => {
                        AnalyticsController.trackButtonClicked("actividades_click_menu");
                        handleDrawerClose();
                      }}>
                    <Typography  sx={{fontWeight:'bold', fontSize:'24px'}}>Actividades</Typography>
                    <Typography sx={{fontWeight:'bold', fontSize:'14px'}}>Día de los Patrimonios 2024</Typography>
                    </Link>
                  </ListItemText>
                </ListItem>
                <ListItem>
                <ListItemText>
                  <Link to="/rutas/con-tito-lastarria-24" style={{textDecoration:'none', color:'black'}} onClick={() => {
                        AnalyticsController.trackButtonClicked("rutas_click_menu");
                        handleDrawerClose();
                      }}>
                      <Typography sx={{fontWeight:'bold', fontSize:'24px'}}>Rutas</Typography>
                      <Typography sx={{fontWeight:'bold', fontSize:'14px'}}>Descubre Rancagua con Tito Lastarria</Typography>
                    </Link>
                  </ListItemText>
                </ListItem>
                <Box sx={{ marginTop: '100%' }}>
                  <ListItem>
                    <ListItemText>
                      <a href="mailto:soporte@innovarancagua.cl" style={{ textDecoration: 'none', color: 'black' }} 
                        onClick={() => {
                          AnalyticsController.trackButtonClicked("click_ayuda");}}
                        >
                        <Typography sx={{ fontWeight: 'bold', fontSize: '18px' }}>Ayuda</Typography>
                      </a>
                    </ListItemText>
                  </ListItem>
                </Box>
              </List>
            </Box>
          </ClickAwayListener>
        </Drawer>
      </BannerContainer>
    </div>
  );
}