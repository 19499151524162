import styled from "@emotion/styled";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";

interface ImageGalleryImageProps {
  imageUrl: string;
}

export const ImageGalleryImage = styled.div<ImageGalleryImageProps>`
  background: url(${(props) => props.imageUrl});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 200px;
  object-fit: contain;
`;

export const PlaceStack = styled(Stack)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Heading = styled.h2`
  padding: 0;
  margin: auto;
  font-size: 1.2rem;
`;

export const BoxDetail = styled(Box)`
  display: flex;
  justify-content: left;
  align-items: center;
  color: black;
`;

export const ContentStack = styled(Stack)`
  flex-direction: column;
  padding: 1rem 0;
`;

export const SaveButton = styled(Button)`
  border-radius: 30px;
  font-weight: bold;
  padding: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #000000;
  background: #f7d765;
`;

export const DialogButton = styled(Button)`
  border-radius: 15px;
`;

export const TitleDialog = styled(DialogTitle)`
  font-weight: bold;
  color: #313e5c;
  padding: 1rem 2rem;
`;
