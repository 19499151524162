import React, { useState, Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

// Providers
import {
  useUserLocationContext,
  useUpdateUserLocationContext
} from "providers/UserLocation";

// Hooks
import { usePollChecker } from "hooks/usePollChecker";

// Components
import EventCountdown from "components/EventCountdown";
import Loading from "components/Loading";
import AlertMessage from "components/AlertMessage";

// Styles
import { BannerWrapper } from "./styles";
import CircularProgress from "@mui/material/CircularProgress";

export default function WelcomeLocation() {
  const navigate = useNavigate();
  const { error, loading, hasVoted, qrChecked, authenticated, settings } =
    usePollChecker();
  const [locationPermission, setLocationPermission] = useState(false);
  const userLocation = useUserLocationContext();
  const userLocationPermission = useUpdateUserLocationContext();
  /*   const activeCountdown = false; */
  useEffect(() => {
    if (hasVoted) {
      navigate(`/voting-view/${hasVoted}`);
    } else if (authenticated && qrChecked) {
      navigate("/voting");
    }
  }, [hasVoted, authenticated, qrChecked]);

  const handleActivateLocation = () => {
    if (!userLocation.enabled) {
      userLocationPermission(true);
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setLocationPermission(true);
            navigate("/location-check");
          },
          (error) => {
            console.error("Error al obtener la ubicación:", error);
          }
        );
      } else {
        console.error("El navegador no soporta geolocalización.");
      }
    } else {
      navigate("/location-check");
    }
  };
  if (loading) {
    return <Loading />;
  }
  return (
    <>
      <BannerWrapper
        style={{
          background:
            'url("/assets/welcome-vote-banner.png") no-repeat center top'
        }}
      ></BannerWrapper>

      <Container>
        <AlertMessage open={error !== ""} severity="error" message={error} />
        <Typography
          variant="h4"
          sx={{
            fontWeight: "bold",
            marginTop: "1rem",
            marginBottom: "1rem",
            lineHeight: 1
          }}
        >
          Vota por tu Carro Alegórico Favorito
        </Typography>

        {settings && settings.enableVote ? (
          <Fragment>
            <Typography
              variant="body1"
              sx={{
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "14.5px",
                fontFamily: "Poppins"
              }}
            >
              Para votar por tu Carro Alegórico favorito debes estar ubicado
              dentro del recorrido o escanear los QR presentes en el lugar
            </Typography>
            <Grid
              container
              spacing={4}
              sx={{
                /*  paddingX: "16px", */
                paddingBottom: "50px",
                paddingTop: "27px"
              }}
            >
              <Grid item>
                <Button
                  variant="text"
                  size="large"
                  sx={{ textTransform: "none" }}
                  onClick={() => navigate("/qr-check")}
                >
                  <Typography
                    variant="h2"
                    gutterBottom
                    sx={{ color: "black", fontSize: "16px", fontWeight: "700" }}
                  >
                    Escanear QR
                  </Typography>
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    background: "black",
                    textTransform: "none",
                    borderRadius: "0px"
                  }}
                  onClick={handleActivateLocation}
                >
                  <Typography
                    variant="h2"
                    sx={{
                      color: "#FFF",
                      fontSize: "16px",
                      fontWeight: "700",
                      marginX: "-13px"
                    }}
                  >
                    {locationPermission
                      ? "Ubicación lista"
                      : "Activar ubicación"}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Fragment>
        ) : (
          <Fragment>
            <Typography
              variant="body1"
              sx={{
                marginTop: "1rem",
                lineHeight: 1.2
              }}
            >
              El evento comenzará en:
            </Typography>
            {/*
            <EventCountdown endDate={"18-11-2023 19:00"} />
            */}
          </Fragment>
        )}
      </Container>
    </>
  );
}
