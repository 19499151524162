import React, { useEffect, useState } from "react";
import { useLocalStorage } from "usehooks-ts";
import { useFirestoreCollectionData, useFirestore } from "reactfire";
import { collection, orderBy, query, where } from "firebase/firestore";
import { getDistance } from "geolib";

// Providers
import { Location, Coordinates } from "providers/Location";
import { useUserLocationContext } from "providers/UserLocation";

export const PLACES_COLLECTION = "interestPlaces";
const PLACES_KEY = "p1@c35";

export enum FetchStatus {
  LOADING = "loading",
  SUCCESS = "success",
  ERROR = "error",
  DISTANCE_UPDATED = "distance_updated"
}

export interface Stamp {
  title: string;
  url: string;
  lockUrl?: string;
  lockTitle?: string;
}

export interface Place {
  id: string;
  name: string;
  icon?: string;
  image?: any[];
  coverImage?: any;
  description?: string;
  summary?: string;
  location?: Location;
  urlKey?: string;
  links?: string[];
  distance?: number;
  visited?: boolean;
  stamps?: Stamp;
  route: string;
  savedAt?: Date;
  fromQr?: boolean;
  video?: {
    id?: string;
    url?: string;
  };
}

export function usePlacesData() {
  const [places, setPlaces] = useLocalStorage<Place[]>(PLACES_KEY, []);
  const userLocation = useUserLocationContext();
  const [distanceUpdatedPlaces, setDistanceUpdatedPlaces] =
    useState<Place[]>(places);

  // load data from firestore
  const firestore = useFirestore();
  const interestPlacesCollection = collection(firestore, PLACES_COLLECTION);
  const interestPlacesQry = query(
    interestPlacesCollection,
    orderBy("name", "asc")
  );
  const { status, data: interestPlaces } = useFirestoreCollectionData(
    interestPlacesQry,
    {
      idField: "id" // this field will be added to the object created from each document
    }
  );

  const [fetchStatus, setFetchStatus] = useState<FetchStatus>(
    status === FetchStatus.LOADING && places.length > 0
      ? FetchStatus.SUCCESS // if places are already loaded from local storage, then status is success
      : status === FetchStatus.ERROR
      ? FetchStatus.ERROR
      : FetchStatus.LOADING
  );

  useEffect(() => {
    if (userLocation?.enabled && userLocation?.coordinates) {
      const newPlaces = places.map((place: Place) => {
        try {
          const distance = distanceBetween(
            userLocation?.coordinates || null,
            place?.location?.coordinates || null
          );
          return { ...place, distance };
        } catch (error) {
          return place;
        }
      });
      setFetchStatus(FetchStatus.DISTANCE_UPDATED);
      const sortedPlaces = newPlaces.sort((a, b) => a.distance! - b.distance!);
      setDistanceUpdatedPlaces(sortedPlaces);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLocation]);

  useEffect(() => {
    if (status === FetchStatus.SUCCESS) {
      // if places are not loaded from local storage, then load from firestore
      const list: Place[] = parseFbPlacesToPlaces(interestPlaces);
      setPlaces(list);
      setFetchStatus(FetchStatus.SUCCESS);
    } else if (status === FetchStatus.ERROR && places.length === 0) {
      setFetchStatus(FetchStatus.ERROR);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, interestPlaces]);

  const list =
    fetchStatus === FetchStatus.DISTANCE_UPDATED
      ? distanceUpdatedPlaces
      : places;

  return [list, fetchStatus] as const;
}

export function distanceBetween(
  pointA: Coordinates | null,
  pointB: Coordinates | null
): number {
  const distance = pointA && pointB ? getDistance(pointA, pointB) : -1;

  return distance;
}

function parseFbPlacesToPlaces(fbp: any): Place[] {
  const places: Place[] = [];
  if (fbp)
    fbp.map((fbPlace: any) => {
      try {
        const {
          id,
          image,
          coverImage,
          icon,
          name,
          description,
          summary,
          route,
          address,
          urlKey,
          links,
          stamps,
          video
        } = fbPlace;
        const { city, latitude, longitude, streetName, streetNumber } = address;

        // Parse to Web Interface format
        const place: Place = {
          id,
          name,
          icon: icon.url,
          image: image,
          coverImage,
          description,
          summary,
          urlKey,
          links,
          route,
          stamps,
          location: {
            address: {
              city,
              streetName,
              streetNumber
            },
            coordinates: {
              lat: latitude,
              lng: longitude
            }
          },
          video
        };

        places.push(place);
        return place;
      } catch (error) {
        return null;
      }
    });

  return places;
}
