import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

// Hooks
import { usePollChecker } from "hooks/usePollChecker";

// Providers
import { getParticipant, Participant } from "providers/Participant";

// Components
import PlaceCard from "components/PlaceCard";
import ParticipantListCard from "components/ParticipantListCard";
import Stack from "@mui/material/Stack";
import Loading from "components/Loading";
import AlertMessage from "components/AlertMessage";

export default function Voting() {
  const [participants, setParticipants] = useState<Participant[]>([]);
  const [selectedParticipant, setSelectedParticipant] =
    useState<Participant | null>(null); // Nuevo estado
  const navigate = useNavigate();
  const {
    settings,
    loading,
    hasVoted,
    locationChecked,
    qrChecked,
    authenticated,
    error
  } = usePollChecker();

  useEffect(() => {
    const enabledToVote = locationChecked || qrChecked;
    if (hasVoted) {
      navigate(`/voting-view/${hasVoted}`);
    } else if (!authenticated || !enabledToVote) {
      navigate("/votacion/carros2023");
    }
  }, [hasVoted, authenticated, locationChecked, qrChecked]);

  useEffect(() => {
    const fetchParticipants = async () => {
      try {
        const participantList = await getParticipant();
        setParticipants(participantList);
      } catch (error) {
        console.error("Error fetching participants:", error);
      }
    };

    fetchParticipants();
  }, []);

  const handleParticipantSelect = (participant: Participant) => {
    if (selectedParticipant && selectedParticipant.id === participant.id) {
      setSelectedParticipant(null);
    } else {
      setSelectedParticipant(participant);
    }
  };
  if (loading) {
    return <Loading />;
  }
  return (
    <>
      <Stack
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1000,
          backgroundColor: "white"
        }}
      >
        <div style={{ display: "flex" }}>
          <div
            style={{
              backgroundColor: "#EA5A16",
              padding: "0.2rem",
              width: "50%"
            }}
          ></div>
          <div
            style={{
              backgroundColor: "#131226",
              padding: "0.2rem",
              width: "50%"
            }}
          ></div>
        </div>
        <Container>
          <AlertMessage open={error !== ""} severity="error" message={error} />
          <Typography
            sx={{
              marginTop: "21px",
              fontWeight: 500,
              fontStyle: "normal",
              lineHeight: "14.5px",
              fontSize: "14px",
              marginBottom: "11px"
            }}
          >
            PASO 1 de 2
          </Typography>
        </Container>
      </Stack>
      <Container
        sx={{
          position: "relative"
        }}
      >
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: "30px"
          }}
        >
          ¿Cuál es tu Carro Favorito?
        </Typography>
        <Typography
          sx={{
            marginTop: "31px",
            marginBottom: "26px",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "14.5px"
          }}
        >
          Selecciona tu carro favorito del listado y vota
        </Typography>
        {participants.map((participant) => (
          <ParticipantListCard
            key={participant.id}
            participant={participant}
            isSelected={selectedParticipant?.id === participant.id}
            onSelect={handleParticipantSelect}
          />
        ))}
        <div
          style={{
            position: "fixed",
            bottom: "20px",
            left: "50%",
            width: "91.5%",
            transform: "translateX(-50%)",
            zIndex: 1000
          }}
        >
          {selectedParticipant && (
            <div style={{ position: "relative", width: "100%" }}>
              <div
                style={{
                  position: "absolute",
                  top: "-10px",
                  left: "-6px",
                  right: "-6px",
                  bottom: "-20px",
                  backgroundColor: "white",
                  zIndex: -1
                }}
              />
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#131226",
                  borderRadius: "0px",
                  textTransform: "none",
                  fontSize: "16px",
                  fontWeight: "700",
                  fontFamily: "Poppins",
                  width: "100%",
                  "&:hover": {
                    backgroundColor: "#131226"
                  },
                  pointerEvents:
                    loading || (settings && !settings.enableVote)
                      ? "none"
                      : "auto"
                }}
                onClick={() => {
                  if (selectedParticipant) {
                    navigate(`/voting-check/${selectedParticipant.urlKey}`);
                  } else {
                    alert(
                      "Por favor, selecciona un participante antes de continuar"
                    );
                  }
                }}
              >
                {settings && settings.enableVote
                  ? "Siguiente"
                  : "Ya ha finalizado la votación"}
              </Button>
            </div>
          )}
        </div>
      </Container>
    </>
  );
}
