// Based on https://github.com/JustFly1984/react-google-maps-api/tree/master/packages/react-google-maps-api
// TODO: https://www.youtube.com/watch?v=8kxYqoY2WwE&ab_channel=GoogleMapsPlatform
import React from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";

/* import { Place } from "providers/Places"; */

import Map, {
  GOOGLE_MAPS_API_KEY,
  DEFAULT_ZOOM,
  /*   WALK_ZOOM, */
  DEFAULT_CENTER_POSITION,
  Position,
} from "components/Map";

import Loading from "components/Loading";

import { MapWrapperContainer } from "./styles";

export const DEFAULT_CENTER = DEFAULT_CENTER_POSITION;
export interface CityMapPoint {
  content?: any;
  position: Position;
  onClick?: any;
}

interface CityMapComponentProps {
  points?: CityMapPoint[];
  center?: Position;
  zoom?: number;
  onInit?: any;
}

export default function CityMapComponent({
  points = [],
  center = DEFAULT_CENTER_POSITION,
  zoom = DEFAULT_ZOOM,
  onInit = (map: any) => {},
}: CityMapComponentProps) {
  return (
    <MapWrapperContainer>
      <Wrapper
        apiKey={GOOGLE_MAPS_API_KEY.key}
        version="beta"
        libraries={["marker"]}
        render={(status: Status) => {
          switch (status) {
            case Status.LOADING:
              return <Loading />;
            case Status.FAILURE:
              return <div className="error" />;
            case Status.SUCCESS:
              return (
                <Map
                  points={points}
                  center={center}
                  zoom={zoom}
                  onInit={onInit}
                />
              );
          }
        }}
      ></Wrapper>
    </MapWrapperContainer>
  );
}
