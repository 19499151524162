import React, { useState } from "react";
import styled from "@emotion/styled";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Sheet } from "react-modal-sheet";
import ActivityDetail from "components/ActivityDetail";
import { useNavigate, useParams, Link } from "react-router-dom";
import PageContainer from "ui/PageContainer";
import BoxList from "ui/BoxList";
import DateFormat from "components/DateFormat";
import { text } from "stream/consumers";
import { EVENT } from "providers/EventRoute";
import { Activity } from "providers/Activities";

const StyledContainer = styled.div`
  margin-bottom: 1rem;

  position: relative;
`;

const ArrowIconContainer = styled.div`
  position: absolute;
  bottom: 10px;
  right: 0;
`;

const ActivityCardPlaceList = ({
  activities = []
}: {
  activities?: Activity[];
}) => {
  const navigate = useNavigate();
  const handleActivityClick = (activity: Activity) => {
    navigate("/actividad/" + activity.urlKey);
    /* setSelectedActivity(activity); */
  };

  const onCloseSheet = () => {
    /* setSelectedActivity(null); */
  };

  return (
    <>
      {activities.map((activity: Activity) => (
        <StyledContainer
          key={activity.id}
          onClick={() => handleActivityClick(activity)}
        >
          <Stack direction="row" alignItems="center">
            <Stack direction="column" marginLeft="0" width="100%">
              <DateFormat date={activity} />
              <img
                alt={activity.title}
                src={activity.icon.url}
                style={{ width: "109px", height: "86px" }}
              />
            </Stack>
            <Stack direction="column" marginLeft="10px" width="100%">
              <Typography
                variant="subtitle1"
                textAlign="left"
                sx={{
                  fontWeight: "bold",
                  fontSize: "0.9rem",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  width: "200px" // Ajusta el ancho según tus necesidades
                }}
              >
                {activity.title}
              </Typography>

              <Typography variant="body2" textAlign="left">
                De {activity.time} a {activity.endTime}hrs.
              </Typography>
            </Stack>
          </Stack>
          {/* <ArrowIconContainer>
            <IconButton
              onClick={() => {
               
              }}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </ArrowIconContainer> */}
          <Divider style={{ marginTop: "16px" }} variant="middle" />
        </StyledContainer>
      ))}
    </>
  );
};

export default ActivityCardPlaceList;
