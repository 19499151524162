import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
//MUI
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Backdrop from "@mui/material/Backdrop";

import ActionButton from "ui/ActionButton";

//PROVIDERS
import { useUpdateUserLocationContext } from "providers/UserLocation";
import "./styles.scss";
interface CheckCardProps {
  place?: any;
  route?: any;
  isOpen: boolean;
  showCloseIcon?: boolean;
  onClose: () => void;
}

export default function CheckCard({
  place,
  isOpen,
  onClose,
  showCloseIcon = true
}: CheckCardProps) {
  /* const classes = useStyles(); */
  const navigate = useNavigate();
  const userLocationPermission = useUpdateUserLocationContext();
  let [searchParams, setSearchParams] = useSearchParams();
  const handlePlace = () => {
    userLocationPermission(true);
    if (window.location.href.includes(`/?place=`)) {
      onClose();
    } else {
      navigate(`/rutas/con-tito-lastarria-24/map?place=${place?.urlKey}`);
    }
    onClose();
  };

  const handleQr = () => {
    //setSearchParams({ menu: "scanner" });
    navigate(`/scan`);
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={onClose}
        fullWidth={true}
        maxWidth={"xl"}
        BackdropComponent={Backdrop}
        sx={{
          zIndex: 9998,
          minHeight: "100vh",
          backgroundColor: "transparent"
        }}
      >
        <DialogTitle
          id="responsive-dialog-title"
          style={{
            fontWeight: "bold",
            /* color: "white", */
            padding: "2rem"
          }}
        >
          {"¡Estás muy lejos!"}
        </DialogTitle>
        {/* <img
          src="/assets/img/tito-1.png"
          alt="Tito"
          style={{
            width: "100%",
            zIndex: 9999,
            left: 0
          }}
        /> */}
        <DialogContent sx={{ padding: "2rem" }}>
          <DialogContentText
            style={
              {
                /* color: "white" */
              }
            }
          >
            Debes estar más cerca para guardar tu visita, o escanear el QR
            disponible en el lugar.
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            padding: "1rem",
            align: "flex",
            flexDirection: "row",
            justifyContent: "center"
          }}
        >
          <Button
            variant="outlined"
            color="info"
            onClick={() => {
              onClose();
            }}
            style={{
              borderRadius: "15px",
              color: "black",
              background: "white",
              height: "50px"
            }}
          >
            {"Volver"}
          </Button>
          <ActionButton
            variant="contained"
            color="info"
            autoFocus
            onClick={handleQr}
            style={{ borderRadius: "15px", height: "50px" }}
          >
            Escanear Qr
          </ActionButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
