import React, { useState } from "react";
import { useLocalStorage } from "usehooks-ts";

//  MUI
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Typography from "@mui/material/Typography";

// STYLES
import { RaffleDiv } from "./styles";

const RafflePage: React.FC = () => {
  const [selectedNumber, setSelectedNumber] = useState(0);
  const [recentNumbers, setRecentNumbers] = useState<number[]>([]);
  const [winnerNumbers, setWinnerNumbers] = useLocalStorage<number[]>(
    "winnerNumbers",
    []
  );

  const [isLoading, setIsLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const generateRandomNumber = () => {
    setIsLoading(true);
    setTimeout(() => {
      const newNumber = Math.floor(Math.random() * 564) + 1;
      setSelectedNumber(newNumber);
      setIsDialogOpen(true);
      setIsLoading(false);
    }, 3000);
  };

  const handleConfirmNumber = () => {
    setWinnerNumbers([...winnerNumbers, selectedNumber]);
    setRecentNumbers((prevNumbers) =>
      [selectedNumber, ...prevNumbers].slice(0, 5)
    );
    setIsDialogOpen(false);
  };

  const handleCancelNumber = () => {
    setSelectedNumber(0);
    setIsDialogOpen(false);
  };

  const cleanRecentNumbers = () => {
    setRecentNumbers([]);
  };

  return (
    <>
      <Container>
        <RaffleDiv>
          <Typography variant="h4" fontWeight="bold" marginY="2vh">
            ¡Gran Sorteo Descubre Rancagua Antiguo 2023!
          </Typography>
        </RaffleDiv>
        <RaffleDiv>
          <Button
            variant="contained"
            color="warning"
            onClick={generateRandomNumber}
            disabled={isLoading}
          >
            {isLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Seleccionar número ganador"
            )}
          </Button>
        </RaffleDiv>
        <Typography variant="h6" fontWeight="bold">
          Números Ganadores
        </Typography>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Número Ganador</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {winnerNumbers.map((number, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {index + 1}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {number}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
      <Dialog open={isDialogOpen} onClose={handleCancelNumber}>
        <DialogTitle>Felicidades al Número: </DialogTitle>
        <DialogContent sx={{ textAlign: "center" }}>
          <DialogContentText>
            <Typography variant="h4" fontWeight="bold">
              {selectedNumber}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-around" }}>
          <Button onClick={handleCancelNumber} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={handleConfirmNumber}
            color="primary"
            variant="contained"
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RafflePage;
