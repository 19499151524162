export interface Event {
  banner: string;
  event: string;
  layout: string;
  places_fields?: string[];
  requireLocation: boolean;
}

export const EVENT = {
  banner:
    "https://firebasestorage.googleapis.com/v0/b/city-tour-84058.appspot.com/o/test%2Flogo.svg?alt=media&token=c8b0c1b0-d4d7-4f34-8362-7fc1f9b007ec",
  event: "2024CONTITO",
  layout: "Pasaporte",
  places_fields: ["title", "description", "button"],
  requireLocation: true
};

const EVENT_LIST: Event[] = [EVENT];

export function getEvents(): Promise<Event[]> {
  try {
    return Promise.resolve(EVENT_LIST);
  } catch (error) {
    return Promise.reject(error);
  }
}

export function getEventById(id: string): Promise<Event | undefined> {
  try {
    const event = EVENT_LIST.find((event) => event.event === id);
    return Promise.resolve(event);
  } catch (error) {
    return Promise.reject(error);
  }
}
