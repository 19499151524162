import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import styled from "@emotion/styled";
import { useAuth, useSigninCheck } from "reactfire";
import {
  GoogleAuthProvider,
  signInWithPopup,
  FacebookAuthProvider
} from "firebase/auth";
import FacebookIcon from "@mui/icons-material/Facebook";
import GoogleIcon from "@mui/icons-material/Google";
// Providers
import { useUserContext, useUpdateUserContext } from "providers/User";

// Components
import UserAvatar from "components/UserAvatar";

// UI
import PageContainer from "ui/PageContainer";
import AnalyticsController from "providers/Analytics";
import Description from "ui/Description";
import Subtitle from "ui/Subtitle";

const signIn = async (auth: any, provider: string) => {
  // TODO: add switch case for other providers
  let p = null;
  switch (provider) {
    case "google":
      p = new GoogleAuthProvider();
      break;

    case "facebook":
      p = new FacebookAuthProvider();
      break;
  }
  try {
    if (p) {
      const result = await signInWithPopup(auth, p);
    }
  } catch (error) {}

  // TODO: add callback to redirect to previous page
};

export default function ProfilePage() {
  const [searchParams] = useSearchParams();
  const [rel, setRel] = useState<string | null>(searchParams.get("rel"));
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useUserContext();

  const setUser = useUpdateUserContext();
  const { status } = useSigninCheck();

  useEffect(() => {
    const newRel = searchParams.get("rel");
    const qrRel = searchParams.get("qr") || "";
    if (newRel) setRel(newRel.concat(`&qr=${qrRel}`));
  }, [searchParams]);
  useEffect(() => {
    if (
      /*       (status === "success" && location.pathname === "/login" && user) || */
      status === "success" &&
      location.pathname === "/perfil" &&
      user
    ) {
      if (rel) navigate(`${rel}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, user]);

  return (
    <PageContainer title={""}>
      {/* <Container sx={{ paddingTop: "1rem", width: "100%" }}> */}
      {user && (
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
        >
          <AvatarWrapper>
            <UserAvatar user={user} showName={true} />
          </AvatarWrapper>
          <Button
            variant="contained"
            color="info"
            sx={{ borderRadius: "4vh" }}
            onClick={() => {
              setUser(null);
            }}
          >
            Cerrar Sesión
          </Button>
        </Stack>
      )}

      {!user && (
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
        >
          <Subtitle>Continuar con</Subtitle>
          {/* <LoginButton
            startIcon={<FacebookIcon />}
            variant="contained"
            color="info"
            style={{ background: "#0000ff" }}
            onClick={() => {
              AnalyticsController.trackButtonClicked("login_facebook");
              signIn(auth, "facebook");
            }}
          >
            Facebook
          </LoginButton> */}
          <LoginButton
            variant="outlined"
            color="inherit"
            startIcon={<GoogleIcon />}
            /*   sx={{
              borderRadius: "4vh",
              width: "70%",
              marginBottom: "1.5vh",
              color: "black",
              borderColor: "black"
            }} */
            onClick={() => {
              AnalyticsController.trackButtonClicked("login_google");
              signIn(auth, "google");
            }}
          >
            Google
          </LoginButton>
        </Stack>
      )}
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
      >
        <Button
          variant="text"
          color="inherit"
          sx={{
            borderRadius: "4vh",
            width: "70%",
            marginTop: "1.5vh"
          }}
          onClick={() => {
            if (!rel) navigate(-1);
            else navigate(rel);
          }}
        >
          {!user ? "Cancelar" : "Volver"}
        </Button>

        {!user && (
          <Description>
            Debes iniciar sesión para guardar las visitas del Recorrido en tu
            cuenta
          </Description>
        )}
      </Stack>
    </PageContainer>
  );
}

const LoginButton = styled(Button)`
  border-radius: 8px;
  width: 70%;
  height: 16vh;
  margin-bottom: 2rem;
`;

const AvatarWrapper = styled.div`
  padding: 2rem 0;
`;
