import React, { useRef, useEffect } from "react";
import { createRoot } from "react-dom/client";

import { Position } from "components/Map";

export interface MapMarkerProps {
  map: any;
  position: Position;
  children: any;
  onClick: any;
}

export default function MapMarker({
  map,
  children,
  position,
  onClick,
}: MapMarkerProps) {
  const markerRef = useRef<any>();
  const rootRef = useRef<any>();

  useEffect(() => {
    // set data
    if (!rootRef.current) {
      const container = document.createElement("div");
      rootRef.current = createRoot(container);
      // hint: use react portal
      markerRef.current = new window.google.maps.marker.AdvancedMarkerView({
        position,
        content: container,
      });
    }
  }, []);

  useEffect(() => {
    rootRef.current.render(children);
    markerRef.current.position = position;
    markerRef.current.map = map;
    const listener = markerRef.current.addListener("click", onClick);
    // TODO: add listener for change className of marker
    return () => {
      listener.remove();
    };
  }, [map]);

  return <></>;
}
