import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import MuiAlert, { AlertProps, AlertColor } from "@mui/material/Alert";

interface State extends SnackbarOrigin {
  open: boolean;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function AlertMessage(props: {
  open: boolean;
  message: string;
  severity: AlertColor | undefined;
}) {
  const { message, severity = "success" } = props;

  const [state, setState] = React.useState<State>({
    open: props.open || false,
    vertical: "top",
    horizontal: "center"
  });

  useEffect(() => {
    setState({ ...state, open: props.open });
  }, [props.open]);

  const { vertical, horizontal, open } = state;

  const handleClose = () => {
    setState({ ...state, open: false });
  };
  //"status.open", state.open);
  //console.log("status.open", state.open);
  return (
    <Box sx={{ width: "auto" }}>
      <Snackbar open={open} autoHideDuration={10000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );
}
