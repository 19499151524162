import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";

import Stack from "@mui/material/Stack";

// Providers
import { Place, usePlacesData } from "providers/Places";
import { useUserContext } from "providers/User";
import AnalyticsController from "providers/Analytics";

// Components
import Stamp from "components/Stamp";

// UI
import PageContainer from "ui/PageContainer";
import CheckCard from "components/CheckCard";
import PrizesDialog from "components/PrizesDialog";
import { EVENT } from "providers/EventRoute";
import { Route } from "providers/Routes";

export default function MyPassportPage() {
  const user = useUserContext();
  // Places custom-hook
  const [places, status] = usePlacesData();
  const [place, setPlace] = useState<Place | null>(null);
  const [stampOpen, setStampOpen] = useState(false);

  const sortedPlaces = places
    .map((place: Place) => {
      const visited = user
        ? user?.savedMonuments?.some(
            (vPlace: Place) =>
              vPlace.id === place.id && vPlace.route === EVENT.event
          )
        : false;
      return { ...place, visited };
    })
    .sort((a, b) => (a.visited ? -1 : 1) - (b.visited ? -1 : 1));

  const savedPlaces: number =
    user?.savedMonuments?.filter((place) => place.route === EVENT.event)
      .length || 0;
  const pageTitle = savedPlaces
    ? `${savedPlaces} de ${places.length} lugares visitados `
    : "¡Descubre la ciudad y obtén recompensas!";

  const pageDescription =
    savedPlaces > 0 ? "¡Descubre la ciudad y obtén recompensas!" : "";
  return (
    <>
      <PageContainer
        title={pageTitle}
        loading={status === "loading"}
        description={pageDescription}
      >
        <Stack direction="column">
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              margin: "2rem 0"
            }}
          >
            <PrizesDialog />
          </div>
          {/* {!user && <HowTo />} */}
          <Grid xs={12} container>
            {sortedPlaces.map((place: Place, key: number) => {
              return (
                <Grid key={key} xs={4}>
                  <Stamp
                    place={place}
                    onClick={() => {
                      AnalyticsController.trackAppEvent(
                        "user_tracks_place",
                        place.name
                      );
                      setPlace(place);
                      !place.visited && setStampOpen(true);
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Stack>
        <CheckCard
          place={place}
          route={EVENT.event}
          isOpen={stampOpen}
          showCloseIcon={false}
          onClose={() => {
            AnalyticsController.trackAppEvent(
              "user_opens_stamp",
              place?.name ?? ""
            );
            setStampOpen(false);
          }}
        />
        {/* {user && (
        <Grid xs={12}>
          <StampsProgress />
        </Grid>
      )} */}
      </PageContainer>
    </>
  );
}
