import { useFirestoreCollectionData, useFirestore } from "reactfire";
import { collection, orderBy, query, where } from "firebase/firestore";

const ACTIVITIES_COLLECTION = "activities";
const DEFAULT_ICON_URL = "/assets/img/tito-banner-1.png";
export interface Activity {
  id: string;
  urlKey: string;
  title: string;
  icon: { url: string; title: string };
  description: string;
  dateTo: Date;
  dateFrom: Date;
  time: string;
  endTime: string;
  interestPlaces: string;
  district?: string;
  address?: string;
}

export function useActivitiesData(qry?: any) {
  const firestore = useFirestore();
  const activitiesCollection = collection(firestore, ACTIVITIES_COLLECTION);
  //const activitiesQry = query(activitiesCollection, orderBy("dateFrom", "desc"));
  const activitiesQry = query(
    activitiesCollection,
    where("dateTo", ">=", new Date("2024-01-01")),
    orderBy("dateTo", "asc")
    /*  orderBy("dateFrom", "desc") */
  );

  const { status, data: activities } = useFirestoreCollectionData(
    activitiesQry,
    {
      idField: "id"
    }
  );

  const activitiees: Activity[] = parseFbActivities(activities);

  return [activitiees, status] as const;
}

function parseFbActivities(fbp: any): Activity[] {
  const activities: Activity[] = [];

  if (fbp) {
    fbp.map((fbp: any) => {
      try {
        const {
          id,
          title,
          description,
          icon = { url: "", title: "" },
          dateTo,
          dateFrom,
          urlKey,
          interestPlaces,
          time,
          endTime,
          district,
          address
        } = fbp;
        const activityIcon = {
          url: icon.url || DEFAULT_ICON_URL,
          title: icon.title || "Default Icon"
        };
        const activity: Activity = {
          urlKey,
          id,
          title,
          description,
          icon: activityIcon,
          dateTo,
          dateFrom,
          time,
          endTime,
          interestPlaces,
          address,
          district
        };

        activities.push(activity);
        return activity;
      } catch (error) {
        return null;
      }
    });
  }
  return activities;
}
