import React, { useState } from "react";
import styled from "@emotion/styled";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useActivitiesData, Activity } from "providers/Activities";
import { Sheet } from "react-modal-sheet";
import ActivityDetail from "components/ActivityDetail";
import PageContainer from "ui/PageContainer";
import BoxList from "ui/BoxList";
import DateFormat from "components/DateFormat";
import { text } from "stream/consumers";
import AnalyticsController from "providers/Analytics";
import { useNavigate } from "react-router-dom";

const StyledContainer = styled.div`
  margin-bottom: 14px;
  margin-left: 16px;
  margin-right: 16px;
  padding: 10px;
  background-color: #ffffff;
  position: relative;
`;

const ArrowIconContainer = styled.div`
  position: absolute;
  bottom: 10px;
  right: 0;
`;

const ActivityCard: React.FC = () => {
  const [activities, status] = useActivitiesData();
  const [selectedActivity, setSelectedActivity] = useState<Activity | null>(
    null
  );
  const navigate = useNavigate();

  if (status === "loading") {
    return <div>Loading...</div>;
  }

  const handleActivityClick = (activity: Activity) => {
    navigate(`/actividad/${activity.urlKey}`);
    //setSelectedActivity(activity);
  };

  const onCloseSheet = () => {
    setSelectedActivity(null);
  };

  return (
    <>
      {activities.slice(0, 4).map((activity: Activity) => (
        <StyledContainer
          key={activity.id}
          onClick={() => {
            AnalyticsController.trackButtonClicked("activity_card_click");
            handleActivityClick(activity);
          }}
        >
          <Stack direction="row" alignItems="center">
            <img
              alt={activity.title}
              src={activity.icon.url}
              style={{ width: "109px", height: "86px" }}
            />
            <Stack direction="column" marginLeft="10px" width="100%">
              <Typography
                variant="subtitle1"
                textAlign="left"
                sx={{ fontWeight: "bold", fontSize: "14.53px" }}
              >
                {activity.title}
              </Typography>
              <DateFormat date={activity} />
              <Typography variant="body2" textAlign="left">
                De {activity.time} a {activity.endTime}hrs.
              </Typography>
            </Stack>
          </Stack>
          {/* <ArrowIconContainer>
            <IconButton onClick={() => handleActivityClick(activity)}>
              <ArrowForwardIosIcon />
            </IconButton>
          </ArrowIconContainer> */}
        </StyledContainer>
      ))}
      {/* Sheet para mostrar el detalle de la actividad */}
      <Sheet
        isOpen={selectedActivity !== null}
        onClose={onCloseSheet}
        snapPoints={[600]}
        initialSnap={0}
      >
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            <div className="scrollable-content">
              <PageContainer title={selectedActivity?.title}>
                <BoxList>
                  <ActivityDetail activity={selectedActivity} />
                </BoxList>
              </PageContainer>
            </div>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={onCloseSheet} />
      </Sheet>
    </>
  );
};

export default ActivityCard;
