import React from "react";
import { Activity } from "providers/Activities";
import ActivityListCard from "components/ActivityListCard";

interface ActivityListCardContainerProps {
  activity: Activity;
  onClick?: () => void;
}

export const DEFAULT_COVER_IMAGE =
    "https://firebasestorage.googleapis.com/v0/b/city-tour-84058.appspot.com/o/imagen-portada.png?alt=media&token=1d609b1e-573e-44cb-8f34-33a4d7a95be1";

export default function ActivityListCardContainer({
  activity,
  onClick = () => {},
  }: ActivityListCardContainerProps) {
const getImage =
  activity?.icon.url !== null ? activity?.icon.url : DEFAULT_COVER_IMAGE;

return (
    <ActivityListCard
      activity={activity}
      onClick={onClick}
      getImage={getImage}
    />
  );
}
