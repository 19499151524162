import { useEffect, useState } from "react";
import { useUserContext, useUpdateUserContext } from "providers/User";

// Providers
import { Place } from "providers/Places";
import { checkQrToken } from "providers/Token";

// Components
import PlaceDetail from "components/PlaceDetail";

interface PlaceContainerProps {
  qrKey?: string;
  place: Place;
}

export default function PlaceContainer({ place, qrKey }: PlaceContainerProps) {
  const user = useUserContext();
  const updateUser = useUpdateUserContext();

  const [qrAuthorization, setQrAuthorization] = useState(false);

  useEffect(() => {
    if (qrKey && user && place) {
      // TODO: check qrKey
      const valid: boolean = checkQrToken(qrKey, place?.urlKey ?? "");
      setQrAuthorization(valid);
    }
  }, [place, user, qrKey]);

  return (
    <>
      <PlaceDetail
        place={place}
        qrAuthorization={qrAuthorization}
        user={user}
        onUpdateUser={updateUser}
      />
    </>
  );
}
