import React, { useState, useEffect, useRef } from "react";

//MUI
import FavoriteIcon from "@mui/icons-material/Favorite";
import Stack from "@mui/material/Stack";

//PROVIDERS
import { Place, distanceBetween } from "providers/Places";

//STYLES
import { Container, Icon } from "./styles";
import "./styles.scss";

interface CityMapPlaceProps {
  place: Place;
  userLocation?: any;
  disabled?: boolean;
}

export default function CityMapPlace({
  place,
  userLocation = null
}: CityMapPlaceProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [distance, setDistance] = useState<number | null>(null);
  const [isNear, setIsNear] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const userCoordinates = userLocation?.coordinates || null;
    const placeCoordinates = place?.location?.coordinates || null;
    if (userCoordinates && placeCoordinates) {
      const dist = distanceBetween(userCoordinates, placeCoordinates);
      setDistance(dist);
      setIsNear(dist <= 50);

      if (dist > 50) {
        setModalOpen(true);
      }
    }
  }, [userLocation, place]);

  return (
    <Container ref={containerRef}>
      <Stack>
        <Icon
          src={place.icon}
          alt={place.name}
          className={isNear ? "red-icon" : "#666666"}
          style={{
            position: "relative",
            zIndex: isNear ? 10 : 1
          }}
        />
        <p
          style={{
            background: "white",
            color: "#666666",
            fontSize: ".8rem",
            padding: ".3rem",
            borderRadius: "15px",
            margin: 0,
            position: "relative"
          }}
        >
          {place.visited && (
            <div>
              <FavoriteIcon
                style={{
                  background: "white",
                  borderRadius: "50%",
                  position: "absolute",
                  top: -2,
                  left: -20,
                  /*   right: -25, */
                  color: "rgb(237, 108, 2)",
                  zIndex: 2
                }}
              />
            </div>
          )}
          {place.name}
        </p>
      </Stack>
    </Container>
  );
}
