import React, { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { QrScanner } from "@yudiel/react-qr-scanner";
import { v4 as uuidv4 } from "uuid";
// UI
import PageContainer from "ui/PageContainer";

// MUI
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import MuiAlert from "@mui/material/Alert";

// Providers
import { useUserContext, useUpdateUserContext } from "providers/User";
import { Place, usePlacesData } from "providers/Places";
import AnalyticsController from "providers/Analytics";

// UI
import Description from "ui/Description";
import Title from "ui/Title";

// Components
import AlertMessage from "components/AlertMessage";

// Hooks
import { usePollChecker } from "hooks/usePollChecker";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// https://www.npmjs.com/package/@yudiel/react-qr-scanner
const QR_BASE_PATH =
  "https://innovarancagua.cl/proyectos/historia-en-tus-manos/detalle?";
const KEY = "scXItcC0yMw==";
const CURRENT_EVENT = "CARROS2023";

export default function QrValidation() {
  const [places, status] = usePlacesData();
  const [qrCode, setQrCode] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [errorCode, setErrorCode] = useState(null);
  /* const user = useUserContext(); */
  /*  const updateUser = useUpdateUserContext(); */
  const pollChecker = usePollChecker();
  const { authenticated } = pollChecker;
  const navigate = useNavigate();

  const onScan = useCallback(
    (qr: string) => {
      if (qr && authenticated) {
        setLoading(true);
        setError("");
        setErrorCode(null);
        try {
          setQrCode(qr.trim());
          const urlKey = qr.trim().replace(QR_BASE_PATH, "");
          if (urlKey.trim() === KEY.trim()) {
            // save qr source
            setSuccess("QR válido");
            AnalyticsController.trackAppEvent(
              "qr_validation_success",
              "qr_valid"
            );
            setTimeout(() => {
              const k = uuidv4(); // TODO: Check if exist in LS
              navigate(`/votacion/${CURRENT_EVENT}?qr=${k}`);
              setLoading(false);
            }, 1000);
          } else {
            // TODO: add exceptions
            setError("Este QR no sirve para votar");
            setLoading(false);
            AnalyticsController.trackAppEvent("qr_validation_other", urlKey);
          }
          setLoading(false);
        } catch (error) {
          AnalyticsController.trackAppEvent(
            "qr_validation_error",
            "scan_error"
          );
          setError("Ocurrio un problema al escanear el QR");
          setLoading(false);
        }
      }
    },
    [authenticated]
  );

  const handleClose = () => {
    navigate(`/votacion/${CURRENT_EVENT}}`);
  };

  return (
    <PageContainer loading={loading || status === "loading"}>
      {pollChecker && pollChecker.error !== "" && (
        <AlertMessage
          open={pollChecker && pollChecker.error !== ""}
          severity="error"
          message={pollChecker.error}
        />
      )}
      <Stack>
        <div
          style={{
            border: "24px solid #E86039",
            borderRadius: "10px",
            margin: "1rem 0"
          }}
        >
          <QrScanner
            onDecode={onScan}
            onError={(error: any) => {
              setLoading(false);
              setError("Ocurrio un problema al escanear el QR");
              setErrorCode(error);
            }}
          />
        </div>

        <Title>Votar escaneando QR</Title>
        <Description>Escanea los QR disponibles en el Evento.</Description>
        {error && <Alert severity="error">{error}</Alert>}
        <Button
          variant="outlined"
          color="inherit"
          sx={{
            border: "none",
            width: "100%",
            marginTop: "4vh",
            fontWeight: "bold"
          }}
          onClick={() => {
            navigate(-1);
          }}
        >
          Volver
        </Button>
        {errorCode && <pre>{JSON.stringify(errorCode)}</pre>}
        {/* {qrCode && <pre>{JSON.stringify(qrCode)}</pre>} */}
        {/* <Snackbar
        open={success!== ''}
        autoHideDuration={6000}
        onClose={()=>{
          navigate("/mi-pasaporte");
        }}
        message="Note archived"
        action={action}
      /> */}

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={success !== ""}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            {success}
          </Alert>
        </Snackbar>
      </Stack>
    </PageContainer>
  );
}
