import React from "react";

// COMPONENTS
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

// ICONS
import FavoriteContentIcon from "@mui/icons-material/Favorite";
import NearMeIcon from "@mui/icons-material/NearMe";

// UI
import Item from "ui/Item";

interface PlaceCardProps {
  place: any;
  disabled?: boolean;
  featured?: boolean;
  onClick?: () => void;
}

export default function PlaceCard({
  place,
  disabled = false,
  featured = false,
  onClick = () => {},
}: PlaceCardProps) {
  const itemStyle = featured ? { background: "red", color: "white" } : {};

  const img = place?.coverImage?.url ? place?.coverImage?.url : place?.icon;

  return (
    <Item style={itemStyle} onClick={onClick}>
      <Stack direction="row" sx={{ alignItems: "center", padding: "0 .5rem" }}>
        <img
          alt="icon"
          src={img}
          style={{
            width: "90px",
            height: "90px",
            objectFit: "contain",
            opacity: `${disabled ? "0.5" : "1"}`,
          }}
        />

        <Stack direction="column" style={{ marginLeft: "10px", width: "100%" }}>
          <Typography
            gutterBottom
            variant="subtitle1"
            component="span"
            textAlign="left"
            style={{ fontSize: "1rem", position: "relative" }}
          >
            {place.name}{" "}
            {place.visited && (
              <FavoriteContentIcon
                style={{
                  color: "rgb(237, 108, 2)",
                  right: "0",
                  position: "absolute",
                }}
              />
            )}
          </Typography>
          <Typography style={{ fontSize: ".8rem" }} textAlign="left">
            {place?.location?.address?.streetName},{" "}
            {place?.location?.address?.streetNumber}
          </Typography>

          {place?.distance && place?.distance !== -1 && (
            <p
              style={{
                color: "rgb(237, 108, 2)",
                textAlign: "left",
                width: "100%",
              }}
            >
              <NearMeIcon /> {place.distance}m{" "}
            </p>
          )}
        </Stack>
      </Stack>
    </Item>
  );
}
