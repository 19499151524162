import React, { useState } from "react";
import { Sheet } from "react-modal-sheet";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from "react-share";
import { FacebookIcon, TwitterIcon, WhatsappIcon } from "react-share";

// MUI
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ShareIcon from "@mui/icons-material/Share";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

// UI
import BoxList from "ui/BoxList";

// STYLES
import { IconMenu, Icon } from "./styles";
import BsShare from "@mui/icons-material/Share";

export interface ShareDialogProps {
  url: string;
  title: string;
  description?: string;
  navbar: boolean;
  button?: boolean;
}

export default function ShareDialog({
  title,
  url,
  description,
  navbar,
  button
}: ShareDialogProps) {
  const [isOpen, setOpen] = useState<boolean>(false);

  return (
    <>
      {/*
      {button ? (
        <Button
          variant="outlined"
          sx={{
            color: "black",
            padding: "5px 22px",
            borderColor: "black",
            borderRadius: "29px",
            fontWeight: 700,
            gap: "10px",
            border: "1px solid #000",
            display: "flex",
            marginX: "auto",
            margin: "1rem auto",
            fontSize: "1rem",
            fontStyle: "normal",
            lineHeight: "17.5px",
            marginTop: "-10px",
            "&:hover, &:active": {
              borderColor: "black"
            },
            textTransform: "capitalize"
          }}
          endIcon={<BsShare />}
          onClick={() => setOpen(true)}
        >
          Compartir
        </Button>
      ) : (
        <Button onClick={() => setOpen(true)}>
          <FavoriteBorderIcon sx={{ color: navbar ? "white" : "black" }} />
        </Button>
      )}

      
      <Sheet
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        springConfig={{ stiffness: 150, damping: 20, mass: 1 }}
        detent={"content-height"}
      >
        <Sheet.Container>
          <Sheet.Header />
          <BoxList>
            <p>Compartir</p>
          </BoxList>
          <Sheet.Content>
            <List sx={{ pt: 0 }}>
              <ShareDialogDiv>
                <ListItem disableGutters>
                  <ListItemButton>
                    <FacebookShareButton
                      url={url}
                      quote={title}
                      hashtag="#LaHistoriaEstaEnTusManos"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%"
                      }}
                    >
                      <FacebookIcon size={32} round />
                      <span style={{ marginLeft: "0.5em" }}>Facebook</span>
                    </FacebookShareButton>
                  </ListItemButton>
                </ListItem>
              </ShareDialogDiv>
              <ShareDialogDiv>
                <ListItem disableGutters>
                  <ListItemButton>
                    <TwitterShareButton
                      title={title}
                      hashtags={["LaHistoriaEstaEnTusManos"]}
                      url={url}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%"
                      }}
                    >
                      <TwitterIcon size={32} round />
                      <span style={{ marginLeft: "0.5em" }}>Twitter</span>
                    </TwitterShareButton>
                  </ListItemButton>
                </ListItem>
              </ShareDialogDiv>
              <ShareDialogDiv>
                <ListItem disableGutters>
                  <ListItemButton>
                    <WhatsappShareButton
                      title={title}
                      url={url}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%"
                      }}
                    >
                      <WhatsappIcon size={32} round />
                      <span style={{ marginLeft: "0.5em" }}>Whatsapp</span>
                    </WhatsappShareButton>
                  </ListItemButton>
                </ListItem>
              </ShareDialogDiv>
            </List>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>
      */}
      <IconMenu
        style={{
          display: "flex",
          justifyContent: "right",
          backgroundColor: "transparent"
        }}
      >
        <Icon src="/assets/icon_heart.svg" alt="Descubre Rancagua Antiguo" />
      </IconMenu>
    </>
  );
}
