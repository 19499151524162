import React, { useState, useEffect } from "react";
import { useParams, useSearchParams, useLocation } from "react-router-dom";

// Providers
import { usePlacesData, Place } from "providers/Places";
import { useUserContext } from "providers/User";

// Components
import PlaceDetail from "components/PlaceDetail";

// UI
import PageContainer from "ui/PageContainer";

const EMBED_PATHS = ["lugar"]; // TODO: move to config

export default function PlacePage() {
  const location = useLocation();
  const [place, setPlace] = useState<Place | null>(null);
  const { urlKey = "" } = useParams();
  const [places, status] = usePlacesData();
  const user = useUserContext();
  const [searchParams] = useSearchParams();
  const path = location.pathname.split("/");
  const embed: boolean = true;

  searchParams.get("embed") === "true" ||
    (path[1] && EMBED_PATHS.includes(path[1]));

  useEffect(() => {
    const place = places.find((place: any) => place.urlKey === urlKey);
    if (place) setPlace(place);
  }, [places, urlKey]);

  return (
    <PageContainer title={place?.name} loading={status === "loading"}>
      {place && <PlaceDetail place={place} embed={embed} />}
    </PageContainer>
  );
}
