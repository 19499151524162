import React, { useState } from "react";
import styled from "@emotion/styled";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import Title from "ui/Title";
import Description from "ui/Description";
import ActionButton from "ui/ActionButton";
/* 
interface QrInformationProps {
  icon: React.ReactNode;
  progressColor?: string;
  percentage?: number;
  onClick?: () => void;
} */

export default function QrInformation() {
  return (
    <Container>
      <Header />
      <Grid
        container
        style={{
          padding: "0 16px",
          width: "100%"
        }}
      >
        <Grid xs={12} style={{ padding: "1rem 0" }}>
          <Title>Escanea y registra tu visita</Title>
        </Grid>
        <Grid xs={12}>
          <Link to="/scan">
            <ActionButton
              variant="contained"
              color="warning"
              fullWidth
              onClick={() => {
                /* console.log("on scan!"); */
              }}
            >
              Escanear
            </ActionButton>
          </Link>
        </Grid>
        <Grid xs={12}>
          <Description>
            Se requiere el uso de la cámara del dispositivo para continuar
          </Description>
        </Grid>
      </Grid>
    </Container>
  );
}

const Container = styled.div``;
const Header = styled.div`
  background: url("/assets/img/qr-background.png") no-repeat center;
  background-size: cover;
  background-position: center 20px;
  height: 35vh;
  width: 100%;
`;
