export const LOCATION_KEY = "l0k@t10n";

export interface Coordinates {
  lat: number;
  lng: number;
}

export interface Address {
  city?: String;
  streetName?: String;
  streetNumber?: String;
  postalCode?: number;
}

export interface Location {
  address?: Address;
  coordinates: Coordinates;
}
