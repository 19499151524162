import * as React from "react";
import Fab from "@mui/material/Fab";
import NavigationIcon from "@mui/icons-material/Navigation";

import { ButtonContainer } from "./styles";
export default function FloatingButton(props: any) {
  return (
    <ButtonContainer>
      <Fab
        {...props}
        variant="extended"
        size="small"
        color="secondary"
        aria-label="add"
      >
        <NavigationIcon sx={{ mr: 1 }} />
        CENTRAR
      </Fab>
    </ButtonContainer>
  );
}
