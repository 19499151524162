import { User } from "./User";

export const SESSION_KEY = "s35i0n";

export interface Session {
  user: User | null;
  locations?: Location[];
  createdAt?: Date;
  updatedAt?: Date;
  expireAt?: number;
}
