import styled from "@emotion/styled";

const Title = styled.h1`
  font-size: 1.5rem;
  padding: 0;
  margin: 0;
  text-align: left;
  color: #000000;
`;
export default Title;
