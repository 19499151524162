import styled from "@emotion/styled";

export const BannerWrapper = styled.div`
  width: 100vw;
  height: 60vh;
  padding: 0;
  margin: 0;
  min-height: 60vh;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
`;
