import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { map } from "lodash";
import ImageGallery from "react-image-gallery";
import styled from "@emotion/styled";

// COMPONENTS
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import CheckCard from "components/CheckCard";
import SavedDialog from "components/SavedDialog";
import SanitizeHTML from "components/SanitizeHTML";
import GridGallery from "components/GridGallery";
import Stamp from "components/Stamp";
import YouTubeEmbed from "components/YoutubeEmbed";
import Subtitle from "ui/Subtitle";
import ActivityCardPlaceList from "components/ActivityCardPlaceList";
// ICONS
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteContentIcon from "@mui/icons-material/Favorite";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";

// MUI
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

// PROVIDERS
import { Place } from "providers/Places";
import { EVENT } from "providers/EventRoute";
import { useActivitiesData, Activity } from "providers/Activities";
import {
  useUserLocationContext,
  useUpdateUserLocationContext
} from "providers/UserLocation";
// STYLES
import {
  ImageGalleryImage,
  PlaceStack,
  Heading,
  BoxDetail,
  ContentStack,
  SaveButton,
  DialogButton,
  TitleDialog
} from "./styles";

interface PlaceDetailProps {
  place: Place;
  showName?: boolean;
  embed?: boolean;
  qrKey?: string;
  qrAuthorization?: boolean;
  onUpdateUser?: (user: any) => void;
  user?: any;
}

const MAX_SAVE_DISTANCE = 500;
export default function PlaceDetail({
  place,
  showName = false,
  embed = false,
  qrKey = "",
  user = null,
  onUpdateUser = () => {},
  qrAuthorization = false
}: PlaceDetailProps) {
  const navigate = useNavigate();
  const [activities, status] = useActivitiesData();

  const [showCheckCard, setShowCheckCard] = useState(false);
  const [showIsSavedDialog, setShowIsSavedDialog] = useState(false);
  const [showLoginDialog, setShowLoginDialog] = useState(false);
  const userLocationPermission = useUpdateUserLocationContext();
  const isSaved = user?.savedMonuments?.some(
    (savedPlace: any) =>
      savedPlace.id === place.id && savedPlace.route === EVENT.event
  );
  const [saved, setSaved] = useState(isSaved);
  const placeActivities = activities.filter((activity: Activity) => {
    return activity.interestPlaces === place.id;
  });
  useEffect(() => {
    if (user && place) {
      const isPlaceSaved = user?.savedMonuments?.some(
        (savedPlace: any) =>
          savedPlace.id === place.id && savedPlace.route === EVENT.event
      );
      setSaved(isPlaceSaved);
    }

    if (place) {
      // console.log("place", place);
    }
  }, [place, user, qrKey, saved]);

  const handleLogin = () => {
    const path = `/perfil?rel=/rutas/con-tito-lastarria-24/map?place=${place?.urlKey}&qr=${qrKey}`;
    navigate(path);
  };

  const handleSavePlace = useCallback(() => {
    if (saved) {
      setShowIsSavedDialog(true);
      return;
    }
    userLocationPermission(true);
    const isNear =
      place && place.distance && place.distance <= MAX_SAVE_DISTANCE
        ? true
        : false;
    console.log("place?.distance", place?.distance);
    console.log("isNear", isNear);

    if (user && (isNear || qrAuthorization)) {
      savePlace({ ...place, fromQr: qrAuthorization });
    } else if (!user) {
      setShowLoginDialog(true);
    } else if (!isNear) {
      setShowCheckCard(true);
    }
  }, [user, saved, place]);

  const savePlace = useCallback(
    (place: any) => {
      const savedMonuments = user?.savedMonuments ?? [];
      const isPlaceSaved = savedMonuments.some(
        (savedPlace: any) =>
          savedPlace.id === place.id && savedPlace.route === EVENT.event
      );
      console.log("isPlaceSaved", isPlaceSaved);
      if (!isPlaceSaved) {
        const updatedSavedMonuments = [
          ...savedMonuments,
          { ...place, route: EVENT.event, savedAt: new Date() }
        ];
        console.log("updatedSavedMonuments", updatedSavedMonuments);
        console.log("user", user);
        onUpdateUser({ ...user, savedMonuments: updatedSavedMonuments });
      }
      setSaved(true);
    },
    [place, user]
  );

  const imagesUrls = map(place?.image, "url");
  const galleryItems = imagesUrls ? getGalleryItems(imagesUrls) : [];

  return (
    <Paper elevation={0}>
      <PlaceStack>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={saved}
          autoHideDuration={10000}
          onClose={() => setSaved(false)}
        >
          <Alert
            onClose={() => setSaved(false)}
            severity="success"
            sx={{ width: "100%" }}
          >
            ¡Visita guardada!
          </Alert>
        </Snackbar>

        {showName && <Heading>{place.name}</Heading>}
      </PlaceStack>
      {/*   <Box sx={{ margin: "1rem 0" }}>
        <ImageGallery
          items={galleryItems}
          showThumbnails={false}
          autoPlay={false}
          lazyLoad={true}
          showFullscreenButton={embed ? false : true}
        />
      </Box> */}

      <ContentStack>
        {/*    <BoxDetail>
          <LocationOnOutlinedIcon sx={{ fontSize: "30px" }} />{" "}
          <span style={{ fontSize: "14px" }}>{address}</span>
        </BoxDetail> */}
        <Grid container>
          <Grid item xs={2} sm={2} md={2} lg={2} xl={2} />
          <Grid item xs={8} sm={8}>
            <Stamp
              place={place}
              onClick={() => {
                !isSaved && handleSavePlace();
              }}
              visited={isSaved}
            />
            {place.distance && !isSaved && (
              <Distance>Distancia {place.distance}m</Distance>
            )}

            <SaveButton
              variant="contained"
              color={isSaved ? "warning" : "info"}
              style={{
                background: isSaved ? "#4B267C" : "",
                color: isSaved ? "#fff" : ""
              }}
              fullWidth
              onClick={handleSavePlace}
              disabled={isSaved}
            >
              {isSaved ? "Visita Guardada" : "Guardar Visita"}
            </SaveButton>
          </Grid>

          {place?.video && place?.video?.id && (
            <Grid item xs={12} sm={12}>
              <VideoWrapper>
                <YouTubeEmbed videoId={place.video.id} />
              </VideoWrapper>
            </Grid>
          )}
          <Grid item xs={12} sm={12}>
            <Subtitle>Galería</Subtitle>
            {galleryItems && galleryItems.length > 0 && (
              <GridGallery images={galleryItems} />
            )}
          </Grid>
          {placeActivities && placeActivities.length > 0 && (
            <Grid item xs={12} sm={12}>
              <Subtitle>Actividades</Subtitle>
              <div>
                <ActivityCardPlaceList activities={placeActivities} />
              </div>
            </Grid>
          )}
          <Grid item xs={12} sm={12}>
            <Subtitle>Historia</Subtitle>
            <SanitizeHTML data={place} embed={true} />
          </Grid>
          {/* TODO: MOSTRAR EL AUTOR DEL CONTENIDO */}
        </Grid>

        <SavedDialog
          isOpen={showIsSavedDialog}
          onClose={() => setShowIsSavedDialog(false)}
        />

        <CheckCard
          place={place}
          route={EVENT.event}
          isOpen={showCheckCard}
          onClose={() => setShowCheckCard(false)}
        />

        <Dialog
          open={showLoginDialog}
          onClose={() => setShowLoginDialog(false)}
          aria-labelledby="responsive-dialog-title"
          PaperProps={{
            style: {
              zIndex: 9999,
              borderRadius: "10px"
            }
          }}
        >
          <TitleDialog>{"Debes crear una cuenta"}</TitleDialog>
          <DialogContent style={{ padding: "1rem" }}>
            <DialogContentText>
              Para guardar tus visitas durante el recorrido.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <DialogButton
              variant="outlined"
              color="info"
              autoFocus
              onClick={() => setShowLoginDialog(false)}
            >
              Cerrar
            </DialogButton>
            <DialogButton
              variant="contained"
              color="info"
              autoFocus
              onClick={handleLogin}
            >
              Crear cuenta
            </DialogButton>
          </DialogActions>
        </Dialog>
        {/* <SanitizeHTML data={place} embed={false} /> */}
      </ContentStack>
    </Paper>
  );
}

function getGalleryItems(imagesUrls: any) {
  return imagesUrls.map((url: string, key: number) => {
    return {
      src: url,
      width: 320,
      height: 212,
      caption: `Imagen ${key + 1}`
    };

    /* return {
      original: url,
      thumbnail: url,
      renderItem: (item: any) => {
        return <ImageGalleryImage key={key} imageUrl={item.original} />;
      }
    }; */
  });
}

const VideoWrapper = styled.div`
  padding: 1rem 0;
`;

const Distance = styled.p`
  font-size: 0.9rem;
  font-weight: 400;
  margin: 0.5rem 0;
  padding: 0;
  color: #000;
  width: 100%;
  text-align: center;
`;
