import React, { Fragment, useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import BsShare from "@mui/icons-material/Share";
import Confetti from "react-confetti";

//Providers
import {
  getParticipant,
  getParticipantByParams,
  Participant
} from "providers/Participant";

// Components
import ShareDialog from "components/ShareDialog";
import VoteCard from "components/VoteCard";
import ListResults from "components/ListResults";
import Loading from "components/Loading";

// Hooks
import { usePollChecker } from "hooks/usePollChecker";
import { useSummaryData } from "hooks/useSummaryData";

// Components
import EventCountdown from "components/EventCountdown";
import AlertMessage from "components/AlertMessage";

export default function VotingView() {
  const [participants, setParticipants] = useState<Participant[]>([]);
  const [participant, setParticipant] = useState<Participant>();
  const [confettiVisible, setConfettiVisible] = useState(false);
  const [data, status] = useSummaryData();
  const { urlKey } = useParams();
  const { numberOfVotes, settings, loading, error } = usePollChecker();

  useEffect(() => {
    if (status === "success") {
      // TODO: sort by number of votes
      (async () => {
        let participantList = (await getParticipant()).map((participant) => {
          const participantSummary = data.find((d) => {
            return d.key === participant.urlKey;
          });
          participant.numberVotes = participantSummary?.count || 0;
          return participant;
        });
        const sortedParticipants = participantList.sort(
          (a: any, b: any) => b.numberVotes - a.numberVotes
        );
        setParticipants(sortedParticipants);
      })();
    }
  }, [status, data]);

  useEffect(() => {
    (async () => {
      try {
        const participant = await getParticipantByParams({ urlKey });
        setParticipant(participant);
        setConfettiVisible(true);
      } catch (error) {
        console.error("Error fetching participant:", error);
      }
    })();
  }, [urlKey]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {confettiVisible && <Confetti height={1550} numberOfPieces={100} />}
      <img
        style={{
          width: "100%",
          marginTop: "0.5rem"
        }}
        src="/assets/banderines.svg"
        alt="banderines"
      />
      <Container
        sx={{
          padding: "0 1rem"
        }}
      >
        <AlertMessage open={error !== ""} severity="error" message={error} />
        <Typography
          sx={{
            padding: "1rem",
            fontSize: "1.875rem",
            fontWeight: 700,
            lineHeight: "2.0625rem",
            marginTop: "1rem",
            textAlign: "center"
          }}
        >
          ¡Voto registrado con éxito!
        </Typography>
        {participants && participant && (
          <VoteCard data={participant} small={true} />
        )}
        <ShareDialog
          url={
            "https://innovarancagua.cl/proyectos/historia-en-tus-manos/detalle"
          }
          title={"¡Vota por tu Carro favorito! #LaHistoriaEstaEnTusManos"}
          navbar={false}
          button={true}
        />

        <Typography
          sx={{
            fontSize: "1.375rem",
            fontStyle: "normal",
            marginBottom: "20px",
            fontWeight: 700,
            fontHeight: "27px"
          }}
        >
          {settings && settings.voteView
            ? "Resultados parciales"
            : "Conteo de votos parciales"}
        </Typography>
        {status === "loading" && <Loading />}
        {settings &&
          settings.voteView &&
          status === "success" &&
          participants.map((participant, index) => (
            <ListResults
              key={participant.id}
              college={participant}
              index={index}
            />
          ))}
        {settings && !settings.voteView && status === "success" && (
          <Fragment>
            <Typography
              sx={{
                fontSize: "1.375rem",
                fontStyle: "normal",
                marginBottom: "20px",
                fontWeight: 700,
                fontHeight: "27px"
              }}
            >
              Total: {numberOfVotes}
            </Typography>
            <div>
              <Typography
                variant="body1"
                sx={{
                  marginTop: "1rem",
                  lineHeight: 1.2
                }}
              >
                Tiempo restante para votar:
              </Typography>
              {/*
              <EventCountdown endDate={"18-11-2023 22:00"} />
              */}
            </div>
          </Fragment>
        )}
      </Container>
    </>
  );
}
