import React from "react";
import { Participant } from "providers/Participant";

// COMPONENTS
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

// UI
import Item from "ui/Item";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Radio } from "@mui/material";

interface ParticipantListCardProps {
  participant?: Participant;
  isSelected: boolean;
  onSelect: (participant: Participant) => void;
}

export default function PlaceCard({
  participant,
  isSelected,
  onSelect
}: ParticipantListCardProps) {
  return (
    <FormControl
      sx={{
        width: "100%"
      }}
    >
      {participant && (
        <Item
          sx={{
            marginBottom: "26px",
            paddingY: "19px",
            gap: "10px",
            borderRadius: "11px",
            borderTop: "2px solid #E6E6E6",
            borderRight: "2px solid #E6E6E6",
            borderBottom: "4px solid #E6E6E6",
            borderLeft: "2px solid #E6E6E6",
            boxShadow: "none",
            cursor: "pointer"
          }}
          onClick={() => onSelect(participant)}
        >
          <Stack direction="row" sx={{ alignItems: "center" }}>
            <img
              alt="icon"
              src={participant.coverImage}
              style={{
                width: "81px",
                height: "81px",
                objectFit: "contain",
                marginLeft: "14px"
              }}
            />
            <Stack
              direction="column"
              style={{ marginLeft: "1.5rem", width: "100%" }}
            >
              <Typography
                gutterBottom
                textAlign="left"
                sx={{
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "17.5px",
                  color: "black"
                }}
              >
                {participant.name}{" "}
              </Typography>
              <Typography
                gutterBottom
                textAlign="left"
                sx={{
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "14.5px",
                  marginBottom: "10px",
                  color: "black"
                }}
              >
                {participant.category}{" "}
              </Typography>
              {/*
              <Typography
                textAlign="left"
                sx={{
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "14.5px",
                  color: "black",
                }}
              >
                N° Votos: {participant.numberVotes}{" "}
              </Typography>
              */}
            </Stack>
            <FormControlLabel
              value={participant}
              control={<Radio checked={isSelected} color={"secondary"} />}
              label={""}
            />
          </Stack>
        </Item>
      )}
    </FormControl>
  );
}
