import { useEffect, useState } from "react";
import { useFirestore } from "reactfire";
import { doc, onSnapshot } from "firebase/firestore";

const POLL_SETTINGS_COLLECTION = "poll_settings";

const CURRENT_EVENT = "CARROS2023";
const DEFAULT_SETTINGS = {
  event: CURRENT_EVENT,
  enableVote: true,
  startDate: "2023-11-18 19:00",
  endDate: "2023-11-18 22:15",
  requireLocation: true,
  voteView: true
};

export function usePollSettingsData(event: string) {
  const firestore = useFirestore();
  const [isLoading, setIsLoading] = useState(true);
  const [settings, setSettings] = useState(DEFAULT_SETTINGS);

  useEffect(() => {
    (async function () {
      onSnapshot(
        doc(firestore, POLL_SETTINGS_COLLECTION, CURRENT_EVENT),
        (doc: any) => {
          const settingsData = doc.data();
          if (settingsData) {
            setSettings(settingsData);
          }
          setIsLoading(false);
        }
      );
    })();
  }, [event, firestore]);
  return [settings, isLoading] as const;
}
