import React, { useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import styled from "@emotion/styled";

interface CircularButtonProps {
  title: string;
  icon: string;
  color?: string;
  onClick: () => void;
}

const CircularButton: React.FC<CircularButtonProps> = ({
  title,
  icon,
  onClick,
  color = "#f5f5f5"
}) => {
  const [active, setActive] = useState(true);
  return (
    <Tooltip title={title} arrow>
      <ButtonWrapper>
        <IconButton
          onClick={() => {
            setActive(!active);
            onClick();
          }}
          style={{
            borderRadius: "50%",
            width: "56px",
            height: "56px",
            backgroundColor: active ? color : "#f5f5f5",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            color: active ? "#fff" : "#000"
          }}
        >
          {icon === "bike" && (
            <img
              src={`/assets/icons/bike${!active ? "_inactive" : ""}.svg`}
              alt="button"
            />
          )}
        </IconButton>
        <ButtonTitle>{title}</ButtonTitle>
      </ButtonWrapper>
    </Tooltip>
  );
};

export default CircularButton;
const ButtonTitle = styled.p`
  padding: 0.2rem 0;
  margin: 0;
  text-align: center;
  width: 100%;
  font-size: 1rem;
`;
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
