import Avatar from "@mui/material/Avatar";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
// Providers
import { User } from "providers/User";

// Components
import { Container } from "./styles";

const getFirstName = (fullName: string) => {
  const regex = new RegExp("^\\s*([^\\s]+)");
  const match = fullName.match(regex);
  return match ? match[1] : "";
};

export default function UserAvatar({
  user = null,
  showName = false,
  size = 60
}: {
  user?: User | null;
  showName?: boolean;
  size?: number;
}) {
  const navigate = useNavigate();
  const avatar = user?.photoURL
    ? user.photoURL
    : user?.name
    ? user?.name?.charAt(0)
    : user?.email
    ? user?.email?.charAt(0)
    : "NN";

  return (
    <Container
      onClick={() => {
        navigate("/perfil");
      }}
    >
      {user && (
        <Avatar
          src={user?.photoURL}
          sx={{
            width: "100%",
            height: "100%",
            boxShadow: "-4px -1px 0px 0px rgba(0, 0, 0, 0.25) inset"
          }}
        ></Avatar>
      )}
      {!user && (
        <Avatar
          sx={{
            width: "100%",
            height: "100%",
            boxShadow: "-4px -1px 0px 0px rgba(0, 0, 0, 0.25) inset"
          }}
        >
          NN
        </Avatar>
      )}
      <Name>{getFirstName(user?.name || "") || "iniciar"}</Name>
      {/* {showName && <Name>{user?.name || user?.email}</Name>} */}
    </Container>
  );
}

const Name = styled.div`
  display: inline-flex;
  padding: 1px 10px;
  justify-content: center;
  text-align: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  border: 2px solid #fff;
  background: #ed9e55;
  color: #fff;
  font-size: 0.6rem;
  margin-top: -18px;
  z-index: 2;
  width: 71px;
  height: 65px;
`;
