import React, { useEffect, useState, useCallback } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Sheet } from "react-modal-sheet";
import { useSearchParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import KeyboardControlKeyIcon from "@mui/icons-material/KeyboardControlKey";
import AnalyticsController from "providers/Analytics";

// Providers
import {
  useUserLocationContext,
  useUpdateUserLocationContext
} from "providers/UserLocation";
import { Place, usePlacesData, distanceBetween } from "providers/Places";
import { Coordinates } from "providers/Location";
import { useUserContext } from "providers/User";

// Containers
import PlaceContainer from "containers/Place";

// Components
import CityMap, { CityMapPoint, DEFAULT_CENTER } from "components/CityMap";
import CityMapPlace from "components/CityMapPlace";
import FloatingButton from "components/FloatingButton";
import MyPosition from "components/MyPosition";
import PlaceCard from "components/PlaceCard";

import { EVENT } from "providers/EventRoute";

// UI
import BoxList from "ui/BoxList";
import PageContainer from "ui/PageContainer";

interface CenterPosition {
  reloadUserPosition: boolean;
  dragPosition: boolean;
  coordinates: Coordinates | null;
}

const DEFAULT_CENTER_POSITION: CenterPosition = {
  reloadUserPosition: false,
  coordinates: DEFAULT_CENTER,
  dragPosition: false
};

const SELECTED_ZOOM = 20;
const SELECTED_ZOOM_2 = 16;
const DEFAULT_ZOOM = 13;

export default function HomeMapPage() {
  let [searchParams, setSearchParams] = useSearchParams();
  const [qrKey, setQrKey] = useState<string>("");
  const [showPlaces, setShowPlaces] = useState<boolean>(false);
  const [myPosition, setMyPosition] = useState<CityMapPoint | null>(null);
  const [center] = useState<CenterPosition>(DEFAULT_CENTER_POSITION);
  const [dragActive, setDragActive] = useState<boolean>(true);
  const [mapRef, setMapRef] = useState<any>(null);
  const [selectedPlace, setSelectedPlace] = useState<Place | null>(null);
  const [points, setPoints] = useState<CityMapPoint[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const user = useUserContext();
  const userLocation = useUserLocationContext();
  const userLocationPermission = useUpdateUserLocationContext();
  const [places, status] = usePlacesData();

  useEffect(() => {
    const placeKey = searchParams.get("place");
    const qr = searchParams.get("qr") || "";
    setQrKey(qr);
    if (placeKey && places) {
      const place = places.find((place: Place) => place.urlKey === placeKey);
      if (place) {
        setDragActive(true);
        if (mapRef && place) {
          mapRef.panTo(place?.location?.coordinates || null);
          mapRef.setZoom(SELECTED_ZOOM);
          setTimeout(() => {
            setSelectedPlace(place);
          }, 500);
        }
      }
    }
  }, [searchParams, places, mapRef]);
  useEffect(() => {
    if (
      mapRef &&
      userLocation.enabled &&
      userLocation.coordinates &&
      !dragActive
    ) {
      // Center userLocation when user is not dragging the map
      mapRef.panTo(userLocation?.coordinates);
    }
  }, [mapRef, userLocation, dragActive]);

  useEffect(() => {
    switch (status) {
      case "loading":
        setLoading(true);
        setPoints([]);
        break;
      case "success":
      case "distance_updated":
        const pList = places
          ? places.map((place: Place) => {
              const visited = user
                ? user?.savedMonuments?.some(
                    (vPlace: Place) =>
                      vPlace.id === place.id && vPlace.route === EVENT.event
                  )
                : false;
              return {
                content: <CityMapPlace place={{ ...place, visited }} />,
                position: place?.location?.coordinates || DEFAULT_CENTER,
                onClick: onClickPlace({ ...place, visited })
              };
            })
          : [];
        setPoints(pList);
        setLoading(false);
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, user]);

  useEffect(() => {
    // update my position point
    const myPositionPoint = userLocation.coordinates
      ? {
          content: <MyPosition coordinates={userLocation.coordinates} />,
          position: userLocation.coordinates,
          onClick: () => {}
        }
      : null;

    setMyPosition(myPositionPoint);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLocation]);

  useEffect(() => {
    let dragListener: any = null;
    if (mapRef) {
      dragListener = mapRef.addListener("dragstart", () => {
        setDragActive(true);
      });
    }

    return () => {
      dragListener && dragListener.remove();
    };
  }, [mapRef]);

  const onMapInit = (map: any) => {
    if (map) map.setOptions({ gestureHandling: "greedy" });
    setMapRef(map);
  };

  const onClickCenter = useCallback(() => {
    userLocationPermission(true);
    if (mapRef && userLocation?.coordinates) {
      setDragActive(false);
      mapRef.panTo(userLocation.coordinates);

      const userCoordinates = userLocation?.coordinates || null;

      const selectedPlace = places.find((place: Place) => {
        const placeCoordinates = place?.location?.coordinates || null;
        if (userCoordinates && placeCoordinates) {
          const distance = distanceBetween(userCoordinates, placeCoordinates);
          return distance <= 50;
        }
        return false;
      });

      const zoom = selectedPlace
        ? SELECTED_ZOOM
        : places.some((place: Place) => {
            const placeCoordinates = place?.location?.coordinates || null;
            if (userCoordinates && placeCoordinates) {
              const distance = distanceBetween(
                userCoordinates,
                placeCoordinates
              );
              return distance <= 300;
            }
            return false;
          })
        ? SELECTED_ZOOM_2
        : DEFAULT_ZOOM;

      mapRef.setZoom(zoom);
    }
  }, [mapRef, userLocation, places]);

  const onClickPlace = useCallback((place: Place) => {
    return (map: any) => {
      setDragActive(true);
      AnalyticsController.trackButtonClicked("place_map_click");
      if (map && place) {
        map.panTo(place?.location?.coordinates || null);
        map.setZoom(SELECTED_ZOOM);
        setTimeout(() => {
          setSelectedPlace(place);
        }, 500);
      }
    };
  }, []);

  const countVisited = user?.savedMonuments?.filter(
    (place: Place) => place.route === EVENT.event
  ).length;
  const pendingVisitCount = places.length - (countVisited || 0);
  return (
    <PageContainer loading={loading || !mapRef} container={false}>
      <Grid spacing={2}>
        <Grid xs={12}>
          {/* MAP */}
          {!loading && (
            <CityMap
              points={myPosition ? points.concat(myPosition) : points}
              center={center.coordinates || undefined}
              zoom={undefined}
              onInit={onMapInit}
            />
          )}
          {/* CENTER BUTTON */}
          {!loading && mapRef && dragActive && (
            <FloatingButton onClick={() => {
              AnalyticsController.trackButtonClicked("center_map_button_click");
              onClickCenter();
            }} />
          )}
          {mapRef && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: 2,
                flexWrap: "wrap",
                position: "fixed",
                bottom: 0,
                width: "100%",
                left: 0,
                background: "#337ab7",
                height: "8vh"
              }}
            >
              <Button
                variant="text"
                sx={{
                  width: "100%",
                  fontSize: "1rem",
                  padding: "0.5rem 0",
                  color: "white"
                }}
                endIcon={<KeyboardControlKeyIcon />}
                onClick={() => {
                  setShowPlaces(true);
                }}
              >
                {pendingVisitCount > 0
                  ? `${pendingVisitCount} lugares por descubrir`
                  : !loading && "¡Descubriste todos los lugares!"}
              </Button>
            </Box>
          )}
          <Sheet
            isOpen={showPlaces}
            onClose={() => {
              setShowPlaces(false);
            }}
            /*  springConfig={{ stiffness: 150, damping: 20, mass: 1 }} */
          >
            <Sheet.Container>
              <Sheet.Header />
              <Sheet.Content>
                <BoxList>
                  <Stack direction="column" sx={{ padding: "0 0.5rem" }}>
                    <h2 style={{ margin: 0, padding: 0, color: "#337ab7" }}>
                      Recorre nuestra historia y patrimonio
                    </h2>
                    <p>
                      Te invitamos a recorrer nuestro pasado, en la ruta que
                      encontrarás en este pasaporte, que cuenta con 15 hitos de
                      interés patrimonial, en donde podrás conocer la ciudad y
                      empaparte de la memoria colectiva local.
                    </p>
                    {/*  <Button
                      variant="contained"
                      color="warning"
                      startIcon={<PlaceIcon />}
                      sx={{ margin: "1rem 0" }}
                    >
                      Activar ubicación
                    </Button> */}
                  </Stack>

                  <Stack direction="column" sx={{ padding: "0 0.5rem" }}>
                    <Box sx={{ width: "100%" }}>
                      <Stack spacing={2}>
                        {places.map((place, key) => {
                          const visited = user
                            ? user?.savedMonuments?.some(
                                (vPlace: Place) =>
                                  vPlace.id === place.id &&
                                  vPlace.route === EVENT.event
                              )
                            : false;
                          return (
                            <PlaceCard
                              key={key}
                              place={{ ...place, visited }}
                              onClick={() => {
                                setShowPlaces(false);
                                if (mapRef) {
                                  mapRef.panTo(
                                    place?.location?.coordinates || null
                                  );
                                  mapRef.setZoom(SELECTED_ZOOM);
                                }
                              }}
                            />
                          );
                        })}
                      </Stack>
                    </Box>
                  </Stack>
                </BoxList>
              </Sheet.Content>
            </Sheet.Container>
            <Sheet.Backdrop />
          </Sheet>

          {/* PLACE SHEET */}
          <Sheet
            isOpen={selectedPlace !== null}
            onClose={() => {
              setSelectedPlace(null);
              setSearchParams({ place: "" });
            }}
            /* springConfig={{ stiffness: 150, damping: 20, mass: 1 }} */
            style={{ zIndex: 999 }}
          >
            <Sheet.Container>
              <Sheet.Header />
              <Sheet.Content>
                <PageContainer title={selectedPlace?.name}>
                  <BoxList style={{ padding: 0 }}>
                    {/* {selectedPlace && (
                      <PlaceDetail place={selectedPlace} qrKey={qrKey} />
                    )} */}
                    {selectedPlace && (
                      <PlaceContainer place={selectedPlace} qrKey={qrKey} />
                    )}
                  </BoxList>
                </PageContainer>
              </Sheet.Content>
            </Sheet.Container>
            <Sheet.Backdrop />
          </Sheet>
        </Grid>
      </Grid>

      {/*   <Stepper /> */}
    </PageContainer>
  );
}
