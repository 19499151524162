import styled from "@emotion/styled";

const Description = styled.p`
  font-size: 0.8rem;
  padding: 0.5rem 0;
  margin: 0;
  text-align: center;
  color: #868686;
`;
export default Description;
