import React, { useState } from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import Icon from "@mui/material/Icon";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

// Components
import UserAvatar from "components/UserAvatar";
import FloatingBackButton from "components/FloatingBackButton";

// UI
import RouteLogo from "ui/RouteLogo";

interface MapHeaderProps {
  logo: string;
  user?: any;
}

export default function MapHeader({ logo, user = null }: MapHeaderProps) {
  const navigate = useNavigate();
  return (
    <Container>
      <FloatingBackButton onClick={() => navigate("/")} />
      <LogoWrapper>
        <RouteLogo logo={logo} />
      </LogoWrapper>
      <AvatarWrapper>
        <UserAvatar user={user} size={36} />
      </AvatarWrapper>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  padding: 12px 8px;
  display: flex;
`;

const LogoWrapper = styled.div`
  margin-left: 18px;
`;

const AvatarWrapper = styled.div`
  position: absolute;
  right: 16px;
`;
