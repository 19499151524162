import React, { useState, useRef, useEffect } from "react";
import {
  Outlet,
  useNavigate,
  useSearchParams,
  useLocation
} from "react-router-dom";

// MUI
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import EventIcon from "@mui/icons-material/Event";
import RouteIcon from "@mui/icons-material/Route";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import BoxList from "ui/BoxList";

// Providers
import { useUserContext } from "providers/User";

// Components
import TopBar from "components/TopBar";

const TAB_PATHS = ["/", "/rutas", "/actividades", "/mi-pasaporte"]; // TODO: move to config
const EMBED_PATHS = [
  "lugar",
  "location-check",
  "voting",
  "location-check",
  "voting-check",
  "voting-view",
  "votacion",
  "qr-check",
  "resultados",
  "incognito"
]; // TODO: move to config

const darkTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#0288d1"
    },
    secondary: {
      main: "#ed6c02",
      light: "rgb(231, 148, 126)",
      dark: "rgb(157, 85, 65)",
      contrastText: "#fafafa"
    },
    info: {
      main: "#3c405b"
    },
    warning: {
      main: "#e17a5e",
      contrastText: "white"
    }
  },
  typography: {
    fontFamily: "Poppins"
  }
});

function Layout() {
  const location = useLocation();
  const navigate = useNavigate();
  const user = useUserContext();
  const [activeTab, setActiveTab] = useState<number>(
    TAB_PATHS.indexOf(location.pathname) !== -1
      ? TAB_PATHS.indexOf(location.pathname)
      : 0
  );
  /* const [value, setValue] = useState(activeTab !== -1 ? activeTab : 0); */
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    TAB_PATHS[newValue] && navigate(TAB_PATHS[newValue]);
    setActiveTab(newValue);
  };
  let [searchParams] = useSearchParams();
  const ref = React.useRef<HTMLDivElement>(null);
  const path = location.pathname.split("/");
  const embed: boolean =
    searchParams.get("embed") === "true" ||
    (path[1] !== "" && EMBED_PATHS.includes(path[1]));

  useEffect(() => {
    if (location) {
      const tab = TAB_PATHS.indexOf(location.pathname);
      setActiveTab(tab !== -1 ? tab : 0);
    }
  }, [location]);

  React.useEffect(() => {
    (ref.current as HTMLDivElement).ownerDocument.body.scrollTop = 0;
  }, [activeTab]);
  const showTabs: boolean =
    ["/perfil", "/scan", "/summary", "/raffle"].indexOf(location.pathname) ===
    -1;
  const regex = /\/map$|\/actividad\/[^\/]+$/;
  const showTopBar: boolean = regex.test(location.pathname) ? false : true;
  const savedPlaces: number = user?.savedMonuments?.length || 0;
  return (
    <div className="App">
      <Box sx={{ pb: 0 }} ref={ref}>
        <ThemeProvider theme={darkTheme}>
          <CssBaseline />
          {showTopBar && <TopBar />}
          <div>
            <Outlet />
          </div>
        </ThemeProvider>
      </Box>
    </div>
  );
}

export default Layout;

interface StyledTabProps {
  label: any;
}
const AntTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }: { theme: any }) => ({
  textTransform: "none",

  "&:hover": {
    opacity: 1
  },
  "&.Mui-selected": {
    fontWeight: theme.typography.fontWeightMedium
  },
  "&.Mui-focusVisible": {}
}));

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}
