import React from "react";
import moment from "moment";
import "moment/locale/es";

// COMPONENTS
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useLocation } from "react-router-dom";


export default function Index({ date }: any) {
  const location = useLocation();
  const dateFrom = date.dateFrom.seconds * 1000;
  const dateTo = date.dateTo.seconds * 1000;
  const newDateFrom = moment(dateFrom).locale("es").format("D [de] MMMM");
  const newDateTo = moment(dateTo).locale("es").format("D [de] MMMM");

  const path = location.pathname;


  return (
    <Stack>
            <Typography sx={{ marginBottom: path == "/" ? "" : "1vh", fontSize: path == "/" ? "0.875rem" : "16px", fontWeight: path === "/" ? "normal" : "bold" }}>

        {newDateFrom}
      </Typography>
    </Stack>
  );
}
