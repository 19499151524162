import React, { useState } from "react";
import styled from "@emotion/styled";
import Grid from "@mui/material/Grid";

import ProgressIndication from "components/ProgressIndication";

import Description from "ui/Description";
import Subtitle from "ui/Subtitle";
import GiftIcon from "ui/GiftIcon";
/* 
interface AwardsInformationProps {
  icon: React.ReactNode;
  progressColor?: string;
  percentage?: number;
  onClick?: () => void;
} */

export default function AwardsInformation({
  user = null,
  places = []
}: {
  user?: any;
  places?: any[];
}) {
  const savedMonuments = user?.savedMonuments || [];
  return (
    <Container>
      <Grid
        container
        style={{
          padding: "0 16px",
          width: "100%"
        }}
      >
        <Grid xs={12}>
          <ProgressWrapper>
            <ProgressIndication
              icon={<GiftIcon />}
              progressColor="#3C47B5"
              total={10}
              current={savedMonuments.length}
              percentage={90}
              onClick={() => {}}
            />
          </ProgressWrapper>

          {savedMonuments.length >= 10 && (
            <div>
              <Subtitle>¡Felicidades!</Subtitle>
              <Description style={{ textAlign: "left" }}>
                ¡Has completado el desafío! Ahora estás participando en el
                sorteo de un viaje a Sewell. ¡Mucha suerte!
              </Description>
              <Description style={{ textAlign: "left" }}>
                Más información en nuestras Redes Sociales{" "}
                <a
                  href="https://www.instagram.com/desarrollo_innovacion/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  @innovarancagua
                </a>
              </Description>
            </div>
          )}
          {savedMonuments.length < 10 && (
            <div>
              <Description style={{ textAlign: "left" }}>
                ¿Te animas a descubrir Rancagua? Participa en nuestro desafío y
                visita
                <b> 10 puntos del recorrido</b>. Al completarlo, podrás entrar
                en un sorteo para ganar un increíble{" "}
                <b>viaje a la histórica ciudad minera de Sewell</b>, Patrimonio
                de la Humanidad . Imagina explorar esta fascinante ciudad junto
                a un acompañante, sumergiéndote en su rica historia y paisajes
                únicos.
              </Description>
              <Description style={{ textAlign: "left" }}>
                ¡No te pierdas esta oportunidad única de vivir una experiencia
                inolvidable mientras descubres los tesoros de Rancagua!
              </Description>
            </div>
          )}
        </Grid>
      </Grid>

      {/*  <Subtitle>
        ¡Recorre y Participa en el Sorteo de un Viaje a Sewell!
      </Subtitle> */}
    </Container>
  );
}

const Container = styled.div`
  padding-bottom: 4rem;
`;

const ProgressWrapper = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
`;
