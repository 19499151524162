import styled from "@emotion/styled";
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 65px;
  height: 65px;
  border-radius: 32.5px;
  border: 2px solid #fff;
  background: #d9d9d9;
  box-shadow: -4px -1px 0px 0px rgba(0, 0, 0, 0.25) inset;
  /*   padding: 1rem; */
`;

export const Name = styled.p`
  text-align: center;
`;
