export interface Route {
  urlKey: string;
  name: string;
  description: string;
  icon: string;
  map: string;
}

const ROUTES_ICONS = [
  "https://firebasestorage.googleapis.com/v0/b/city-tour-84058.appspot.com/o/icons%2Fautoguiadas%2Fmap_icon_1.png?alt=media&token=b2d19e18-964a-48d9-b372-01795a8c5107",
  "https://firebasestorage.googleapis.com/v0/b/city-tour-84058.appspot.com/o/icons%2Fautoguiadas%2Fmap_icon_2.png?alt=media&token=c2c1c728-b12a-49ed-b5ce-a2bad625738c",
  "https://firebasestorage.googleapis.com/v0/b/city-tour-84058.appspot.com/o/icons%2Fautoguiadas%2Fmap_icon_3.png?alt=media&token=d325140e-ea9b-4d96-938e-f529574df9b0",
];

export const getRoutes = () => {
  try {
    const routes: Route[] = [
      {
        urlKey: "paseo-historias-mineras-y-ferroviarias",
        name: "Paseo historias Mineras y Ferroviarias",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        icon: ROUTES_ICONS[0], // "/assets/stepper/icon_pin.png",
        map: "https://www.google.com/maps/d/embed?mid=1mx2jTsK6VgGa46l1V9TBfe4FIF17Qsc&hl=es&ehbc=2E312F",
      },
      {
        urlKey: "un-paseo-por-la-vida-y-obra-de-oscar-castro",
        name: "Un Paseo por la vida y obra de Oscar Castro",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        icon: ROUTES_ICONS[1], //"/assets/stepper/icon_pin.png",
        map: "https://www.google.com/maps/d/embed?mid=1Eyy_GIZUS22ig55xX3urpM4BHA_c3MI&hl=es&ehbc=2E312F",
      },
      {
        urlKey: "tradiciones-huasas-futbol-personajes-tipicos-y-picadas",
        name: "Tradiciones huasas, fútbol, personajes típicos y picadas",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        icon: ROUTES_ICONS[2], // "/assets/stepper/icon_pin.png",
        map: "https://www.google.com/maps/d/embed?mid=1P0leFyjp8AmcdW8LMMKzwk2tAskYR-o&hl=es&ehbc=2E312F",
      },
      {
        urlKey: "vestigios-de-una-batalla",
        name: "Vestigios de una Batalla",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        icon: ROUTES_ICONS[0], //"/assets/stepper/icon_pin.png",
        map: "https://www.google.com/maps/d/embed?mid=1Xp_KrqzpkFF1LR-AlzN_T-ypsKyBy94&hl=es&ehbc=2E312F",
      },
      {
        urlKey: "paseo-fotografico-por-el-patrimonio-de-la-calle-del-estado",
        name: "Paseo fotográfico por el patrimonio de la calle del Estado",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        icon: ROUTES_ICONS[1], //"/assets/stepper/icon_pin.png",
        map: "https://www.google.com/maps/d/embed?mid=1bjas3GmZDs9hNVuxlheN8_SAEFL6IrQ&hl=es&ehbc=2E312F",
      },
      {
        urlKey: "con-tito-lastarria-24",
        name: "Paseo fotográfico por el patrimonio de la calle del Estado",
        description:
        "Soy Tito Lastarria, Guardián del Patrimonio de Rancagua... ¡Acompáñame a recorrer nuestra ciudad histórica, yo seré tu guía!  Junto a mi, conocerás 10 hitos patrimoniales: desde la emblemática Casa de la Cultura, el antiguo Cine Apolo e inigualable Teatro San Martín, el campanario de la Iglesia de La Merced, hasta el importante Barrio Trenova, y por supuesto, el Cementerio N°1, dónde está mi mausoleo. Acércate a los puntos señalados en el mapa y así podrás visualizar los videos con historias y anécdotas que tengo preparadas para ti. ¡Ven a descubrir los secretos de Rancagua!",
        icon: "/assets/img/tito-banner-1.png",
        map: "https://www.google.com/maps/d/embed?mid=1bjas3GmZDs9hNVuxlheN8_SAEFL6IrQ&hl=es&ehbc=2E312F",
      },
    ];
    return routes;
  } catch (error) {
    return [];
  }
};
