import { Place } from "providers/Places";
export const formatAddress = (place: Place) => {
  if (!place || !place.location || !place.location.address) {
    return "";
  }

  const address = place.location.address;
  const streetName = address.streetName || "";
  const streetNumber = address.streetNumber || "";
  const city = address.city || "";

  return `${streetName} ${streetNumber}, ${city}`;
};
