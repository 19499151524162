import React, { useEffect, useState, useCallback } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
// Providers
import {
  useUserLocationContext,
  useUpdateUserLocationContext
} from "providers/UserLocation";
import { Place, usePlacesData, distanceBetween } from "providers/Places";
import { useUserContext } from "providers/User";
import AnalyticsController from "providers/Analytics";

// Containers

// Components

import Title from "ui/Title";

// UI
import { BackgroundImage, Overlay } from "./styles";
import PageContainer from "ui/PageContainer";
import PlaceCard from "components/PlaceCard";
import { Typography } from "@mui/material";
import SearchBar from "components/SearchBar";
import ActivityCarousel from "components/ActivityCarousel";
import Sections from "components/Sections";
import CardContainer from "components/CardContainer";
import CardRoute from "components/CardRoute";
import Footer from "components/Footer";
import HistoricalDocuments from "components/HistoricalDocuments";
import ActivityListCard from "components/ActivityListCard";
import ActivityCard from "components/ActivityCard";
import { Link } from "react-router-dom";
import { ActionButton } from "ui/ActionButton";

export default function HomePage() {
  const [loading, setLoading] = useState<boolean>(false); // TODO: Implement loading
  const user = useUserContext();
  const userLocation = useUserLocationContext();
  const userLocationPermission = useUpdateUserLocationContext();
  const [places, status] = usePlacesData();
  const navigate = useNavigate();

  return (
    <PageContainer loading={loading} container={false}>
      <BackgroundCover
        onClick={() => {} /* navigate("/rutas/con-tito-lastarria-24") */}
        style={{ backgroundImage: 'url("assets/img/tito-banner-2.png")' }}
      >
        <div
          style={{
            position: "absolute",
            bottom: 0,
            right: "16px",
            width: "200px"
          }}
        >
          <ActionButton
            style={{
              width: "100%",
              marginTop: "0",
              backgroundColor: "#663399",
              color: "#fff"
            }}
            onClick={() => {
              AnalyticsController.trackButtonClicked(
                "Ver_Videos_Tito_Lastarria"
              );
              navigate("/videos");
              /*  const pdfUrl =
                "https://firebasestorage.googleapis.com/v0/b/city-tour-84058.appspot.com/o/2024%2FPROG-RANCAGUA.pdf?alt=media&token=0c85086a-ff8d-414e-9546-bbb50d252b57";
              window.open(pdfUrl, "_blank");
              AnalyticsController.trackButtonClicked("Ver_Programa_Rancagua"); */
            }}
          >
            VER VIDEOS
          </ActionButton>
          <ActionButton
            style={{ width: "100%", marginTop: "0" }}
            onClick={() => {
              AnalyticsController.trackButtonClicked("Ver_Ruta_Tito_Lastarria");
              navigate("/rutas/con-tito-lastarria-24");
            }}
          >
            VER RUTA
          </ActionButton>
        </div>
      </BackgroundCover>

      <Grid spacing={2}>
        <Grid xs={12} md={4}>
          <Title
            style={{
              color: "black",
              zIndex: 1,
              margin: "16px"
              /*   position: "absolute",
              bottom: 0 */
            }}
          >
            Sumérgete en la historia, cultura y patrimonio de Rancagua.
          </Title>
          <Typography
            variant="h2"
            align="left"
            sx={{
              color: "black",
              ml: "16px",
              mr: "16px",
              mt: "11px",
              fontSize: "1rem"
            }}
          >
            Descubre sus encantos ocultos, desde sitios históricos, hasta puntos
            de interés patrimonial, cultural y gastronómico. Explora nuestras
            rutas temáticas, sus barrios, ciclovías, plazas, y disfruta de
            eventos locales. Navega por nuestra plataforma para vivir una
            experiencia inolvidable en el corazón de Chile y ven a descubrir
            esta ciudad con su riqueza histórica, cultural, minera, natural, y
            sus tradiciones campesinas del mundo rural.
          </Typography>
        </Grid>
        {/* Floating button 
        <Box
          sx={{
            position: "fixed",
            bottom: "50px",
            left: "50%",
            transform: "translateX(-50%)",
            display: "flex",
            zIndex: 9999,
            justifyContent: "center",
          }}
    
        >
          <a href="/rutas/con-tito-lastarria-24/map" style={{ textDecoration: 'none' }}>
            <Button
              sx={{
                padding: "5px 50px",
                color: "#000000",
                borderColor: "#F7D765",
                backgroundColor: "#F7D765",
                borderWidth: "2px",
                borderStyle: "solid",
                borderRadius: "10px",
                display: "flex",
                alignItems: "center",
                gap: "20px",
                pointerEvents: "none",
                textTransform: "none",
                fontWeight: "bold",
                fontSize: "16px"
              }}
            >
              <img
                src="assets/icons/map.svg"
                alt="icon"
                style={{ width: "24px", height: "24px" }}
              />
              Mapa
            </Button>
          </a>
        </Box>
        */}

        <Box
          sx={{
            backgroundColor: "#F5F5F5",
            marginTop: "40px",
            paddingBottom: "70px"
          }}
        >
          <Grid xs={12}>
            <Sections title="Próximas Actividades" description="" />
          </Grid>
          <Grid xs={12}>
            <Box sx={{ marginBottom: "14px" }}>
              <ActivityCard />
            </Box>
            <Link
              to="/actividades"
              style={{ color: "black" }}
              onClick={() => {
                AnalyticsController.trackButtonClicked("Ver_Todas_Actividades");
              }}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "14px",
                  textAlign: "right",
                  paddingRight: "16px"
                }}
              >
                Ver todos
              </Typography>
            </Link>
          </Grid>
        </Box>
        <Grid xs={12}>
          <Sections title="Rutas" description="" />
        </Grid>
        <Grid
          container
          spacing={2}
          sx={{
            justifyContent: "center",
            width: "100%",
            margin: 0,
            padding: "8px"
          }}
        >
          <Grid xs={12} sm={6} md={3}>
            <CardRoute
              id="con-tito-lastarria-24"
              title="Descubre Rancagua con Tito Lastarria"
              subtitle="Guardián del Patrimonio"
              image="assets/routes/patrimoniales.svg"
            />
          </Grid>
          {/* 
          <Grid xs={12} sm={6} md={3}>
            <CardRoute
              id="paseo-historias-mineras-y-ferroviarias"
              title="Nombre Ruta"
              subtitle="5 Recorridos"
              image="assets/routes/culturales.svg"
            />
          </Grid>
          <Grid xs={12} sm={6} md={3}>
            <CardRoute
              id="3"
              title="Nombre Ruta"
              subtitle="3 Recorridos"
              image="assets/routes/tradiciones.svg"
            />
          </Grid>
          <Grid xs={12} sm={6} md={3}>
            <CardRoute
              id="4"
              title="Nombre Ruta"
              subtitle="4 Recorridos"
              image="assets/routes/patrimoniales.svg"
            />
          </Grid>
          */}
        </Grid>
        {/* 
        <HistoricalDocuments />
        */}

        {/* 
        <Grid xs={12} sx={{ marginBottom: "45px" }}>
          <Box sx={{ marginBottom: "-20px" }}>
            <Sections title="Experiencias" description="" />
          </Box>
          <ActivityCarousel tipo="Experience" />
        </Grid>
        */}
        <Footer />
      </Grid>
    </PageContainer>
  );
}

const BackgroundCover = styled.div`
  width: 100%;
  height: 60vh;
  position: relative;
  background-color: rgb(227 82 139); /* Color de fondo debajo de la imagen */
  background-image: url("/path/to/your/image.jpg"); /* Ruta de la imagen de fondo */
  background-position: bottom center; /* Centra la imagen de fondo */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
  background-size: contain; /* Ajusta el tamaño de la imagen para cubrir todo el div */
`;
