import React, { useState } from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import UserAvatar from "components/UserAvatar";
import Icon from "@mui/material/Icon";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

interface MapButtonProps {
  icon: React.ReactNode;
  backgroundColor?: string;
  boxShadow?: string;
  onClick?: () => void;
}

export default function MapButton({
  icon,
  backgroundColor = "#f7d765",
  boxShadow = "none",
  onClick = () => {}
}: MapButtonProps) {
  return (
    <Button
      onClick={onClick}
      style={{ background: backgroundColor, boxShadow }}
    >
      {icon}
    </Button>
  );
}

const Button = styled.div`
  border-radius: 36px;
  border: 2px solid #fff;
  background: #8bdb5a;
  display: flex;
  width: 42px;
  height: 42px;
  padding: 6px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  flex-shrink: 0;
  color: #fff;
`;
