import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocalStorage } from "usehooks-ts";
import {
  collection,
  doc,
  getDocs,
  query,
  serverTimestamp,
  setDoc,
  where
} from "firebase/firestore";
import { useAuth, useFirestore, useUser } from "reactfire";
import { Session, SESSION_KEY } from "./Session";
import UserLocation from "providers/UserLocation";
import { EVENT } from "providers/EventRoute";
export interface User {
  name: string;
  email: string;
  uid: string;
  phoneNumber?: string;
  photoURL?: string;
  providerId?: string;
  score: number;
  scoreAlias?: string;
  ranking: number;
  rankingAlias?: string;
  savedMonuments?: any[];
  isAnonymous?: boolean;
}

const DEFAULT_SESSION = {
  user: null,
  createdAt: new Date(),
  expireAt: 0
};

const userContext = createContext<User | null>(null);
const userUpdateContext = createContext<any>(null);

// User Context Hook
export function useUserContext() {
  return useContext(userContext);
}

export function useUpdateUserContext() {
  return useContext(userUpdateContext);
}

type uProviderProps = {
  children: JSX.Element;
};

export function UserProvider({ children }: uProviderProps) {
  const firestore = useFirestore();
  const auth = useAuth();
  const { status, data } = useUser();
  // Data from storage
  const [session, setSession] = useLocalStorage<Session>(
    SESSION_KEY,
    DEFAULT_SESSION
  );

  // Init internal states
  const [user, setUser] = useState<User | null>(session.user);

  const logout = async () => {
    await auth.signOut();
    setSession({
      user: null,
      updatedAt: new Date(),
      createdAt: new Date(),
      expireAt: 0
    });

    setUser(null);
    window.location.reload();
  };

  const updateUser = (data: User | null): void => {
    if (!data) {
      logout();
    }

    // TODO: Add validation rules
    const newUserData = user && data ? { ...user, ...data } : data;

    // Update in session context
    setSession({
      user: newUserData,
      updatedAt: new Date(),
      createdAt: session.user ? session.createdAt : new Date(),
      expireAt: 0
    });

    setUser(newUserData);

    // TODO: Sync monuments with firestore
    if (
      data?.savedMonuments &&
      data?.savedMonuments.filter((place) => place.route === EVENT.event)
        .length > 0
    )
      (async () => {
        data?.savedMonuments?.map(async (place: any) => {
          const docRef = doc(
            firestore,
            "place_visited",
            `${place.route}-${data.uid}-${place.id}` // composite key
          );
          const result = await setDoc(
            docRef,
            {
              userId: data.uid,
              email: data?.email || "",
              phoneNumber: data?.phoneNumber || "",
              placeId: place.id,
              place,
              event: EVENT,
              year: 2024,
              route: place.route || "",
              created: serverTimestamp()
            },
            { merge: true }
          );
        });
        // TODO: add status loading in response (return as promise)
        // TODO: refresh places list (in places provider) wen user is updated
      })();
  };

  useEffect(() => {
    if (!user && status === "success" && data) {
      try {
        const { displayName, email, uid, photoURL, providerId, phoneNumber } =
          data;
        const newUser: User = {
          name: displayName || "",
          email: email || "",
          uid: uid || "",
          phoneNumber: phoneNumber || "",
          photoURL: photoURL || "",
          providerId: providerId || "",
          score: 0,
          ranking: 0,
          savedMonuments: []
        };

        const getUserSavedMonuments = async () => {
          const monumentsQuery = query(
            collection(firestore, "place_visited"),
            where("userId", "==", newUser.uid),
            where("route", "==", EVENT.event) // TODO: parametrize route
          );

          const interestPlaces = await getDocs(monumentsQuery);

          newUser.savedMonuments = interestPlaces.docs.map((doc) => {
            return { ...doc.data().place };
          });
          updateUser(newUser);
        };

        getUserSavedMonuments();
      } catch (error) {}
    } else if (status === "success" && data && user) {
      (async () => {
        const monumentsQuery = query(
          collection(firestore, "place_visited"),
          where("userId", "==", user.uid),
          where("route", "==", EVENT.event)
        );

        const interestPlaces = await getDocs(monumentsQuery);

        const savedMonuments = interestPlaces.docs.map(
          (doc) => doc.data().place
        );

        if (
          savedMonuments.length > 0 &&
          user?.savedMonuments?.length !== savedMonuments.length
        ) {
          const userData = { ...user, savedMonuments };
          updateUser(userData);
        }
      })();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, data, user]);

  return (
    <div>
      <userContext.Provider value={user}>
        <userUpdateContext.Provider value={updateUser}>
          <UserLocation>{children}</UserLocation>
        </userUpdateContext.Provider>
      </userContext.Provider>
    </div>
  );
}
