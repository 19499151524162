/** @jsxImportSource @emotion/react */
import React from 'react';
import styled from '@emotion/styled';

const SectionContainer = styled.section`
  padding: 20px;
`;

const Title = styled.h2`
  font-size: 24px;
  margin-bottom: 10px;
`;

const Description = styled.p`
  font-size: 14px;
  color: #666;
`;


interface SectionProps {
    title: string;
    description: string;
  }
  
const Sections = ({ title, description }: SectionProps) => {
    return (
        <SectionContainer>
            <Title>{title}</Title>
            {description && <Description>{description}</Description>}
        </SectionContainer>
    );
};

export default Sections;