import React, { Fragment, useEffect, useState } from "react";
import { useFirestore } from "reactfire";
import { collection, getDocs } from "firebase/firestore";

// MUI
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Loading from "../../components/Loading";

// UI
import PageContainer from "ui/PageContainer";

//Hooks
import { usePollChecker } from "hooks/usePollChecker";
import EventCountdown from "../../components/EventCountdown";
import { useSummaryData } from "hooks/useSummaryData";

export const POLL_SUMMARY_COLLECTION = "poll_summary";

export default function PollSummaryPage() {
  const [loading, setLoading] = useState(true);
  const [votes, setVotes] = useState<any[]>([]);
  const { numberOfVotes, settings } = usePollChecker();
  const [data, status] = useSummaryData();
  useEffect(() => {
    if (status === "success") {
      const list = data.sort((a: any, b: any) => b.count - a.count);
      setVotes(list);
      setLoading(false);
    }
  }, [data, status]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <img
        style={{
          width: "100%",
          marginTop: "0.5rem"
        }}
        src="/assets/banderines.svg"
        alt="banderines"
      />
      <PageContainer>
        <Typography
          sx={{
            padding: "0 1rem",
            fontSize: "1.875rem",
            fontWeight: 700,
            lineHeight: "2.0625rem",
            marginTop: "1rem",
            textAlign: "center"
          }}
        >
          Resultados de la Votación
        </Typography>
        <Typography
          sx={{
            padding: "1rem",
            fontSize: "1rem",
            fontWeight: 700,
            lineHeight: "2.0625rem",
            marginTop: "1rem",
            textAlign: "center"
          }}
        >
          Total de votos: {numberOfVotes}
        </Typography>
        <Typography
          sx={{
            /* padding: "1rem", */
            fontSize: ".8rem",
            fontWeight: 500,
            /* lineHeight: "2.0625rem", */
            /* marginTop: "1rem", */
            textAlign: "center"
          }}
        >
          Sábado 18 de Noviembre de 2023
        </Typography>
        <Typography
          sx={{
            padding: "1rem",
            fontSize: "1rem",
            fontWeight: 500,
            lineHeight: "2.0625rem",
            marginTop: "1rem",
            textAlign: "center"
          }}
        >
          La Historia está en Tus Manos
        </Typography>
        {settings && settings.voteView ? (
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead style={{ background: "#FCDA52" }}>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>#</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Carro</TableCell>
                  <TableCell style={{ fontWeight: "bold" }} align="left">
                    Votos
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {votes.map((vote, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{vote.name}</TableCell>
                    <TableCell align="left">{vote.count}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Fragment>
            <Typography
              sx={{
                padding: "1rem",
                fontSize: "1rem",
                fontWeight: 700,
                lineHeight: "2.0625rem",
                marginTop: "1rem",
                textAlign: "center"
              }}
            >
              Total de votos: {numberOfVotes}
            </Typography>
            <div>
              <Typography
                variant="body1"
                sx={{
                  marginTop: "1rem",
                  lineHeight: 1.2
                }}
              >
                Tiempo restante para votar:
              </Typography>
              {/*
              <EventCountdown endDate={"18-11-2023 22:15"} />
              */}
            </div>
          </Fragment>
        )}
      </PageContainer>
    </div>
  );
}
