import React, { useState } from "react";
import styled from "@emotion/styled";
import Grid from "@mui/material/Grid";

import PlacesList from "components/PlacesList";
import ProgressIndication from "components/ProgressIndication";

import WalkCircleIcon from "ui/WalkCircleIcon";
import Description from "ui/Description";

export default function MapInformation({
  user = null,
  places = []
}: {
  user?: any;
  places?: any[];
}) {
  const savedMonuments = user?.savedMonuments || [];
  return (
    <Container>
      <Grid
        container
        style={{
          padding: "0 16px",
          width: "100%"
        }}
      >
        <Grid xs={12}>
          <ProgressWrapper>
            <ProgressIndication
              icon={<WalkCircleIcon />}
              current={savedMonuments.length}
              total={places.length}
              onClick={() => {
                /*    setActiveMenu({
                  name: "map",
                  title: "Recorrido",
                  size: 600,
                  description: ""
                }); */
              }}
            />
          </ProgressWrapper>
          <Description style={{ marginBottom: "0.5rem" }}>
            Lugares visitados de la ruta
          </Description>
        </Grid>
      </Grid>
      <PlacesList />
    </Container>
  );
}

const Container = styled.div`
  padding-bottom: 4rem;
`;

const ProgressWrapper = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
`;
