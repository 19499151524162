import React from "react";
import { usePlacesData } from "providers/Places";
import YouTubeEmbed from "components/YoutubeEmbed";
import { Grid, Paper, Typography, CardMedia, Box } from "@mui/material";
import PageContainer from "ui/PageContainer";
import Title from "ui/Title";



export default function VideoList() {
    const [places, status] = usePlacesData();
      const placesWithVideos = places.filter(place => place.video && place.video.id);

    return (
        <PageContainer loading={status === "loading"} container={false}>
        <Paper elevation={0}>
        <CardMedia
            sx={{
              width: "100%",
              height: "auto",
              minHeight: "100%",
              objectFit: "contain",
              marginTop: "1rem"
            }}
            component="img"
            image={"/assets/img/tito-banner-1.png"}
            alt="Videos"
          />
            <Box sx={{ padding: "1rem" }}>
            <Title
                style={{
                color: "black",
                zIndex: 1,
                }}
            >
                DESCUBRE RANCAGUA CON TITO LASTARRIA
            </Title>
            <Typography
            variant="h2"
            align="left"
            sx={{
              color: "black",
              mt: "11px",
              fontSize: "1rem"
            }}
          >
            A través de estos videos, conocerás 10 puntos patrimoniales de nuestra comuna, 
            desde la emblemática Casa de la Cultura, el antiguo Cine Apolo e inigualable 
            Teatro San Martín, hasta el importante Barrio Trénova. Y por supuesto, 
            La Iglesia La Merced y el Cementerio Nº 1.
            ¡Ven a descubrir los secretos de Rancagua con nuestro Guardián del Patrimonio!
          </Typography>
            </Box>
            <Box sx={{ padding: "1rem" }}>
                <Grid container spacing={3}>
                {placesWithVideos.map(place => (
                    <Grid item xs={12} sm={6} md={4} key={place.id}>
                    <Typography variant="h6" gutterBottom>
                        {place.name}
                    </Typography>
                    {place.video?.id && (
                        <YouTubeEmbed videoId={place.video.id} />
                    )}
                    </Grid>
                ))}
                </Grid>
            </Box>
        </Paper>
        </PageContainer>
    );
}